import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import CohortAssessment from "../../components/assessments/CohortAssessment";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import { ApiConfig } from "../../services/ApiConfig";
export default function FundersAssessment() {
  const [activeStep, setActiveStep] = useState(1);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [assessmentData, setAssessmentData] = useState([]);
  const [questionStatus, setQuestionStatus] = useState("No");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1);
    }
  };

  const getAssessmentData = async (path) => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllCohortQuestionary,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result?.allQuestionary);
        setAssessmentData(res?.data?.result?.allQuestionary);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleInitialAnswers = async () => {
  //   try {
  //     const res = await customAxios({
  //       method: "POST",
  //       url: ApiConfig.getFundingAnswerDetails,
  //       headers: {
  //         token: sessionStorage.getItem("userToken"),
  //       },
  //       data: {
  //         user_id: sessionStorage.getItem("userId"),
  //       },
  //     });
  //     if (res.data.statusCode === 200) {
  //       setInitialAnswers(res.data?.result);
  //       getAssessmentData(path);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    getAssessmentData();
    // handleInitialAnswers(path);
  }, []);

  return (
    <div style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}>
      {assessmentData?.length > 0 && (
        <CohortAssessment
          pageTitle={"BOOST! Your Business Cohort Application"}
          renderData={assessmentData}
          activeStep={activeStep}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          questionStatus={questionStatus}
          setQuestionStatus={setQuestionStatus}
          //   initialAnswers={initialAnswers}
          //   handleInitialAnswers={handleInitialAnswers}
        />
      )}
    </div>
  );
}
