import React from "react";
import "./assessment.scss";

export default function AssessMentPercentage() {
  let percentage = parseInt(sessionStorage.getItem("percentage"));
  return (
    <div className="assessmentPercentage">
      <div>
        <h2>{percentage.toFixed(0)}% of this assessment has been completed</h2>
        <div>
          <span style={{ width: `${percentage.toFixed(1)}%` }}></span>
        </div>
      </div>
    </div>
  );
}
