import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import { ApiConfig } from "../../services/ApiConfig";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setAction } from "../../redux/features/actionScheduleRefresherSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "14px",
  minHeight: "500px",
  "@media(max-width:600px)": {
    minWidth: "96vw",
  },
};

const validationSchema = Yup.object({
  // category: Yup.string().trim().required("Category is required."),
  description: Yup.string()
    .trim()
    .required("Description is required.")
    .min(5, "Minimum 5 character are required."),
  businessOwner: Yup.object(),
  actionTitle: Yup.string().trim().required("Action Title is required."),
  actionType: Yup.string().trim().required("Action Type is required."),
  assign: Yup.string().trim().required("Assignee name is required."),
});

const SchedulePopUp = ({
  open,
  setOpen,
  getActionsList,
  setUpdateAssignedActionList,
  isActionListUpdated,
  getActionList,
  action,
  title,
  currentId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(dayjs());
  const [isNetworkCallLoading, setIsNetworkCallLoading] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [actionType, setActionType] = useState([{}]);
  const actionScheduleRefresher = useSelector(
    (state) => state.actionScheduleRefresher.actionSchedule
  );
  const dispatch = useDispatch();

  const [initialValues, setInitialValues] = useState({
    // category: "",
    description: "",
    businessOwner: "",
    assign: "me",
    actionTitle: "",
    actionType: "",
    // date: dayjs(),
  });

  useEffect(() => {
    getAllActionType();
    if (currentId) {
      getAction();
    }
  }, [currentId]);

  const getAction = async (values) => {
    // setIsNetworkCallLoading(true);
    const payloadToSend = {
      action_id: currentId,
    };

    const res = await apiWithToken({
      url: ApiConfig.findAction,
      method: "GET",
      params: payloadToSend,
    });
    if (res?.data?.statusCode === 200) {
      console.log(res, "hghgh");
      console.log("res8", res?.data?.result?.actions[0]);
      const action = res?.data?.result?.actions[0];
      const actionData = action;
      const dueDate = actionData?.due_date;
      const parsedDueDate = dayjs(dueDate);
      console.log(initialValues);
      setInitialValues({
        // category: actionData?.category || "",
        description: actionData?.description || "",
        businessOwner: actionData?.businessOwner || "",
        assign: actionData?.assign_to || "me",
        date: parsedDueDate,
        actionTitle: actionData?.action_title,
        actionType: actionData?.actionType_id,
      });
      // setIsNetworkCallLoading(false);
      // getActionsList();
      // dispatch(setAction());
      // // toast.success(res?.result || "Action created successfully.");
      // setOpen(false);
      // setUpdateAssignedActionList((e) => !e);
    }
  };
  const getAllActionType = async () => {
    const res = await apiWithToken({
      url: ApiConfig.getAllActionType,
      method: "GET",
    });
    if (res?.data?.statusCode === 200) {
      console.log(res, "hghgh");
      setActionType(res?.data?.result?.actionTypes);
    } else {
      console.log("sasasas");
    }
  };

  const createAction = async (values) => {
    setIsNetworkCallLoading(true);
    const payloadToSend = {
      assigned_by: sessionStorage.getItem("userId"),
      assigned_to:
        values?.assign === "me"
          ? sessionStorage.getItem("userId")
          : sessionStorage.getItem("advisorId"),
      name:
        values?.assign === "me"
          ? sessionStorage.getItem("ownerName")
          : sessionStorage.getItem("advisorName"),
      // category: values?.category,
      description: values?.description,
      action_title: values?.actionTitle,
      actionType_id: values?.actionType,
      assign_to: values?.assign,
      due_date: date.format("YYYY-MM-DD HH:mm:ss.SSSZ"),
    };

    const res = await apiWithToken({
      url: ApiConfig.createAction,
      method: "POST",
      data: payloadToSend,
    });
    if (res?.data?.statusCode === 200) {
      console.log("res8", res?.data);
      setIsNetworkCallLoading(false);
      getActionsList();
      dispatch(setAction());
      // toast.success(res?.result || "Action created successfully.");
      setOpen(false);
      setUpdateAssignedActionList((e) => !e);
    }

    setOpen(false);
  };

  const updateAction = async (values) => {
    // setIsNetworkCallLoading(true);
    console.log(values);
    const payloadToSend = {
      action_id: currentId,
      assigned_by: sessionStorage.getItem("userId"),
      assigned_to:
        values?.assign === "me"
          ? sessionStorage.getItem("userId")
          : sessionStorage.getItem("advisorId"),
      name:
        values?.assign === "me"
          ? sessionStorage.getItem("ownerName")
          : sessionStorage.getItem("advisorName"),
      // category: values?.category,
      description: values?.description,
      assign_to: values?.assign,
      action_title: values?.actionTitle,
      actionType_id: values?.actionType,
      due_date: date.format("YYYY-MM-DD HH:mm:ss.SSSZ"),
    };
    const res = await apiWithToken({
      url: ApiConfig?.updateAction,
      method: "PUT",
      data: payloadToSend,
    });
    if (res?.data?.statusCode === 200) {
      console.log("res8", res?.data);

      await getActionList();
      setOpen(false);

      setIsNetworkCallLoading(false);
      // dispatch(setAction());
      // toast.success(res?.result || "Action created successfully.");
      setUpdateAssignedActionList((e) => !e);
    }

    setOpen(false);
  };

  return (
    <Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            ...style,
            backgroundColor: currentTheme === "DARK" ? "#1e1e1e" : "#e9e9e9",
          }}
        >
          {isLoading ? (
            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                minHeight: "400px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircularProgress
                sx={{
                  color: "#523366",
                }}
                size={"50px"}
              />
            </Box>
          ) : (
            <>
              <IconButton
                sx={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  color: "#1f1f1f",
                  color: currentTheme === "DARK" ? "#fff" : "#000",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CancelIcon />
              </IconButton>
              <Grid
                container
                item
                xs={12}
                style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
              >
                <Typography variant="h6">{title}</Typography>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid
                container
                item
                xs={12}
                mt={4}
                style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
              >
                <Formik
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={
                    title === "Create Action" ? createAction : updateAction
                  }
                  validationSchema={validationSchema}
                >
                  {({
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    values,
                    setValues,
                  }) => (
                    <Form style={{ width: "100%" }}>
                      <Box>
                        <label> Action Title</label>
                        <TextField
                          disabled={isNetworkCallLoading}
                          name="actionTitle"
                          placeholder="Enter action title"
                          value={values.actionTitle}
                          sx={{ mt: 1 }}
                          fullWidth
                          inputProps={{
                            style: {
                              height: "10px",
                              color: currentTheme === "DARK" ? "#fff" : "#000",
                            },
                            maxLength: 200,
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.actionTitle && Boolean(errors.actionTitle)
                          }
                          helperText={touched.actionTitle && errors.actionTitle}
                        />
                      </Box>
                      <Box
                        mt={2}
                        style={{
                          color: currentTheme === "DARK" ? "#fff" : "#000",
                        }}
                      >
                        <label> Select Action Item</label>
                        <Select
                          style={{
                            color: currentTheme === "DARK" ? "#fff" : "#000",
                          }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          displayEmpty
                          disabled={isNetworkCallLoading}
                          name="actionType"
                          value={values.actionType}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          sx={{
                            mt: 1,
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={
                            touched.actionType && Boolean(errors.actionType)
                          }
                          helperText={touched.actionType && errors.actionType}
                        >
                          {actionType?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.action_type}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                      {/* <Box  mt={2}>
                        <label> Select Category</label>

                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          displayEmpty
                          disabled={isNetworkCallLoading}
                          name="category"
                          value={values.category}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          sx={{
                            mt: 1,
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={touched.category && Boolean(errors.category)}
                          helperText={touched.category && errors.category}
                        >
                          <MenuItem value={"Update assessment"}>
                            Update assessment
                          </MenuItem>
                          <MenuItem value={"Join live classes"}>
                            Join live classes
                          </MenuItem>
                          <MenuItem value={"Update report"}>
                            Update report
                          </MenuItem>
                        </Select>
                      </Box> */}
                      <Box mt={2}>
                        <label> Short Description</label>
                        <TextField
                          disabled={isNetworkCallLoading}
                          name="description"
                          placeholder="Enter description"
                          value={values.description}
                          sx={{ mt: 1 }}
                          fullWidth
                          multiline
                          rows={5}
                          inputProps={{
                            style: {
                              height: "10px",
                              color: currentTheme === "DARK" ? "#fff" : "#000",
                            },
                            maxLength: 1000,
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.description && Boolean(errors.description)
                          }
                          helperText={touched.description && errors.description}
                        />
                      </Box>

                      <Box mt={2}>
                        <label>Assign To</label>
                        <Select
                          style={{
                            color: currentTheme === "DARK" ? "#fff" : "#000",
                          }}
                          disabled={isNetworkCallLoading}
                          name="assign"
                          value={values.assign}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          sx={{
                            mt: 1,
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={touched.assign && Boolean(errors.assign)}
                          helperText={touched.assign && errors.assign}
                        >
                          <MenuItem value={"me"}>Me</MenuItem>
                          <MenuItem value={"advisor"}>Advisor</MenuItem>
                        </Select>
                      </Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            width: "100%",
                            mt: 2,
                          }}
                          components={["DatePicker"]}
                        >
                          <label>Select Due Date</label>
                          <DatePicker
                            disabled={isNetworkCallLoading}
                            name="date"
                            value={values?.date}
                            onChange={(e) => {
                              console.log(e.format("lll"));
                              setDate(e);
                              // handleChange(e);
                            }}
                            disablePast={true}
                            sx={{
                              mt: 1,
                              width: "100%",

                              "& .MuiOutlinedInput-root": {
                                borderRadius: "4px",
                              },

                              "& .MuiInputLabel-root": {
                                top: "-5px",
                              },
                              input: {
                                padding: "10px 10px",
                                color:
                                  currentTheme === "DARK" ? "#fff" : "#000",
                              },
                              style: {
                                color:
                                  currentTheme === "DARK" ? "#fff" : "#000",
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                marginLeft: "0px",
                              },
                            }}
                            error={touched.assign && Boolean(errors.assign)}
                            helperText={touched.assign && errors.assign}
                          />
                        </Box>
                      </LocalizationProvider>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent={"center"}
                        gap={2}
                        mt={6}
                        mb={2}
                      >
                        <Button
                          sx={{
                            background:
                              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                            borderRadius: "10px",
                            fontSize: "18px",
                            paddingX: "40px",
                            paddingY: "10px",

                            textTransform: "none",
                          }}
                          disabled={isNetworkCallLoading}
                          variant="contained"
                          type="submit"
                        >
                          Submit
                          {isNetworkCallLoading && (
                            <CircularProgress
                              size={20}
                              sx={{
                                marginLeft: "10px",
                                color: "white",
                                color:
                                  currentTheme === "DARK" ? "#fff" : "#000",
                              }}
                            />
                          )}
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
};

export default SchedulePopUp;
