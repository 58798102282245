import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import SchedulePopUp from "./SchedulePopUp";
import "./Calendar.scss";
import { useSelector } from "react-redux";
import ActionPlanning from "../home/ActionPlanning";
import AssignedAction from "../../components/scheduleAction/AssignedAction";
import ScheduleCallTab from "../../components/scheduleAction/ScheduleCallTab";
import { useLocation } from "react-router";

export default function ScheduleAndCall() {
  const [selectedTab, setSelectedTab] = useState(0);
  const [updateAssignedActionList, setUpdateAssignedActionList] =
    useState(false);
  const [updateScheduleCallList, setUpdateScheduleCallList] = useState(false);
  const { pathname } = useLocation();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div style={{ backgroundColor: currentTheme == "DARK" ? "#202123" : "#fff" }}>
      <ActionPlanning
        updateAssignedActionList={updateAssignedActionList}
        setUpdateAssignedActionList={setUpdateAssignedActionList}
        updateScheduleCallList={updateScheduleCallList}
        setUpdateScheduleCallList={setUpdateScheduleCallList}
      />
      <div className="optionContainer">
        <div
          onClick={() => setSelectedTab(0)}
          style={{
            background: selectedTab === 0 ? "#fff" : "transparent",
            color: selectedTab === 0 ? "#262626" : "#fff",
            
          }}
        >
          Assigned Actions
        </div>
        <div
          onClick={() => setSelectedTab(1)}
          style={{
            background: selectedTab === 1 ? "#fff" : "transparent",
            color: selectedTab === 1 ? "#262626" : "#fff",
          }}
        >
          Scheduled Calls
        </div>
      </div>
      <div >
        {selectedTab === 0 && (
          <AssignedAction
            updateAssignedActionList={updateAssignedActionList}
            setUpdateAssignedActionList={setUpdateAssignedActionList}
          />
        )}
      </div>
      <div>
        {selectedTab === 1 && (
          <ScheduleCallTab
            updateScheduleCallList={updateScheduleCallList}
            setUpdateScheduleCallList={setUpdateScheduleCallList}
          />
        )}
      </div>
    </div>
  );
}
