import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Box,
  FormControl,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { Form } from "formik";
import OtpInput from "react-otp-input";
import { ApiConfig } from "../../services/ApiConfig";

import { toast } from "react-toastify";
import * as Yup from "yup";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import axios from "axios";

const LoginWrapper = styled("div")({
  height: "100vh",
  width: "45%",
  overflow: "hidden",
  marginTop: "150px",
  marginRight: "150px",
  "@media(max-width:1280px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    marginTop: "200px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
  // backgroundColor: "yellow",
});

const FormContainer = styled(Form)({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",

  "@media(max-width:640px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "4px",

  "@media(max-width:1024px)": {
    width: "70vw !important",
    // margin: "10px 0px",
  },
});

const OtpVerification = () => {
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const location = useLocation();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [minutes, setMinutes] = useState(
    sessionStorage.getItem("minutes")
      ? parseInt(sessionStorage.getItem("minutes"))
      : 2
  );
  const [seconds, setSeconds] = useState(
    sessionStorage.getItem("seconds")
      ? parseInt(sessionStorage.getItem("seconds"))
      : 0
  );
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [sessionDetails, setSessionDetails] = useState({
    ip_address: "",
    login_from: "",
    location: "",
    source: "web",
  });

  async function getBrowserAndLocation() {
    const browserName = detectBrowser();
    const ipAddress = await getIpAddress();
    const locationInfo = await getLocationInfo(ipAddress);
    console.log(browserName, ipAddress, locationInfo);

    setSessionDetails({
      ...sessionDetails,
      ip_address: ipAddress,
      login_from: browserName,
      location: locationInfo,
    });
  }

  function detectBrowser() {
    const userAgent = navigator.userAgent;

    if (userAgent.includes("Chrome")) {
      return "Google Chrome";
    } else if (userAgent.includes("Firefox")) {
      return "Mozilla Firefox";
    } else if (userAgent.includes("Safari")) {
      return "Apple Safari";
    } else if (userAgent.includes("Edge")) {
      return "Microsoft Edge";
    } else if (userAgent.includes("MSIE") || userAgent.includes("Trident/")) {
      return "Internet Explorer";
    } else {
      return "Unknown Browser";
    }
  }

  // async function getIpAddress() {
  //   const response = await fetch("https://api64.ipify.org?format=json");
  //   const data = await response.json();
  //   return data.ip;
  // }
  async function getIpAddress() {
    try {
      const response = await fetch("https://api64.ipify.org?format=json");
      const data = await response.json();
      return data?.ip;
    } catch (error) {
      console.log(error, "IPGET");
      return null;
    }
  }
  async function getLocationInfo(ipAddress) {
    console.log(ipAddress, "kajgff");
    try {
      if (!ipAddress) {
        return null;
      }
      const response = await fetch(
        `https://ipinfo.io/${ipAddress}?token=a7c0a7b7e643f8`
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.log(error, "kajgfafafedws");
      return null;
    }
  }

  // async function getIpAddress() {
  //   const response = await fetch("https://api64.ipify.org?format=json");
  //   const data = await response.json();
  //   return data.ip;
  // }

  // async function getLocationInfo(ipAddress) {
  //   const response = await fetch(
  //     `https://ipinfo.io/${ipAddress}?token=a7c0a7b7e643f8`
  //   );
  //   const data = await response.json();
  //   return data;
  // }

  useEffect(() => {
    getBrowserAndLocation();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    sessionStorage.setItem("minutes", minutes);
    sessionStorage.setItem("seconds", seconds);
    return () => {
      sessionStorage.removeItem("minutes");
      sessionStorage.removeItem("seconds");
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const handleKeyPress = (e) => {
    const isNumeric = /^\d+$/;

    if (!isNumeric.test(e.key)) {
      e.preventDefault();
      // setErrorMessage("OTP must be a number.");
    } else {
      setErrorMessage("");
    }
  };

  const otpHandler = async () => {
    setLoading(true);

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.verifyOtp,
        data: {
          email: location.state.email,
          otp: otp,
          ip_address: sessionDetails?.ip_address
            ? sessionDetails?.ip_address
            : "123.123.123.123",

          location: sessionDetails.location
            ? `${sessionDetails.location?.city},${sessionDetails.location?.country}`
            : `${"Delhi"},${"IN"}`,

          login_from: sessionDetails.login_from,
          source: sessionDetails.source,
        },
      });

      console.log(res);
      if (res && res.data.result) {
        sessionStorage.setItem("userToken", res.data.result.token);
        sessionStorage.setItem("cohort", res.data.result.cohort_exist);
        sessionStorage.setItem("userUniqueId", res.data.result.user_unique_id);

        if (location.state.type === "forget") {
          navigate("/resetpassword", {
            state: { email: location.state.email },
          });
        } else if (res.data.result.cohort_exist === false) {
          window.location.href = "/my-cohort";
          // navigate("/my-cohort");
          // window.location.reload();
        } else {
          navigate("/");
        }
      } else {
        console.log(res);
        setErrorMessage(
          res?.data?.responseMessage ||
            "Something went wrong. Please try again.",
          { position: toast.POSITION.TOP_RIGHT }
        );
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again.",
        { position: toast.POSITION.TOP_RIGHT }
      );
      setLoading(false);
    }
  };

  const reSendOtp = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.resentOtp,
        data: {
          email: location.state.email,
        },
      });
      console.log(res);
      if (res.data?.statusCode == 200) {
        setMinutes(2);
        setSeconds(0);
        console.log(res);
        // sessionStorage.setItem("userToken", res.data.result.token);

        // navigate("/dashboard");
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
    }
  };

  const reSendOtp2 = async () => {
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.resentOtp,
        data: {
          email: location.state.email,
        },
      });
      console.log(res);
      if (res.data?.statusCode == 200) {
        console.log(res);
        // sessionStorage.setItem("userToken", res.data.result.token);

        // navigate("/dashboard");
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
    }
  };

  // useEffect(() => {
  //   if (location.state.status == "2faemail") {
  //     reSendOtp2();
  //   }
  // }, [location.state.status]);

  useEffect(() => {
    if (!location?.state?.email) {
      return navigate("/");
    }
  }, []);

  return (
    <LoginWrapper>
      <Typography
        color={currentTheme == "DARK" ? "#fff" : "#000"}
        style={{ textAlign: "center" }}
        variant="h4"
        fontWeight={"500"}
      >
        OTP Verification
      </Typography>
      <Typography
        color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
        sx={{
          textAlign: "center",
          // color: "rgba(32, 33, 35, 0.60)",
          fontSize: "18px",
        }}
        variant="body1"
        fontWeight={"500"}
        mt={2}
      >
        Please enter the OTP sent on your email : {location.state.email}
      </Typography>
      {/* <Typography
        sx={{
          textAlign: "left",
          color: "rgba(32, 33, 35, 0.60)",
          fontSize: "18px",
        }}
        variant="body1"
        fontWeight={"500"}
        my={2}
      >
        Enter OTP
      </Typography>
      <OtpInput
        value={otp}
        onChange={setOtp}
        numInputs={4}
        renderSeparator={
          <Box display={"inline"} mx={1.5}>
            {" "}
          </Box>
        }
        containerStyle={{
          display: "flex",
          flexWrap: "wrap",
        }}
        inputStyle={{
          minWidth: "55px",
          height: "55px",
          borderRadius: "10px",
          border: "1px solid rgba(0, 0, 0, 0.25)",
        }}
        renderInput={(props) => (
          <input
            style={{
              width: "40px", // Set the width as per your design
              height: "40px", // Set the height as per your design
              fontSize: "16px", // Set the font size as per your design
              border: "1px solid #ccc", // Set the border as per your design
              borderRadius: "4px", // Set the border radius as per your design
              margin: "4px", // Set the margin as per your design
              textAlign: "center", // Center the text within the input
              // Add any other styles you want to customize
            }}
            {...props}
          />
        )}
      /> */}

      <Box mt={3} mx="auto">
        <FormControl sx={{ width: "100%" }}>
          <Typography
            color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
            sx={{
              textAlign: "center",

              fontSize: "18px",
            }}
            variant="body1"
            fontWeight={"500"}
            my={2}
          >
            Enter OTP
          </Typography>

          <OtpInput
            value={otp}
            onChange={(value) => {
              const zeroRegex = /^0+$/;

              if (zeroRegex.test(value) && value.length === 4) {
                setErrorMessage("Input cannot be all zeros.");
              } else {
                setErrorMessage("");
              }

              setOtp(value.replace(/[^0-9]/g, ""));
            }}
            numInputs={4}
            renderSeparator={
              <Box display={"inline"} mx={1}>
                {" "}
              </Box>
            }
            containerStyle={{
              width: "100%",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              margin: "0px 10px",
            }}
            inputStyle={{
              minWidth: "55px",
              height: "55px",
              borderRadius: "10px",
              border: "1px solid rgba(0, 0, 0, 0.25)",
            }}
            renderInput={(props) => (
              <input
                style={{
                  width: "40px",
                  height: "40px",
                  fontSize: "16px",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  margin: "4px",
                  textAlign: "center",
                }}
                onKeyPress={handleKeyPress}
                {...props}
              />
            )}
          />

          {minutes > 0 || seconds > 0 ? (
            <Box
              mt={1}
              mx="auto"
              sx={{
                width: `calc(55px * 5)`,
                textAlign: "right",
                color: "purple",
                fontSize: "18px",
              }}
            >
              {String(minutes).padStart(2, "0").replace("-", "")}:
              {String(seconds).padStart(2, "0")}
            </Box>
          ) : (
            <Box
              mt={1}
              sx={{
                width: { xs: "310px", lg: "475px" },
                textAlign: "right",
              }}
            >
              <Button
                onClick={() => {
                  reSendOtp();
                }}
                mx="auto"
                variant="text"
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  WebkitBackgroundClip: "text", // For Webkit browsers like Chrome and Safari
                  color: "transparent",
                  textTransform: "none",
                  textDecoration: "underline",
                  fontSize: "15px",
                  "&:hover": {
                    fontWeight: 600,
                  },
                }}
                // disabled={seconds > 0 && minutes > 0}
                type="submit"
              >
                Resend OTP?
              </Button>
            </Box>
          )}
        </FormControl>
      </Box>
      <Typography
        sx={{
          textAlign: "center",
          color: "#d32f2f",
          fontSize: "15px",
        }}
        fontWeight={"500"}
        // mt={2}
      >
        {errorMessage}
      </Typography>

      <Box
        mt={8}
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          sx={{
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            borderRadius: "10px",
            fontSize: "18px",
            paddingX: "40px",
            paddingY: "10px",
            textTransform: "none",
          }}
          variant="contained"
          disabled={loading || otp.length < 4 || /^0+$/.test(otp)}
          onClick={() => {
            if (otp.length < 4) {
              setErrorMessage("OTP must contain at least 4 digits.");
            } else if (/^0+$/.test(otp)) {
              setErrorMessage("OTP cannot be all zeros.");
            } else {
              otpHandler();
            }
          }}
          type="submit"
        >
          Continue
          {loading && (
            <CircularProgress
              size={20}
              sx={{
                marginLeft: "10px",
                color: "white",
              }}
            />
          )}
        </Button>
      </Box>
    </LoginWrapper>
  );
};

export default OtpVerification;
