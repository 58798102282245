import React, { useState, useRef, useEffect } from "react";

import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import { canvasPreview } from "./canvasPreview";
import { useDebounceEffect } from "./useDebounceEffect";

import "react-image-crop/dist/ReactCrop.css";

import { Dialog, DialogContent, Button, Box } from "@mui/material";
import { ImageCompressor } from "image-compressor";

// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CropImage({
  selectedFile,
  setCropData,
  aspectRatio,
  setThumbnail,
}) {
  const [imgSrc, setImgSrc] = useState("");
  const previewCanvasRef = useRef(null);
  const imgRef = useRef(null);
  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  var scale = 1;
  var rotate = 0;
  var aspect = aspectRatio;
  const [isOpen, setIsOpen] = useState(false);

  function onSelectFile(file) {
    if (file) {
      setCrop(undefined); // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(file);
    }
  }
  useEffect(() => {
    if (selectedFile) {
      onSelectFile(selectedFile);
      setIsOpen(true);
    }
  }, [selectedFile]);

  useEffect(() => {
    // Automatically set the initial crop state when the component mounts
    if (aspect) {
      setCrop(centerAspectCrop(150, 150, aspect));
    }
  }, [aspect]);

  function onImageLoad(e) {
    if (aspect) {
      setCrop(centerAspectCrop(150, 150, aspect));
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  const imageCompressor = new ImageCompressor();
  const compressorSettings = {
    toWidth: 300,
    // toHeight: 100,
    mimeType: "image/png",
    mode: "strict",
    quality: 0.6,
    grayScale: false,
    sepia: false,
    threshold: 0,
    vReverse: false,
    hReverse: false,
    speed: "low",
  };
  const imageCompressor1 = new ImageCompressor();

  const compressorSettings1 = {
    toWidth: 940,
    // toHeight: 100,
    mimeType: "image/png",
    mode: "strict",
    quality: 0.6,
    grayScale: false,
    sepia: false,
    threshold: 0,
    vReverse: false,
    hReverse: false,
    speed: "low",
  };
  const saveHandler = async () => {
    var canvas = document.getElementById("alpha");
    var dataURL;
    if (canvas === null) {
      // canvas = document.getElementById("original-img");
      dataURL = await getImageDataUrl("original-img");
    } else {
      dataURL = canvas.toDataURL("image/png");
    }
    imageCompressor.run(dataURL, compressorSettings, proceedCompressedImage);
    imageCompressor1.run(dataURL, compressorSettings1, proceedCompressedImage1);

    setIsOpen(false);
  };

  const getImageDataUrl = (imgId) => {
    return new Promise((resolve, reject) => {
      var imgElement = document.getElementById(imgId);

      if (imgElement) {
        var imageUrl = imgElement.src;
        var image = new Image();
        image.src = imageUrl;

        image.onload = function () {
          var canvas = document.createElement("canvas");
          var context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0);
          var dataURL = canvas.toDataURL("image/png");
          resolve(dataURL);
        };

        image.onerror = function () {
          reject(new Error("Failed to load the image."));
        };
      } else {
        reject(new Error("Image element not found."));
      }
    });
  };

  const cancleHandler = () => {
    setIsOpen(false);
    setCrop();
    setCompletedCrop();
  };
  function proceedCompressedImage1(compressedSrc1) {
    // ...
    if (setCropData) {
      setCropData(compressedSrc1);
    }
  }
  function proceedCompressedImage(compressedSrc) {
    // ...
    if (setThumbnail) {
      setThumbnail(compressedSrc);
    }
  }
  useEffect(() => {
    // Initialize the crop state with pre-selected crop data
    setCrop({
      unit: "%",
      width: 30,
      height: 30,
      aspect: 1,
      x: 35,
      y: 20,
    });
  }, [imgSrc]);
  return (
    <div className="App">
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        maxWidth="sm"
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        PaperProps={{
          style: {
            // maxHeight: "700px",
            // maxWidth: "600px",
            width: "100%",
            height: "90%",
            overflow: "hidden",
            padding: "10px 0px",
          },
        }}
      >
        <DialogContent
          style={{
            height: "100%",
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* <div className='Crop-Controls'>
            <input type='file' accept='image/*' onChange={onSelectFile} />
          </div> */}

          {Boolean(imgSrc) && (
            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => setCrop(percentCrop)}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                overflow: "scroll",
              }}
            >
              <div
                style={{
                  transform: `scale(${scale}) rotate(${rotate}deg)`,
                  objectFit: "cover",
                  // width: "100%",
                  // height: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  id="original-img"
                  ref={imgRef}
                  alt="Crop me"
                  src={imgSrc}
                  style={{
                    transform: `scale(${scale}) rotate(${rotate}deg)`,
                    objectFit: "cover",
                    width: "100%",
                    height: "100%",
                  }}
                  onLoad={onImageLoad}
                />
              </div>
            </ReactCrop>
          )}
          <div>
            {Boolean(completedCrop) && (
              <canvas
                id="alpha"
                ref={previewCanvasRef}
                style={{
                  border: "1px solid black",
                  objectFit: "contain",
                  width: completedCrop.width,
                  height: completedCrop.height,
                  display: "none",
                }}
              />
            )}
          </div>
          <Box mt={4}>
            <Button
              sx={{
                marginX: "auto",
                marginBottom: "40px",
                width: "140px",
                fontSize: "18px",
                border: "0.747px solid var(--Linear, #E46703)",
                color: "black",
                textTransform: "none",
              }}
              onClick={cancleHandler}
            >
              Cancel
            </Button>
            &nbsp;&nbsp;
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                width: "140px",
                fontSize: "18px",
                textTransform: "none",
              }}
              variant="contained"
              onClick={saveHandler}
            >
              Save
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
