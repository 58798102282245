import React, { useEffect, useState } from "react";
import { ApiConfig } from "../../services/ApiConfig";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import customAxios from "../../utils/customAxios";

export default function AllCategory() {
  const [allKeyAndFeatures, setAllKeyAndFeatures] = useState([]);

  const getAllKeyAndFeatures = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllKeyAndFeatures,
      });
      console.log("dataa1", res?.data?.result?.allKeyAndFeatures);
      if (res.data.statusCode === 200) {
        setAllKeyAndFeatures(res?.data?.result?.allKeyAndFeatures);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllKeyAndFeatures();
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div>
        <h2 style={{ marginBottom: "2rem", fontSize: "32px" }}>
          Key Features & Benefits
        </h2>
      </div>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          // justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        {allKeyAndFeatures.map((val, idx) => (
          <Grid
            item
            lg={3}
            md={4}
            sm={8}
            xs={11}
            position="relative"
            backgroundColor="#dadada"
            height="300px"
            borderRadius="20px"
            style={{
              margin: "10px",
              boxShadow:
                "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)",
            }}
            p={3}
          >
            <Box
              position="absolute"
              top="0"
              right="0"
              component="img"
              src="/images/bordervector.png"
            />

            <Box
              component="img"
              sx={{ width: "42px", height: "35px" }}
              src={val.image_url}
            />
            <Typography
              fontSize={{ md: "24px", xs: "18px" }}
              mt={1}
              fontWeight={500}
            >
              {val.title}
            </Typography>
            <Typography
              color="#00000099"
              fontSize="14px"
              mt={1}
              fontWeight={400}
            >
              {val.description
                ? val.description?.length > 219
                  ? val.description?.slice(0, 219) + "..."
                  : val.description
                : "NA"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
