import React from "react";
import {
  Dialog,
  DialogTitle,
  Button,
  Typography,
  CssBaseline,
  Box,
} from "@mui/material";
import { useSelector } from "react-redux";

const CookieModal = ({ open, onClose }) => {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  return (
    <Dialog sx={{}} open={open} onClose={onClose}>
      <Box style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff"}}>

   
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "30px",
          borderRadius: "20px",
         color: currentTheme == "DARK" ? "#fff" : "#000" 
          
        }}
      >
        <Typography variant="h4" sx={{ fontSize: "30px" }} fontWeight={"600"}>
          We value your Privacy
        </Typography>
        <Typography
          mt={2}
          sx={{
            color: "rgba(32, 33, 35, 0.60)",
            width: "432px",
            paddingX: "30px",
            textAlign: "center",
            color: currentTheme == "DARK" ? "#fff" : "#000" 
          }}
          variant="subtitle2"
          fontWeight={"500"}
          
        >
          "Welcome to our website! We use cookies to enhance your browsing
          experience, analyze site traffic, and personalize content. By
          continuing to use this site, you consent to the use of cookies in
          accordance with our Privacy Policy."
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-evenly">
        <Button
          sx={{
            marginX: "30px",
            marginBottom: "40px",
            paddingX: "50px",
            paddingY: "10px",
            color: "black",
            textTransform: "none",
            fontSize: "16px",
            border: "0.747px solid var(--Linear, #E46703)",
            color: currentTheme == "DARK" ? "#fff" : "#000" 
          }}
          variant="outlined"
          onClick={onClose}
        >
          Deny Cookies
        </Button>
        <Button
          sx={{
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            marginX: "30px",
            marginBottom: "40px",
            paddingX: "50px",
            paddingY: "10px",
            textTransform: "none",
            fontSize: "16px",
          }}
          variant="contained"
          onClick={onClose}
        >
          Allow Cookies
        </Button>
      </Box>
      </Box>
    </Dialog>
  );
};

export default CookieModal;
