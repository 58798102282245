import React from "react";
import { Grid, Box, Typography, Button, Container } from "@mui/material";
import { styled } from "@mui/system";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

const Maincomponent = styled("Box")(({ theme }) => ({
  "& .headText": {
    "& h2": {
      fontSize: "45px",
    },
  },
  "& .marketCard": {
    backgroundImage: 'url("/images/rectblue.png")',

    // width: "38vw",
    // height: "32vw",
    // height: "350px",
    // maxHeight:'32vw'
    height: "450px",
    widht: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "cover",
    display: "flex",
    borderRadius: "15px",
    justifyContent: "center",
    // marginY: { xs: "30px" },
    alignItems: "center",
  },
  "& .masterMindCard": {
    backgroundImage: 'url("/images/rectgreen.png")',
    height: "450px",
    widht: "100%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    objectFit: "cover",
    borderRadius: "15px",
    display: "flex",
    flexDirection: "column",
    // gap: { md: "25px", xs: "5px" },
    justifyContent: "center",
    alignItems: "center",
    rowGap: "20px",
  },
}));

const MonitorMeasure = () => {
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  return (
    <Maincomponent>
      <Box pb={4} mt={4}>
        <Container maxWidth="lg">
          <Box my={3} className="headText">
            <Typography
              sx={{
                fontSize: { xl: "45px", md: "35px", sm: "30px", xs: "25px" },
                fontWeight: 600,
                color: currentTheme === "DARK" ? "#fff" : "#000",
                textAlign: { md: "left", xs: "center" },
                width: "100%",
              }}
            >
              Monitor & Measure
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item md={6} sm="6" xs={12}>
              <Box className="marketCard">
                <Typography
                  fontWeight={600}
                  color="white"
                  sx={{
                    fontSize: { xl: "1.8rem", xs: "1.4rem" },
                    width: "350px",
                    textAlign: "center",
                  }}
                >
                  Market Expansion Analytics
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} sm="6" xs={12}>
              <Box className="masterMindCard">
                <Typography
                  sx={{ fontSize: { xl: "30px", sm: "20px" } }}
                  color="white"
                  variant="h6"
                >
                  Master Mind Community
                </Typography>
                <Box
                  component="img"
                  sx={{ width: { xl: "80px", md: "60px", xs: "40px" } }}
                  src={"/images/people.png"}
                />
                <Button
                  sx={{
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    marginX: "100px",
                    borderRadius: "10px",
                    width: { xl: "246px", md: "120px", xs: "80px" },
                    textTransform: "none",
                    fontSize: { xl: "20px", md: "15px", xs: "12px" },
                  }}
                  variant="contained"
                  onClick={() => navigate("/community")}
                  disabled={true}
                >
                  Chat
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Maincomponent>
  );
};

export default MonitorMeasure;
