import React, { useState } from "react";

const PdfView = () => {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess() {
    setNumPages(numPages);
  }

  const pdfUrl =
    "https://storageboostaccount.blob.core.windows.net/test-container-new/1714045032723_1713957071087_1713853654418_1713794127392_EducationAssesment_Version1(2).pdf";

  return (
    <div>
      <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
        Open PDF
      </a>

      {/* <a href={url} target="_blank">
        pdf
      </a> */}
      {/* <iframe
        title="PDF Viewer"
        src="https://storageboostaccount.blob.core.windows.net/test-container-new/1714045032723_1713957071087_1713853654418_1713794127392_EducationAssesment_Version1(2).pdf"
        width="100%"
        height="100%"
        frameBorder="0"
      /> */}
    </div>
  );
};

export default PdfView;
