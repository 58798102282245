// import styled from "@emotion/styled";
// import { Box } from "@mui/material";
// import React from "react";
// import { useNavigate } from "react-router-dom";

// const LoginLayoutWrapper = styled("div")({
//   height: "100dvh",
//   width: "100dvw",
//   overflow: "hidden",
//   display: "flex",
//   flexDirection: "row",
//   position: "relative",
//   paddingLeft: "80px",
//   "@media(max-width:1024px)": {
//     paddingLeft: "0px",
//     padding: "20px",
//     overflow: "scroll",
//   },
// });

// const CompanyLogoContainer = styled("img")({
//   position: "absolute",
//   top: "20px",
//   "@media(max-width:1024px)": {
//     left: "20px",
//   },
// });

// const LoginLayoutImageContainer = styled("img")({
//   width: "50%",
//   "@media(max-width:1024px)": {
//     display: "none",
//   },
// });

// const LoginLayout = ({ children }) => {
//   const navigate = useNavigate();
//   return (
//     <LoginLayoutWrapper>
//       <CompanyLogoContainer
//         src={"/images/companyLogo.png"}
//         onClick={() => navigate("/")}
//       />

//       {children}
//       <LoginLayoutImageContainer
//         component={"img"}
//         sx={{ objectFit: "contain" }}
//         src={"/images/ForgotPasswordValidation.png"}
//       />
//     </LoginLayoutWrapper>
//   );
// };

// export default LoginLayout;

import styled from "@emotion/styled";
import { Box, Grid } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const LoginLayoutWrapper = styled("div")({
  height: "100dvh",
  width: "100dvw",
  overflow: "hidden",
  display: "flex",
  flexDirection: "row",
  position: "relative",
  paddingLeft: "100px",
  "@media(max-width:1255px)": {
    paddingLeft: "0px",
    overflowY: "scroll",
    // padding: "10px 10px",
  },
});

const CompanyLogoContainer = styled("img")({
  position: "absolute",
  top: "20px",
  width: "120px",
  "@media(max-width:1280px)": {
    left: "20px",
  },
});

const LoginLayoutImageContainer = styled("div")({
  width: "50%",
  "@media(max-width:1280px)": {
    display: "none",
  },
});

const LoginLayout = ({ children }) => {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  return (
    <LoginLayoutWrapper
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      <Link to="/">
        <CompanyLogoContainer src={"/images/boostlogo2.png"} />{" "}
      </Link>

      {children}

      <Box
        sx={{
          top: "-60px",
          width: "55%",
          height: "110dvh",
          position: "relative",
          backgroundImage: "url(/images/bg.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          "@media(max-width:1280px)": {
            display: "none",
          },
        }}
      >
        <Box
          component={"img"}
          src={"/images/businesswoman.png"}
          sx={{
            position: "absolute",
            right: "0%",
            width: "40vw",
            top: "4%",
          }}
        />
      </Box>
    </LoginLayoutWrapper>
  );
};

export default LoginLayout;
