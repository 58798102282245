import { useEffect } from "react";
import { Box } from "@mui/material";
import LearningLives from "./LearningLives";
import MonitorMeasure from "./MonitorMeasure";
import Welcome from "./Welcome";
import Discovery from "./Discovery";
import ActionPlanning from "./ActionPlanning";
import { useSelector } from "react-redux";
import LiveAnnouncement from "./LiveAnnouncement";
// import AdvisorDashboard from "./AdvisorDashboard";

export default function Home() {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <div style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}>
      <Welcome />
      <Discovery />
      {/* <LiveAnnouncement /> */}
      <LearningLives />
      <ActionPlanning />
      <MonitorMeasure />
    </div>
  );
}

// {/* <AdvisorDashboard />/ */}
