import {
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, height } from "@mui/system";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CancelIcon from "@mui/icons-material/Cancel";
import dayjs from "dayjs";
import { LoadingButton } from "@mui/lab";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { setSchedule } from "../../redux/features/actionScheduleRefresherSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "14px",
  minHeight: "500px",
  "@media(max-width:600px)": {
    minWidth: "96vw",
  },
};

const validationSchema = Yup.object({
  category: Yup.string().trim().required("Category is required."),
  descritpion: Yup.string()
    .trim()
    .required("Description is required.")
    .min(5, "Minimum 5 character are required."),
  date: Yup.object().required("Due date is required."),
  endTime: Yup.object().required("End Time is required."),
  startTime: Yup.object().required("Start Time is required."),
});
const ScheduleCallPopUp = ({
  setOpen,
  open,
  isCallListUpdated,
  setIsCallListUpdated,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isNetworkCallLoading, setIsNetworkCallLoading] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [listData, setListData] = useState([]);
  const dispatch = useDispatch();

  const actionScheduleRefresher = useSelector(
    (state) => state.actionScheduleRefresher.actionSchedule
  );
  const createAction = async (values) => {
    if (values?.date?.isSame(dayjs(), "day")) {
      if (values?.startTime?.isBefore(dayjs(), "minute")) {
        toast.error("Start time should be in future.");
        return;
      }
    }
    if (
      values?.startTime?.isAfter(values?.endTime) ||
      values?.startTime?.isSame(values?.endTime)
    ) {
      toast.error("End time should be in future");
      return;
    }
    setIsNetworkCallLoading(true);
    let startDateAndTime = dayjs(
      `${values?.date?.format("YYYY-MM-DD")}T${values?.startTime?.format(
        "HH:mm:ss.SSSZ"
      )}`
    );
    let endDateAndTime = dayjs(
      `${values?.date?.format("YYYY-MM-DD")}T${values?.endTime?.format(
        "HH:mm:ss.SSSZ"
      )}`
    );
    const payloadToSend = {
      user_id: sessionStorage.getItem("advisorId"),
      business_category_name: values?.category,
      meeting_description: values?.descritpion,
      meeting_start_date_time: startDateAndTime?.format(
        "YYYY-MM-DD HH:mm:ss.SSSZ"
      ),
      meeting_end_date_time: endDateAndTime?.format("YYYY-MM-DD HH:mm:ss.SSSZ"),
      timeZone: sessionStorage.getItem("timeZone"),
    };
    const options = {
      url: ApiConfig.createMeeting,
      method: "POST",
      data: payloadToSend,
    };
    const res = await apiWithToken(options);

    setIsNetworkCallLoading(false);
    toast.success(
      res?.data?.responseMessage || "Meeting created successfully."
    );
    dispatch(setSchedule());
    setOpen(false);
    setIsCallListUpdated((e) => !e);
  };
  const initialDataLoader = async () => {
    console.log("asasasas");
    const options = {
      url: ApiConfig.getAllCallCategory,
      method: "GET",
    };
    const res = await apiWithToken(options);
    if (!res) {
      return;
    }

    setListData(res?.data?.result?.actionTypes);
    console.log(listData, "lissssssssssss");
  };
  useEffect(() => {
    console.log(listData, "listtttt");
    initialDataLoader();
    console.log(isCallListUpdated, setIsCallListUpdated, "kjasbgfaf");
  }, [isCallListUpdated]);

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Box
        sx={{
          ...style,
          backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
        }}
      >
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <IconButton
              sx={{
                position: "absolute",
                right: "0px",
                top: "0px",
                color: "#1f1f1f",
              }}
              onClick={() => {
                setOpen(false);
              }}
            >
              <CancelIcon
                style={{
                  backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
                  borderRadius: "50%",
                }}
              />
            </IconButton>
            <Grid container item xs={12}>
              <Typography
                variant="h6"
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              >
                Schedule Call
              </Typography>
            </Grid>
            <Divider sx={{ width: "100%", marginTop: "10px" }} />
            <Grid container item xs={12} mt={2}>
              <Formik
                initialValues={{
                  category: "",
                  descritpion: "",
                  date: null,
                  startTime: null,
                  endTime: null,
                }}
                onSubmit={createAction}
                validationSchema={validationSchema}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                }) => (
                  <Form style={{ width: "100%" }}>
                    <Box
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      <label> Short Description</label>
                      <TextField
                        disabled={isNetworkCallLoading}
                        name="descritpion"
                        placeholder="Enter description"
                        sx={{ mt: 1 }}
                        fullWidth
                        inputProps={{
                          style: {
                            height: "10px",
                            backgroundColor:
                              currentTheme == "DARK" ? "#fff" : "#fff",
                          },
                          maxLength: 200,
                        }}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: "0px",
                          },
                        }}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={
                          touched.descritpion && Boolean(errors.descritpion)
                        }
                        helperText={touched.descritpion && errors.descritpion}
                      />
                    </Box>
                    <Box
                      mt={2}
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      <label> Category</label>
                      <Select
                        disabled={isNetworkCallLoading}
                        name="category"
                        value={values.category}
                        onChange={(e) => {
                          handleChange(e);
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        inputProps={{
                          style: { height: "10px" },
                        }}
                        sx={{
                          mt: 1,
                          "& .MuiSelect-select": {
                            padding: "10.5px 14px",
                            backgroundColor:
                              currentTheme == "DARK" ? "#fff" : "#fff",
                          },
                        }}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: "0px",
                          },
                        }}
                        error={touched.category && Boolean(errors.category)}
                        helperText={touched.category && errors.category}
                      >
                        {listData?.map((item, index) => (
                          <MenuItem value={item?.description} key={index}>
                            {item?.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText sx={{ color: "#d32f2f" }}>
                        {Boolean(touched.category) &&
                          Boolean(errors.category) &&
                          errors.category}
                      </FormHelperText>
                    </Box>

                    {/* <Box mt={2}>
                        <label>Select Owner</label>
                        <Select
                          disabled={isNetworkCallLoading}
                          name="businessOwner"
                          value={values.businessOwner}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          sx={{
                            mt: 1,
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={
                            touched.businessOwner &&
                            Boolean(errors.businessOwner)
                          }
                          helperText={
                            touched.businessOwner && errors.businessOwner
                          }
                        >
                          {ownerList.map((data, i) => {
                            return (
                              <MenuItem key={i} value={data}>
                                {data?.full_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {Boolean(touched.businessOwner) &&
                            Boolean(errors.businessOwner) &&
                            errors.businessOwner}
                        </FormHelperText>
                      </Box> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <Box
                        sx={{
                          width: "100%",
                          mt: 2,
                        }}
                        components={["DateTimePicker"]}
                      >
                        <label
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          Meeting Date
                        </label>
                        <DatePicker
                          disabled={isNetworkCallLoading}
                          name="date"
                          value={values.date}
                          onChange={(e) => {
                            setFieldValue("date", e);
                          }}
                          disablePast={true}
                          sx={{
                            mt: 1,
                            width: "100%",
                            backgroundColor:
                              currentTheme == "DARK" ? "#fff" : "#fff",

                            "& .MuiOutlinedInput-root": {
                              borderRadius: "4px",
                            },

                            "& .MuiInputLabel-root": {
                              top: "-5px",
                            },
                            input: {
                              padding: "10px 10px",
                            },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={touched.assign && Boolean(errors.assign)}
                          helperText={touched.assign && errors.assign}
                        />
                        <FormHelperText sx={{ color: "#d32f2f" }}>
                          {Boolean(touched.date) &&
                            Boolean(errors.date) &&
                            errors.date}
                        </FormHelperText>
                      </Box>
                    </LocalizationProvider>

                    <>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            width: "100%",
                            mt: 2,
                          }}
                          components={["TimePicker"]}
                        >
                          <label
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Start time
                          </label>
                          <TimePicker
                            disabled={isNetworkCallLoading}
                            name="startTime"
                            value={values.startTime}
                            onChange={(e) => {
                              // if (e.isBefore(dayjs()) || e.isSame(dayjs())) {
                              //   toast.error('Start time should be in future')
                              //   setFieldValue('startTime', null)
                              //   return
                              // } else {
                              setFieldValue("startTime", e);
                              // }
                              // setFieldValue("startTime", e);
                            }}
                            // shouldDisableTime={(time) => {
                            //   return time.isBefore(dayjs(), 'minute')
                            // }}
                            sx={{
                              mt: 1,
                              width: "100%",
                              backgroundColor:
                                currentTheme == "DARK" ? "#fff" : "#fff",

                              "& .MuiOutlinedInput-root": {
                                borderRadius: "4px",
                              },

                              "& .MuiInputLabel-root": {
                                top: "-5px",
                              },
                              input: {
                                padding: "10px 10px",
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                marginLeft: "0px",
                              },
                            }}
                            error={
                              touched.startTime && Boolean(errors.startTime)
                            }
                            helperText={touched.startTime && errors.startTime}
                          />
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {Boolean(touched.startTime) &&
                              Boolean(errors.startTime) &&
                              errors.startTime}
                          </FormHelperText>
                        </Box>
                      </LocalizationProvider>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            width: "100%",
                            mt: 2,
                          }}
                          components={["TimePicker"]}
                        >
                          <label
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            End time
                          </label>
                          <TimePicker
                            disabled={isNetworkCallLoading}
                            name="endTime"
                            value={values.endTime}
                            // shouldDisableTime={(time) => {
                            //   return time.isBefore(values.startTime, 'minute')
                            // }}
                            onChange={(e) => {
                              setFieldValue("endTime", e);
                            }}
                            sx={{
                              mt: 1,
                              width: "100%",
                              backgroundColor:
                                currentTheme == "DARK" ? "#fff" : "#fff",

                              "& .MuiOutlinedInput-root": {
                                borderRadius: "4px",
                              },

                              "& .MuiInputLabel-root": {
                                top: "-5px",
                              },
                              input: {
                                padding: "10px 10px",
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                marginLeft: "0px",
                              },
                            }}
                            error={touched.endTime && Boolean(errors.endTime)}
                            helperText={touched.endTime && errors.endTime}
                          />
                          <FormHelperText sx={{ color: "#d32f2f" }}>
                            {Boolean(touched.endTime) &&
                              Boolean(errors.endTime) &&
                              errors.endTime}
                          </FormHelperText>
                        </Box>
                      </LocalizationProvider>
                    </>

                    <Grid
                      container
                      item
                      xs={12}
                      justifyContent={"center"}
                      gap={2}
                      mt={6}
                      mb={2}
                    >
                      <LoadingButton
                        loading={isNetworkCallLoading}
                        variant="contained"
                        style={{ backgroundColor: "#cc3d0b" }}
                        type="submit"
                        sx={{
                          width: "180px",
                          py: 1.2,
                          textTransform: "none",
                          "&.MuiButton-containedLoading": {
                            color: "#fff",
                          },
                        }}
                      >
                        Schedule
                      </LoadingButton>
                    </Grid>
                  </Form>
                )}
              </Formik>
            </Grid>
          </>
        )}
      </Box>
    </Modal>
  );
};

export default ScheduleCallPopUp;
