import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import QuizPercentage from "../../widgets/assessmentPersentage/QuizPercentage";
import Assessments from "../../components/assessments/QuizAssessment";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import QuizAssessment from "../../components/assessments/QuizAssessment";
import { useSelector } from "react-redux";
export default function QuizManagement() {
  const [activeStep, setActiveStep] = useState(1);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const [userDetails, setUserDetails] = useState({});
  const [quizPercentage, setQuizPercentage] = useState(0);

  console.log("userData===>>>>", userDetails?.id);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [quizAssessmentData, setQuizAssessmentData] = useState([]);
  const [quizAnswersData, setQuizAnswersData] = useState([]);
  const [pageLimit, setPageLimit] = useState(1);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const { state } = useLocation();
  const { moduleId, moduleName, courseId, completedVideo } = state;

  console.log(state);
  const userData = sessionStorage.getItem("user");
  console.log("totalPercentage==>>", userData?.user_id);
  const handleNextStep = async () => {
    if (activeStep < pageLimit) {
      const nextPage = activeStep + 1;

      try {
        const res = await customAxios({
          method: "GET",
          url: ApiConfig.getAllQuiz,
          headers: {
            token: sessionStorage.getItem("userToken"),
          },
          params: {
            module_id: moduleId,
            page: nextPage,
            limit: 4,
          },
        });

        if (res?.data?.statusCode === 200) {
          setQuizAssessmentData(res?.data?.result?.data);
          setActiveStep(nextPage);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handlePreviousStep = async () => {
    if (activeStep > 1) {
      const prevPage = activeStep - 1;

      try {
        const res = await customAxios({
          method: "GET",
          url: ApiConfig.getAllQuiz,
          headers: {
            token: sessionStorage.getItem("userToken"),
          },
          params: {
            module_id: moduleId,
            page: prevPage,
            limit: 4,
          },
        });

        if (res?.data?.statusCode === 200) {
          setQuizAssessmentData(res?.data?.result?.data);
          setActiveStep(prevPage);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getQuizAssessmentData = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllQuiz,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          module_id: moduleId,
          page: activeStep,
          limit: 4,
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result?.data);
        setQuizAssessmentData(res?.data?.result?.data);
        setPageLimit(res?.data?.result?.totalPages);
        console.log(quizAssessmentData, "ghjghhggh");
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getUserDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getUserDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        setUserDetails(res?.data?.result);

        getQuizAnswersData(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getQuizAnswersData = async (ID) => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllQuizAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          module_id: moduleId,
          user_id: ID?.id,
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result?.data);
        setQuizAnswersData(res?.data?.result?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const updateQuizPercentage = (percentage) => {
    setQuizPercentage(percentage);
  };
  useEffect(() => {
    getQuizAssessmentData();
    getUserDetails();
  }, []);
  useEffect(() => {
    getQuizAnswersData();
  }, [userData]);

  return (
    <Box
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      <Container>
        <Typography
          fontWeight={"700"}
          fontSize="45px"
          style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
        >
          {moduleName} Quiz
        </Typography>
        <Box mt={4}>
          <QuizPercentage type="quiz" percentage={quizPercentage} />
        </Box>
        <Grid spacing={2} container mt={4}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box width="100%" component="img" src="/images/quizlogo.png" />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={8}>
            {quizAssessmentData?.length > 0 && (
              <QuizAssessment
                pageTitle={quizAssessmentData.map(
                  (question) => question.question
                )}
                questionId={quizAssessmentData.map((question) => question.id)}
                courseId={courseId}
                moduleId={moduleId}
                renderData={quizAssessmentData}
                activeStep={activeStep}
                handlePreviousStep={handlePreviousStep}
                handleNextStep={handleNextStep}
                pageLimit={pageLimit}
                initialAnswers={initialAnswers}
                updateQuizPercentage={updateQuizPercentage}
                // handleInitialAnswers={handleInitialAnswers}?
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
