import React from "react";
import "./common.scss";

export default function Pagination({ item, selected }) {
  return (
    <div className="paginationWrapper">
      <div>Previous</div>
      {[1, 2].map((page, i) => {
        return <div key={i}>{page}</div>;
      })}
      <div>Next</div>
    </div>
  );
}
