import { Box, Grid, Typography, Button } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useState } from "react";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { FaAddressCard } from "react-icons/fa";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const JoinCourseDetail = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const { data } = state;
  console.log(data?.course, "===data>>>>");
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  useEffect(() => {
    console.log(data, "askjbzbadf");
  }, [data]);

  const joinHandler = async (data) => {
    try {
      setIsLoading(true);
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userEnrollNewCourse,
        data: {
          course_id: data?.course?.id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      setIsLoading(false);
      if (!res?.data) {
        return;
      }
      console.log(res);
      toast.success(res?.data?.result || "Course enrolled Successfully.");
      // navigate("/learning&lives");
      navigate("/learning-detail/module", {
        state: { data: data },
      });
    } catch (error) {
      console.log(error, "asjahsazxcdf");
      setIsLoading(false);
    }
  };

  const updataEnrolledCourseApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.updateEnrolledCourse,
        data: {
          course_id: data?.course?.id,
          completed_videos: 1,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      setIsLoading(false);
      console.log("res====>>>>", res);
      if (!res?.data) {
        return;
      }
      // toast.success(res?.data?.result || "Course enrolled Successfully.");
      // navigate("/learning&lives");
    } catch (error) {
      console.log(error, "asjahsazxcdf");
      setIsLoading(false);
    }
  };

  const videoRef = useRef(null);

  const getCurrentTime = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      console.log("CurrentTime=>>", currentTime);
    }
  };
  const [halfwayFlag, setHalfwayFlag] = useState(false);
  console.log("halfwayFlag==>", halfwayFlag);
  const checkHalfway = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      if (currentTime >= duration / 2) {
        setHalfwayFlag(true);
        videoRef.current.removeEventListener("timeupdate", checkHalfway);
      }
    }
  };
  useEffect(() => {
    if (halfwayFlag) {
      updataEnrolledCourseApi();
    }
  }, [halfwayFlag]);

  return (
    <Grid
      container
      item
      xs={12}
      justifyContent={"center"}
      alignItems={"center"}
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      <Grid container xs={10} justifyContent={"center"} alignItems={"center"}>
        <Grid container item xs={12} flexDirection={"column"}>
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            variant="h4"
            style={{ fontWeight: "600", marginBottom: "14px" }}
          >
            Learning Live
          </Typography>
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            variant="h6"
          >
            Join & Grow
          </Typography>
        </Grid>

        {/* card-stat */}
        <Grid
          container
          item
          xs={12}
          mt={4}
          sx={{
            border: "1px solid #eca067",
            p: 2,
            borderRadius: "14px",
          }}
        >
          <Grid
            container
            item
            xs={12}
            alignItems={"center"}
            justifyContent={"flex-start"}
            gap={1}
          >
            <FaAddressCard color="#d2460a" fontSize={"34px"} />

            <Typography
              color={currentTheme == "DARK" ? "#fff" : "#000"}
              variant="h4"
              style={{ fontWeight: "500", marginLeft: "14px" }}
            >
              {data?.course?.courseName ? data?.course?.courseName : "NA"}
            </Typography>
          </Grid>
          <Typography variant="body1" sx={{ color: "#a3a3a3", mt: 2 }}>
            {data?.course?.description ? data?.course?.description : "NA"}
          </Typography>

          <Box
            mt={4}
            sx={{
              width: "100%",
              height: { sm: "500px", xs: "auto" },
              border: "none",
              "& video": {
                borderRadius: "20px",
              },
            }}
          >
            <video
              src={data?.course?.video_url}
              height="100%"
              width="100%"
              controls
              ref={videoRef}
              onTimeUpdate={checkHalfway}
            />
          </Box>

          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
            mt={4}
          >
            <LoadingButton
              loading={isLoading}
              disabled={isLoading}
              onClick={() => {
                joinHandler(data);
                // updataEnrolledCourseApi()
              }}
              variant="contained"
              sx={{
                color: "#fff",
                py: 1,
              }}
              style={{
                background: "#cd3e0b",
                width: "260px",
              }}
            >
              Launch
            </LoadingButton>
          </Grid>
        </Grid>
        {/* card-end */}
      </Grid>
    </Grid>
  );
};

export default JoinCourseDetail;
