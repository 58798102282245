import React, { useEffect, useState } from "react";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useNavigate } from "react-router";
import { styled } from "@mui/system";

const MainComponent = styled("Box")(({ theme }) => ({
  "& .myCourceBox": {
    "& .mainBox": {
      borderRadius: "10px",
      border: "1px solid #E46703",
      padding: "20px",
    },
    "& .contentBox": {
      display: "flex",
      "& img": {
        width: "100%",
        maxWidth: "400px",
        height: "250px",
        position: "relative",
        top: "0px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        objectFit: "fill",
        borderRadius: "20px !important",
      },
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
    "& .sideContentBox": {
      marginLeft: "16px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
      },
    },
  },
}));
const MyCourse = () => {
  const [myCourseList, setMyCourseList] = useState([]);
  console.log("myCourseList", myCourseList);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    name: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    getEnrollCourseList();
  }, [filter]);

  const getEnrollCourseList = async () => {
    try {
      setIsLoading(true);
      const res = await customAxios({
        url: ApiConfig.enrolledCoursesList,
        method: "GET",
        params: Object.fromEntries(
          Object.entries(filter).filter(([_, v]) => v != "")
        ),
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      setMyCourseList(res?.data?.result?.data || []);
      console.log(res?.data?.result, "akjszkbxsbzb");
      setTotalPages(res?.data?.result?.totalPages || 1);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"500px"}
      >
        <CircularProgress
          sx={{
            color: "#c9380c",
          }}
          size={"40px"}
        />
      </Grid>
    );
  }
  if (!isLoading && myCourseList?.length == 0) {
    return (
      <Grid
        container
        item
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"500px"}
      >
        <Typography
          variant="h6"
          style={{ color: currentTheme === "DARK" ? "#fff" : "#000" }}
        >
          No enrolled course found
        </Typography>
      </Grid>
    );
  }

  return (
    <MainComponent>
      <Box className="myCourceBox">
        <Grid container spacing={3}>
          {myCourseList &&
            myCourseList?.map((data, i) => {
              return (
                <Grid key={i} item xs={12} sm={12} md={12}>
                  <Box key={i} className="mainBox">
                    <Box className="displaySpacebetween">
                      <Typography
                        variant="h5"
                        style={{
                          color: "rgba(38, 38, 38, 1)",
                          fontWeight: 600,
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {data?.courseDetails?.courseName
                          ? data?.courseDetails?.courseName?.length > 40
                            ? data?.courseDetails?.courseName?.slice(0, 40) +
                              "..."
                            : data?.courseDetails?.courseName
                          : "NA"}
                      </Typography>
                      <CircularProgressWithLabel
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                        value={data?.percentage ? data?.percentage : 0}
                      />
                    </Box>
                    <hr
                      style={{
                        margin: "20px 0px",
                        color: "rgba(0, 0, 0, 0.2)",
                      }}
                    />
                    <Box className="contentBox">
                      <Box>
                        <img src={data?.courseDetails?.image_url} />
                      </Box>
                      <Box className="sideContentBox">
                        <Typography
                          variant="body1"
                          style={{
                            color: "rgba(32, 33, 35, 0.87)",
                            fontWeight: 300,
                            fontSize: "16px",
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          {data?.courseDetails?.description
                            ? data?.courseDetails?.description?.length > 150
                              ? data?.courseDetails?.description?.slice(
                                  0,
                                  150
                                ) + "..."
                              : data?.courseDetails?.description
                            : "NA"}
                        </Typography>
                        <Box className="displayStart">
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "400",
                              fontSize: "18px",
                              color: "rgba(8, 5, 21, 0.6)",
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Available Videos:
                          </Typography>
                          <Typography
                            style={{
                              color: "rgba(8, 5, 21, 1)",
                              fontWeight: 600,
                              fontSize: "20px",
                              marginLeft: "8px",
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                            variant="subtitle1"
                          >
                            {" "}
                            {data?.total_videos ? data?.total_videos : "0"}
                          </Typography>
                        </Box>
                        <Box className="displayStart">
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "400",
                              fontSize: "18px",
                              color: "rgba(8, 5, 21, 0.6)",
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Completed Videos:
                          </Typography>
                          <Typography
                            style={{
                              color: "rgba(8, 5, 21, 1)",
                              fontWeight: 600,
                              fontSize: "20px",
                              marginLeft: "8px",
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                            variant="subtitle1"
                          >
                            {" "}
                            {data?.course?.allCompletedVideos
                              ? data?.course?.allCompletedVideos
                              : "0"}
                          </Typography>
                        </Box>

                        <Button
                          sx={{
                            textTransform: "none",
                            color: "#fff",
                            px: 6,
                            mt: { md: 4, xs: 0 },
                            py: 1,
                          }}
                          style={{
                            backgroundImage:
                              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                          }}
                          onClick={() => {
                            navigate("/learning-detail/module", {
                              state: { data: data },
                            });
                          }}
                        >
                          View
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
        <Box className="displayCenter" my={2}>
          {totalPages > 1 && (
            <Pagination
              size="small"
              count={totalPages}
              defaultPage={filter.page}
              onChange={(e, page) => {
                setCurrentPage(page);
                const updatedFilter = { ...filter, page: page };
                setFilter(updatedFilter);
              }}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "rgba(226, 99, 4, 1) !important",
                  color: "#fff",
                },
                "& .MuiPaginationItem-page:hover": {
                  backgroundColor: "rgba(226, 99, 4, 1)",
                  color: "#fff",
                },
                "& .MuiPaginationItem-page": {
                  borderRadius: "1px solid rgba(217, 217, 217, 1)",
                },
              }}
            />
          )}
        </Box>
      </Box>
    </MainComponent>
  );
};

export default MyCourse;

function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
        mb: { md: 1, xs: 0 },
      }}
    >
      <CircularProgress
        variant="determinate"
        {...props}
        size={"50px"}
        sx={{
          color: "#c9370d",
        }}
      />
      <Box
        sx={{
          top: "15px",
          left: "8px",
          // bottom: 0,
          // right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
