import React, { useState } from "react";
import "./common.scss";
import { useSelector } from "react-redux";
export default function RadioButton({
  formField,
  error,
  key,
  setAnswersDetails,
  answersDetails,
  handleUpdateForm,
  serialNumber,
}) {
  const [answerId, setAnswerId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const handleChange = (e, item) => {
    if (answersDetails[formField?.id]?.answer_id) {
      setAnswerId(answersDetails[formField?.id]?.answer_id);
    }
    const version = parseInt(sessionStorage.getItem("version"));
    const isValue = e?.target?.value;
    setAnswersDetails({
      ...answersDetails,
      [formField?.id]: {
        question_id: formField?.id,
        assesment_id: formField?.assesment_master_id,
        assesment_category_id: formField?.assesment_category_,
        answer: isValue,
        category_id: formField?.category_id,

        version_control: version || formField?.version_control,
      },
    });
  };

  const handleUpdateChange = (e) => {
    if (answerId) {
      const data = {
        business_owner_id: sessionStorage.getItem("userId"),
        answer_id: answerId,

        answer: answersDetails[formField?.id]?.answer,
      };
      handleUpdateForm(data);
    }
  };
  return (
    <div className="checkboxAreaContainer" key={key}>
      {formField?.question && (
        <p
          className="heading"
          style={{
            color: currentTheme === "DARK" ? "#fff" : "#000",
            e: { color: "#E46703" },
          }}
        >
          {/* {serialNumber + 1}. */}
          {formField?.question}
        </p>
      )}
      <div>
        {formField?.answer.length > 0 &&
          formField?.answer.map((item, i) => {
            return (
              <div
                style={{ margin: "15px 0" }}
                className="checkboxWrapper"
                key={i}
              >
                <input
                  type="radio"
                  id={i}
                  name={formField?.id}
                  value={item}
                  onChange={(e) => handleChange(e, item)}
                  onBlur={handleUpdateChange}
                  checked={answersDetails[formField?.id]?.answer === item}
                />
                <label
                  htmlFor={i}
                  style={{
                    color: currentTheme === "DARK" ? "#fff" : "#000",
                    e: { color: "#E46703" },
                  }}
                >
                  {item}
                </label>
              </div>
            );
          })}
      </div>
      <span>{error}</span>
    </div>
  );
}
