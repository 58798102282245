import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { Formik, Form } from "formik";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ApiConfig } from "../../services/ApiConfig";

import { cleanDigitSectionValue } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { ToastContainer, toast } from "react-toastify";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";

const LoginWrapper = styled("div")({
  height: "100dvh",
  width: "45%",
  overflow: "hidden",
  marginTop: "200px",
  marginRight: "100px",
  "@media(max-width:1248px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    marginTop: "200px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
  // backgroundColor: "yellow",
});

const FormContainer = styled(Form)({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",

  "@media(max-width:640px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FormTextField = styled(TextField)({
  height: "60px!important",
  width: "35vw",
  marginTop: "4px",

  "@media(max-width:1024px)": {
    width: "50vw !important",
    // margin: "10px 0px",
  },
  "@media(max-width:600px)": {
    width: "70vw !important",
    // margin: "10px 0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
});

const initialValues = {
  contactInfo: "",
};

const validationSchema = Yup.object({
  contactInfo: Yup.string()

    .email("Please enter a valid email address.")
    .required("Email address is required.")
    .matches(
      /^[a-zA-Z0-9._%+$-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    )
    .min(8, "Email address must be at least 8 characters."),
});

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const navigate = useNavigate();
  const handleFogetPassword = async (values) => {
    console.log("njn");
    setLoading(true);

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.resentOtp,
        data: { email: values.contactInfo },
      });

      if (res.data?.statusCode == 200) {
        console.log(res);
        // sessionStorage.setItem("userToken", res.data.result.token);

        navigate("/otp", {
          state: { email: values?.contactInfo, type: "forget" },
        });
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);

        return null;
      }
    } catch (error) {
      console.log(error, "aksfgaf");

      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
    }
  };
  const useStyle = {
    text: {
      textAlign: "left",
      fontSize: "18px",
      width: "35vw",
      "@media(max-width:990px)": {
        width: "50vw",
        textAlign: "center",
      },
      "@media(max-width:553px)": {
        width: "71vw",
        textAlign: "center",
      },
    },
  };
  return (
    <LoginWrapper>
      <Typography
        style={{ textAlign: "left" }}
        color={currentTheme == "DARK" ? "#fff" : "#000"}
        variant="h4"
        fontWeight={"500"}
      >
        Forgot Password
      </Typography>
      <Typography
        color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
        sx={useStyle.text}
        variant="body1"
        fontWeight={"500"}
        mt={2}
      >
        Please enter your registered email address and we will send you OTP to
        reset your password.
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log("hddu");
          console.log(values);
          handleFogetPassword(values);
        }}
      >
        {({ errors, touched, values, handleChange, handleBlur }) => (
          <FormContainer>
            <FormControl>
              <label
                style={{
                  textAlign: "left",
                  fontSize: "18px",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                Email
              </label>
              <FormTextField
              style={{ backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff" }}
                inputProps={{
                  maxLength: 60,
                  style: {
                    height: "30px",
                    marginBottom: "0px",
                    borderRadius: "8px",
                  },
                }}
                variant="outlined"
                name="contactInfo"
                placeholder="Enter you email address"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.contactInfo && Boolean(errors.contactInfo)}
                helperText={touched.contactInfo && errors.contactInfo}
                onInput={(e) => {
                  let value = e.target.value;

                  value = value.replace(/\s/g, "");

                  e.target.value = value.toLowerCase();
                  setErrorMessage("");
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
              />
            </FormControl>
            <Typography
              sx={{
                textAlign: { sm: "left", xs: "center" },
                color: "#d32f2f",
                fontSize: "14px",
                width: { sm: "40vw", xs: "80vw" },
              }}
              fontWeight={"500"}
              mt={2}
            >
              {errorMessage}
            </Typography>
            <Box
              mt={3}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  borderRadius: "10px",
                  fontSize: "18px",
                  paddingX: "40px",
                  paddingY: "10px",
                  textTransform: "none",
                }}
                disabled={loading}
                variant="contained"
                type="submit"
              >
                Continue
                {loading && (
                  <CircularProgress
                    size={20} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
          </FormContainer>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default Login;
