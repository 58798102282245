import React, { useEffect, useState } from "react";
import "./common.scss";
import { Mic, SettingsVoice } from "@mui/icons-material";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Dialog } from "@mui/material";
import { useSelector } from "react-redux";

export default function TextArea({
  placeHolder,
  formField,
  setAnswersDetails,
  answersDetails,
  key,
  handleUpdateForm,
}) {
  const [currentId, setCurrentId] = useState("");
  const [open, setOpen] = useState(false);
  const [answerId, setAnswerId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  const handleChange = (e) => {
    if (answersDetails[formField?.id]?.answer_id) {
      setAnswerId(answersDetails[formField?.id]?.answer_id);
    }
    const version = parseInt(sessionStorage.getItem("version"));
    setAnswersDetails({
      ...answersDetails,
      [e.target?.name]: {
        question_id: formField?.id,
        assesment_id: formField?.assesment_master_id,
        assesment_category_id: formField?.assesment_category_,
        answer: e.target.value,
        category_id: formField?.category_id,
        version_control: version || formField?.version_control,
      },
    });
  };
  const handleUpdateChange = (e) => {
    if (answerId) {
      const data = {
        business_owner_id: sessionStorage.getItem("userId"),
        answer_id: answerId,
        answer: answersDetails[formField?.id]?.answer,
      };
      handleUpdateForm(data);
    }
  };
  const handleSpeech = (formField) => {
    resetTranscript();
    setCurrentId(formField?.id);
    setOpen(true);
    // startSpeechToText();
    SpeechRecognition.startListening({ continuous: true });
  };

  const handleStopSpeech = () => {
    SpeechRecognition.stopListening();
    const version = parseInt(sessionStorage.getItem("version"));
    if (transcript) {
      setAnswersDetails({
        ...answersDetails,
        [formField?.id]: {
          question_id: formField?.id,
          assesment_id: formField?.assesment_master_id,
          assesment_category_id: formField?.assesment_category_,
          answer: transcript,
          version_control: version || formField?.version_control,
        },
      });
    }
    resetTranscript();
    setOpen(false);
  };

  console.log("answersid", answersDetails);

  return (
    <>
      <div className="textAreaContainer">
        {formField?.question && (
          <p
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            className="heading"
          >
            {formField?.question}
          </p>
        )}
        <textarea
          placeholder={placeHolder}
          key={formField.id}
          onChange={handleChange}
          onBlur={handleUpdateChange}
          value={
            answersDetails[formField?.id]?.answer !== undefined
              ? answersDetails[formField?.id]?.answer
              : ""
          }
          name={formField?.id}
        />
        {browserSupportsSpeechRecognition && (
          <div className="micIcon">
            {currentId === formField?.id && listening ? (
              <SettingsVoice onClick={() => handleStopSpeech(formField)} />
            ) : (
              <Mic onClick={() => handleSpeech(formField)} />
            )}
          </div>
        )}
        {/* <span>{error}</span> */}
      </div>

      <Dialog onClose={() => handleStopSpeech(formField)} open={open}>
        <div className="recordingOverlay">
          <SettingsVoice
            onClick={() => handleStopSpeech(formField)}
            style={{ fontSize: "3em" }}
          />
          {!transcript ? <h2>Listening...</h2> : <p>{transcript}</p>}
          <button onClick={() => handleStopSpeech(formField)}>Stop</button>
        </div>
      </Dialog>
    </>
  );
}
