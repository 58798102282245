import React, { useState } from "react";
import "./common.scss";
import { useSelector } from "react-redux";

export default function DropDown({
  formField,
  error,
  key,
  setAnswersDetails,
  answersDetails,
  handleUpdateForm,
}) {
  const [selectedAnswer, setSelectedAnswer] = useState("");
  const [answerId, setAnswerId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleChange = (e) => {
    if (answersDetails[formField?.id]?.answer_id) {
      setAnswerId(answersDetails[formField?.id]?.answer_id);
    }
    const selectedValue = e.target.value;
    setSelectedAnswer(selectedValue);
    console.log(selectedValue, "gasdjhagsjas");
    const version = parseInt(sessionStorage.getItem("version"));

    setAnswersDetails({
      ...answersDetails,
      [formField?.id]: {
        question_id: formField?.id,
        assesment_id: formField?.assesment_master_id,
        assesment_category_id: formField?.assesment_category_,
        answer: selectedValue,
        category_id: formField?.category_id,

        version_control: version || formField?.version_control,
      },
    });

    handleUpdateChange();
  };

  const handleUpdateChange = () => {
    if (answerId) {
      const data = {
        business_owner_id: sessionStorage.getItem("userId"),
        answer_id: answerId,
        answer: answersDetails[formField?.id]?.answer,
      };
      handleUpdateForm(data);
    }
  };

  return (
    <div className="dropDownContainer" key={key}>
      {formField?.question && (
        <p
          style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          className="heading"
        >
          {formField?.question}
        </p>
      )}
      <div>
        <select
          value={
            selectedAnswer
              ? selectedAnswer
              : answersDetails[formField?.id]?.answer
          }
          onChange={handleChange}
          onBlur={handleUpdateChange}
        >
          {/* <option value="">Select an option</option> */}
          {formField?.answer.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
        </select>
      </div>
      <span>{error}</span>
    </div>
  );
}
