import React, { useEffect, useState } from "react";
import "./assessment.scss";
import Stepper from "../stepper/Stepper";
import AssessMentPercentage from "../../widgets/assessmentPersentage/AssessMentPercentage";
import TextArea from "../common/TextArea";
import TextField from "../common/TextField";
import CheckOption from "../common/CheckOption";
import MultiTextField from "../common/MultiTextField";
import { ApiConfig } from "../../services/ApiConfig";
import PasswordResetModal from "../PasswordResetModal";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import DropDown from "../common/DropDown";
import RadioButton from "../common/RadioButton";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import RadioButtonCohort from "../common/cohort/RaduiButtonCohort";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function Assessments({
  pageTitle,
  renderData,
  questionStatus,
  setQuestionStatus,
  activeStep,
  handlePreviousStep,
  handleNextStep,
  //   initialAnswers,
  //   handleInitialAnswers,
}) {
  const [stepper, setStepper] = useState([]);
  const [answerDetails, setAnswersDetails] = useState([]);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFillModalOpen, setIsFillModalOpen] = useState(false);
  const formDetails = renderData;
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  console.log(location);
  // const handleFormData = (data) => {
  //   const arrData = data?.assesmentCategoryMasters;
  //   const stepperData = data.map((step, i) => {
  //     return { step: i + 1, title: step?.funding_assessment_category_name };
  //   });
  //   setStepper(stepperData);
  // };

  useEffect(() => {
    // configureAnswerDetail();
  }, []);

  //   const configureAnswerDetail = () => {
  //     const version = parseInt(sessionStorage.getItem("version"));
  //     if (initialAnswers !== "Assesment not found.") {
  //       const ans = initialAnswers?.map((data) => {
  //         return {
  //           [data?.question_id]: {
  //             answer: data?.answer,
  //             answer_id: data?.id,
  //             // assesment_id: data?.assesment_id,
  //             question_id: data?.question_id,
  //             // assesment_category_id: data?.assesment_category_id,
  //             version_control: version,
  //           },
  //         };
  //       });
  //       const mergedAnswers = Object.assign({}, ...ans);
  //       setAnswersDetails(mergedAnswers);
  //     }
  //   };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIsFillModalOpen(false);
  };

  const handleUpdateForm = async (data) => {
    // try {
    //   const res = await customAxios({
    //     method: "POST",
    //     url: ApiConfig.updateUserAssesmentAnswers,
    //     headers: {
    //       token: sessionStorage.getItem("userToken"),
    //     },
    //     data: {
    //       business_owner_id: sessionStorage.getItem("userId"),
    //       assesment_id: sessionStorage.getItem("assessmentId"),
    //       version_control: parseInt(sessionStorage.getItem("version")),
    //       answer: [data],
    //     },
    //   });
    //   if (res.data.statusCode === 200) {
    //     handleInitialAnswers();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  console.log(formDetails);
  const handleSubmitForm = async (type) => {
    const updatedAnsers = formDetails.map((item, i) => {
      if (answerDetails[item.id]) {
        return answerDetails[item.id];
      } else {
        return {
          answer: "",
          question_id: item?.id,
        };
      }
    });

    setIsDataLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userApplyForCohort,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          answer: updatedAnsers,
          cohort_id: location?.state?.joinCohortId,
        },
      });
      if (res.data.statusCode === 200) {
        // handleInitialAnswers();
        if (type === "submit") {
          setIsModalOpen(true);
        } else if (type === "next") {
        } else {
          // toast.success("Answers saved successfully!");
        }
        setIsDataLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setIsDataLoading(false);
    }
  };
  const handleSubmitForm2 = async (type) => {
    const updatedAnsers = formDetails.map((item, i) => {
      if (answerDetails[item.id]) {
        return answerDetails[item.id];
      } else {
        return {
          answer: "",
          question_id: item?.id,
        };
      }
    });

    setIsDataLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userApplyForCohort,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          answer: updatedAnsers,
          cohort_id: location?.state?.joinCohortId,
        },
      });
      if (res.data.statusCode === 200) {
        // handleInitialAnswers();
        if (type === "submit") {
          navigate("/my-cohort");
        } else if (type === "next") {
        } else {
          // toast.success("Answers saved successfully!");
        }
        setIsDataLoading(false);
      }
    } catch (error) {
      console.log(error);
      toast.error(error);
      setIsDataLoading(false);
    }
  };

  const handleRenderForm = (form, i) => {
    switch (form?.answer_type) {
      case "textArea":
        return (
          <TextArea
            placeHolder="Type here..."
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            key={i}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
          />
        );
      case "radio":
        return (
          <RadioButton
            formField={form}
            serialNumber={i}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "dropDown":
        return (
          <DropDown
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "checkBox":
        return (
          <CheckOption
            formField={form}
            setAnswersDetails={setAnswersDetails}
            answersDetails={answerDetails}
            handleUpdateForm={handleUpdateForm}
            handleSubmitForm={handleSubmitForm}
            key={i}
          />
        );
      case "textBar":
        if (form?.answer.length > 0) {
          return (
            <MultiTextField
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              key={i}
            />
          );
        } else {
          return (
            <TextField
              placeHolder="Type here..."
              formField={form}
              setAnswersDetails={setAnswersDetails}
              answersDetails={answerDetails}
              handleUpdateForm={handleUpdateForm}
              handleSubmitForm={handleSubmitForm}
              key={i}
            />
          );
        }
      default:
        break;
    }
  };

  useEffect(() => {
    // handleFormData(renderData);
    // setIsFillModalOpen(true);
  }, []);

  const handleNewVersion = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.checkActiveVersionControl,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          assesment_id: sessionStorage.getItem("assessmentId"),
        },
      });
      if (res.data?.result) {
        const currentVersion = sessionStorage.getItem("version");
        sessionStorage.setItem("version", currentVersion - -1);
        createNewVersion();
      } else {
        toast.error(res.data?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const createNewVersion = async () => {
    const answerList = Object.values(answerDetails);
    const updatedAnsers = answerList.map((answer) => {
      return {
        ...answer,
        version_control: parseInt(sessionStorage.getItem("version")),
      };
    });
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userAssesmentAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: { answer: updatedAnsers },
      });
      if (res.data.statusCode === 200) {
        // handleInitialAnswers();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container maxWidth="lg">
      {" "}
      <div style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}>
        <div
          style={{ marginTop: "100px", borderRadius: "15px" }}
          className="titleWrapper"
        >
          <h2
            style={{
              color: currentTheme == "DARK" ? "#fff" : "#fff",
              marginTop: "20px",
            }}
          >
            {pageTitle}
          </h2>
          <h3
            style={{
              color: currentTheme == "DARK" ? "#fff" : "#fff",
              fontSize: "30px",
              fontWeight: "400",
              marginTop: "10px",
            }}
          >
            Application Questions
          </h3>
        </div>
        <div style={{ paddingTop: "0px" }}>
          {/* <div style={{ margin: "2.5rem 0 3.5rem 0" }}>
        <AssessMentPercentage type="funder" />
      </div> */}

          {isDataLoading ? (
            // <Box className="spinner">
            <Grid
              container
              item
              xs={12}
              sx={{
                minHeight: "500px",
              }}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <CircularProgress
                sx={{
                  color: "#e16204",
                }}
                size={"70px"}
              />
              <Typography
                color={currentTheme == "DARK" ? "#fff" : "#000"}
                mt={6}
                variant="subtitle2"
              >
                Loading Questionnaire
              </Typography>
            </Grid>
          ) : (
            <Grid container>
              {" "}
              <Grid
                className="scrollBarCustomColor"
                component={OverlayScrollbarsComponent}
                xs={12}
                md={6}
                item
                sx={{
                  height: "80vh",
                  overflowY: "scroll",
                  marginY: "20px",

                  // scrollbarWidth: "none", // For Firefox
                  // "&::-webkit-scrollbar": {
                  //   display: "none", // For Safari and Chrome
                  // },
                }}
              >
                {/* <Stepper data={stepper} activeStep={activeStep} /> */}

                <div className="header">
                  <h3
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    {/* {stepper[activeStep - 1]?.title} */}
                  </h3>
                  {/* <button onClick={() => handleNewVersion()}>
              Create new version
            </button> */}
                </div>
                <Box
                  // className="scrollBarCustomColor"
                  // component={OverlayScrollbarsComponent}
                  style={{ width: "95%" }}
                >
                  {" "}
                  <div className="dropDownContainer">
                    <p
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                      className="heading"
                    >
                      There are 28 questions and an acknowledgement in this
                      application, Are you ready?
                    </p>
                    <div>
                      <select
                        onChange={(e) => setQuestionStatus(e.target.value)}
                        value={questionStatus}
                      >
                        <option>{"Yes"}</option>
                        <option>{"No"}</option>
                      </select>
                    </div>
                  </div>
                  <div
                    style={{
                      display: questionStatus == "Yes" ? "block" : "none",
                    }}
                  >
                    {" "}
                    {formDetails?.length > 0 &&
                      formDetails
                        .filter((item) => {
                          if (item.is_active) {
                            return item;
                          }
                        })
                        .map((form, i) => handleRenderForm(form, i))}
                  </div>
                  <div
                    style={{
                      display: questionStatus == "Yes" ? "none" : "flex",
                      justifyContent: "center",
                    }}
                    className="btnContainer"
                  >
                    <div className="">
                      <Button
                        sx={{
                          background:
                            "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                          paddingX: "50px",
                          paddingY: "8px",
                          textTransform: "none",
                          fontSize: "18px",
                          borderRadius: "10px",
                        }}
                        variant="contained"
                        onClick={() => {
                          // setIsModalOpen(true);
                          handleSubmitForm2("submit");
                          // navigate("fundersList", {
                          //   state: { type: "result" },
                          // });
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </Box>
              </Grid>
              <Grid sx={{ display: { xs: "none", md: "block" } }} md={1}></Grid>
              <Grid
                md={5}
                xs={12}
                mt={7}
                item
                sx={{ display: { xs: "none", md: "block" } }}
              >
                <Box
                  width="30vw"
                  component="img"
                  src="/images/cohortassessment.png"
                />
              </Grid>
            </Grid>
          )}
        </div>
        <div
          style={{
            display: questionStatus == "Yes" ? "flex" : "none",
            justifyContent: "center",
            width: "60%",
            paddingBottom: "20px",
          }}
          className="btnContainer"
        >
          <div className="">
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                paddingX: "50px",
                paddingY: "8px",
                textTransform: "none",
                fontSize: "18px",
                borderRadius: "10px",
              }}
              variant="contained"
              onClick={() => {
                // setIsModalOpen(true);
                handleSubmitForm("submit");
                // navigate("fundersList", {
                //   state: { type: "result" },
                // });
              }}
            >
              Submit
            </Button>
          </div>
        </div>
        <PasswordResetModal
          type="cohortassessment"
          open={isModalOpen}
          onClose={handleCloseModal}
        />
        <PasswordResetModal
          type="fillassessment"
          open={isFillModalOpen}
          onClose={handleCloseModal}
        />
      </div>
    </Container>
  );
}
