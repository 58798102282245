import axios from "axios";
import React, { useState } from "react";
import OTPInput from "react-otp-input";
import { toast } from "react-toastify";
import { ApiConfig } from "../../services/ApiConfig";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";

const ChangePasswordOtpModal = ({ open, setOpen, funcToExecute, data }) => {
  const [otp, setotp] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const submitHandler = async () => {
    if (!otp) {
      toast.error("Otp is required.");
    }
    if (otp?.length > 4) {
      toast.error("Minimum 4 character are required");
    }

    const res = await axios({
      url: ApiConfig.verifyOtp,
      data: {
        email: data,
      },
    });

    console.log(res?.data, "asvjahds");
  };

  return (
    <React.Fragment>
      <Dialog open={open} style={{}}>
        <DialogTitle>Otp verification</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { md: "400px", xs: "auto" },
            // height: { xs: "300px" },
          }}
        >
          <OTPInput
            value={otp}
            onChange={(otp) => {
              setotp(otp);
            }}
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "10px",
            mb: "20px",
          }}
        >
          <Grid container item xs={5}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "gray" }}
            >
              No
            </Button>
          </Grid>
          <Grid container item xs={5}>
            <Button
              onClick={() => {
                // funcToExecute();
                // handleClose();
              }}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "#fe352c" }}
            >
              Yes
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ChangePasswordOtpModal;
