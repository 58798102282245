import {
  Box,
  Button,
  Container,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { fontSize, styled } from "@mui/system";
import { useLocation, useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import QuizPercentage from "../../widgets/assessmentPersentage/QuizPercentage";
import { FaKaggle, FaPlay } from "react-icons/fa";
import { useSelector } from "react-redux";
const CousreVideosSection = styled("Box")(({ theme }) => ({
  "& .mainCousreVideosBox": {
    [theme.breakpoints.down("md")]: {
      padding: "0px 40px",
    },
    "& .videoListBox": {
      backgroundColor: "#FBF8FC",
      display: "flex",
      borderRadius: "20px",
      border: "1px solid orange",
    },
    "& .thumbnailContainer": {
      width: "140px",
      height: "140px",
      marginRight: "16px",
      position: "relative",
    },

    "& .overlay": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    "& .play-icon": {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 100,
    },
    "& .moduleTutorial": {
      fontSize: "30px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "25px",
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },
    "& .videoNameText": {
      // fontWeight: 500,
      fontSize: "25px",
      // color: "#000",
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
      },
    },
  },
}));
const CousreVideos = () => {
  const { state } = useLocation();
  const data = state?.data;
  const navigate = useNavigate();
  const [videoList, setVideoList] = useState([]);
  const [videoTitle, setVideoTitle] = useState("");
  const [videoDescription, setVideoDescription] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [moduleName, setModuleName] = useState();
  const [moduleId, setModuleId] = useState();
  const [completedVideo, setCompletedVideo] = useState();
  const [quizSubmittedFlag, setQuizSubmittedFlag] = useState(false);
  const [courseId, setCourseId] = useState();
  const [videoId, setVideoId] = useState();
  const [hoveredThumbnail, setHoveredThumbnail] = useState(null);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [apiCalled, setApiCalled] = useState(false);
  console.log(data);
  const handleMouseEnter = (idx) => {
    setHoveredThumbnail(idx);
  };
  const totalPercentage = (completedVideo / videoList?.length) * 100;
  const handleMouseLeave = () => {
    setHoveredThumbnail(null);
  };
  const getCourseVideos = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getCourseModulesVideos,
        params: {
          module_id: data?.id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res?.data?.statusCode === 200) {
        setVideoList(res?.data?.result?.moduleVideoList[0]?.videoDetails);
        setModuleId(
          res?.data?.result?.moduleVideoList[0]?.videoDetails[0]?.module_id
        );
        setCourseId(
          res?.data?.result?.moduleVideoList[0]?.videoDetails[0]?.course_id
        );
        setVideoDescription(
          res?.data?.result?.moduleVideoList[0]?.videoDetails[0]
            ?.video_description
        );
        setVideoUrl(
          res?.data?.result?.moduleVideoList[0]?.videoDetails[0]?.video_url
        );
        setVideoId(res?.data?.result?.moduleVideoList[0]?.videoDetails[0]?.id);
        setCompletedVideo(
          res?.data?.result?.moduleVideoList[0]?.modulePercentage
        );
        setVideoTitle(
          res?.data?.result?.moduleVideoList[0]?.videoDetails[0]?.videoName
        );
        setQuizSubmittedFlag(res?.data?.result?.moduleVideoList[0]?.quizStatus);
        setFileUrl(
          res?.data?.result?.moduleVideoList[0]?.videoDetails[0]?.file_url
        );
      }
    } catch (error) {
      console.log(error, "zkjbcads");
    }
  };

  const createEnrolledCourseHistoryApiFn = async (status) => {
    try {
      setIsLoading(true);
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.createEnrolledCourseHistory,
        data: {
          course_id: courseId,
          module_id: state?.data?.id,
          video_id: videoId,
          status: status,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      getCourseVideos();
      // toast.success(res?.data?.result || "Course enrolled Successfully.");
      // navigate("/learning&lives");
    } catch (error) {
      console.log(error, "asjahsazxcdf");
      setIsLoading(false);
    }
  };
  const updataEnrolledCourseApi = async (data) => {
    try {
      setIsLoading(true);
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.updateEnrolledCourse,
        data: {
          course_id: courseId,
          completed_videos: completedVideo + 1,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      createEnrolledCourseHistoryApiFn("COMPLETED");
      getCourseVideos();
      // toast.success(res?.data?.result || "Course enrolled Successfully.");
      // navigate("/learning&lives");
    } catch (error) {
      console.log(error, "asjahsazxcdf");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!data) {
      navigate("/learning&lives");
    }
    getCourseVideos();
    setModuleName(data?.moduleName);
    console.log(videoList?.length);
  }, [data]);

  const videoRef = useRef(null);

  const getCurrentTime = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      console.log("CurrentTime=>>", currentTime);
    }
  };
  const [halfwayFlag, setHalfwayFlag] = useState(false);
  console.log("halfwayFlag==>", totalPercentage);
  const checkHalfway = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      if (currentTime >= duration / 2 && !halfwayFlag) {
        setHalfwayFlag(true);
        if (!apiCalled) {
          setApiCalled(true);
          updataEnrolledCourseApi();
        }
      }
    }
  };

  useEffect(() => {
    videoRef.current?.addEventListener("timeupdate", checkHalfway);

    return () => {
      videoRef.current?.removeEventListener("timeupdate", checkHalfway);
    };
  }, []);

  const rewind = () => {
    if (videoRef.current) {
      let currentTime = videoRef.current.currentTime;
      if (isNaN(currentTime) || !isFinite(currentTime)) {
        console.error("Failed to retrieve currentTime for rewind");
        return; // Exit the function if currentTime is invalid
      }
      let newTime = Math.max(0, currentTime - 10);
      videoRef.current.currentTime = newTime;
      console.log(`Rewind: from ${currentTime} to ${newTime}`);
    }
  };

  const fastForward = () => {
    if (videoRef.current) {
      let currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      if (
        typeof currentTime !== "number" ||
        isNaN(currentTime) ||
        typeof duration !== "number" ||
        isNaN(duration)
      ) {
        console.error("Invalid currentTime or duration", currentTime, duration);
        return; // Exit the function if values are invalid
      }
      let newTime = Math.min(currentTime + 10, duration);
      videoRef.current.currentTime = newTime;
      console.log(`FastForward: from ${currentTime} to ${newTime}`);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { key } = event;
      console.log(`${key} pressed`);
      if (videoRef.current) {
        switch (key) {
          case "ArrowLeft":
            rewind();
            break;
          case "ArrowRight":
            fastForward();
            break;
          default:
            break;
        }
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      console.log("Event listener removed for keydown");
    };
  }, []);

  return (
    <CousreVideosSection>
      {videoList && videoList?.length === 0 ? (
        <Box className="displayCenter">
          <Typography
            variant="subtitle1"
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          >
            No Module data found!
          </Typography>
        </Box>
      ) : (
        <Container maxWidth={"lg"}>
          {" "}
          <Box
            className="mainCousreVideosBox"
            style={{
              backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
              color: currentTheme == "DARK" ? "#fff" : "#000",
            }}
          >
            <Box mt={4}>
              {" "}
              <QuizPercentage
                type="module"
                percentage={Math.floor(completedVideo)}
              />
              <Typography
                variant="h4"
                fontSize="45px"
                fontWeight={"600"}
                mt={4}
              >
                Learning & Lives
              </Typography>
              <Typography fontSize="25px" variant="h6" mt={2}>
                Join & Grow
              </Typography>
              <Box display="flex" width="100%" justifyContent="space-between">
                {" "}
                <Typography fontSize="25px" variant="h6" mt={2}>
                  {data?.moduleName}
                </Typography>
                {quizSubmittedFlag == true ? (
                  <Button
                    sx={{
                      backgroundColor: "#D75008",
                      width: "230px",
                      fontSize: "18px",
                      textTransform: "none",
                      padding: "0px",
                    }}
                    variant="contained"
                    onClick={() =>
                      navigate("/learning-detail/module/quizResponse", {
                        state: { data: data?.id },
                      })
                    }
                  >
                    View Quiz Response
                  </Button>
                ) : (
                  ""
                )}
              </Box>
              <hr style={{ marginTop: "20px" }} />
              <Grid container spacing={2} xs={12}>
                <Grid item xs={12} lg={6} flexDirection="column" spacing={2}>
                  {/* <Typography
                  className="moduleTutorial"
                  mt={6}
                  variant="h4"
                  fontWeight={700}
                  // fontSize="45px"
                >
                  {data?.moduleName}-Modules-Tutorials
                </Typography> */}
                  <Box
                    p={3}
                    mt={8}
                    sx={{
                      backgroundColor: "#FBF8FC",
                      borderRadius: "20px",
                      border: "1px solid orange",
                    }}
                  >
                    {videoUrl && videoUrl.includes(".pdf") ? (
                      // <a
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {" "}
                        <Tooltip title="Download Pdf">
                          {" "}
                          <a
                            href={videoUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src="/images/pdf.jpg" alt="PDF Thumbnail" />
                          </a>
                        </Tooltip>
                      </Box>
                    ) : (
                      <video
                        style={{ marginTop: "16px", borderRadius: "20px" }}
                        width="100%"
                        src={videoUrl}
                        controls
                        ref={videoRef}
                        onTimeUpdate={checkHalfway}
                      />
                    )}

                    <Typography
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      variant="h6"
                      sx={{ cursor: "pointer" }}
                    >
                      {videoTitle ? videoTitle : "Description"}{" "}
                      {fileUrl && (
                        <a
                          style={{ marginLeft: "4px" }}
                          href={fileUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                              textDecoration: "none",
                              color: "black",
                            }}
                          >
                            ( View Pdf{" "}
                            <img
                              height="20px"
                              src="/images/pdf.jpg"
                              alt="PDF Thumbnail"
                            />
                            )
                          </span>
                        </a>
                      )}
                    </Typography>
                    <Typography
                      mt={2}
                      variant="body1"
                      fontSize="14px"
                      color="#202123BF"
                    >
                      {videoDescription}
                    </Typography>
                  </Box>
                </Grid>
                {/* Vertical Divider */}
                <Grid
                  display={{ lg: "flex", xs: "none" }}
                  justifyContent="center"
                  item
                  lg={0.5}
                >
                  <Box
                    sx={{
                      width: "3px",
                      height: "800px",
                      backgroundColor: "#DF5E05",
                    }}
                  ></Box>
                </Grid>
                {/* Right Section */}

                <Grid
                  item
                  xs={12}
                  lg={5.5}
                  mt={4}
                  height="770px"
                  sx={{
                    overflowY: "scroll",
                    "&::-webkit-scrollbar": {
                      width: "0",
                    },
                  }}
                  spacing={2}
                >
                  <Typography
                    className="moduleTutorial"
                    fontWeight={700}
                    // fontSize="45px"
                  >
                    {data?.moduleName} -Module-Tutorials
                  </Typography>
                  {videoList?.map((video, idx) => (
                    <Box
                      width="100%"
                      height="174px"
                      mt={2}
                      p={2}
                      className={"videoListBox"}
                      key={idx}
                    >
                      <Box
                        className="thumbnailContainer"
                        onMouseEnter={() => handleMouseEnter(idx)}
                        onMouseLeave={handleMouseLeave}
                        style={{
                          position: "relative",
                          overflow: "hidden",
                          borderRadius: "20px",
                        }}
                      >
                        <img
                          width="100%"
                          height="140px"
                          alt="thumbnailImg"
                          src={video?.thumbnail}
                          style={{ borderRadius: "20px" }}
                        />
                        {hoveredThumbnail === idx && (
                          <div className="play-icon">
                            <FaPlay
                              size={40}
                              color="rgba(248, 248, 248, 1)"
                              style={{ zIndex: 99 }}
                            />
                          </div>
                        )}
                        {hoveredThumbnail === idx && (
                          <div className="overlay" />
                        )}
                      </Box>
                      <Box>
                        <Typography
                          className="videoNameText"
                          variant="h4"
                          style={{
                            fontWeight: 500,
                            // fontSize: "25px",
                            color: "#000000",
                          }}
                        >
                          {video?.videoName
                            ? video?.videoName?.length > 28
                              ? video?.videoName?.slice(0, 28) + "..."
                              : video?.videoName
                            : "NA"}
                        </Typography>
                        <Typography
                          className="videoNameText"
                          variant="h4"
                          mt={1}
                          mb={2}
                          style={{
                            fontWeight: 300,
                            fontSize: "20px",
                            color: "#000",
                          }}
                        >
                          {video?.video_description
                            ? video?.video_description.length > 28
                              ? video?.video_description.slice(0, 28) + "..."
                              : video?.video_description
                            : "NA"}
                        </Typography>
                        <Button
                          sx={{
                            backgroundColor: "#D75008",
                            width: "100px",
                            fontSize: "18px",
                            textTransform: "none",
                            padding: "0px",
                          }}
                          variant="contained"
                          onClick={() => {
                            setVideoDescription(video?.video_description);
                            setVideoUrl(video?.video_url);
                            setVideoTitle(video?.videoName);
                            setVideoId(video?.id);
                            setFileUrl(video?.file_url);
                          }}
                        >
                          View
                        </Button>
                      </Box>
                    </Box>
                  ))}
                </Grid>
              </Grid>
            </Box>

            <Box
              px={0}
              mt={10}
              height="60px"
              display={quizSubmittedFlag ? "none" : "flex"}
              justifyContent="center"
            >
              {data?.isQuizCreatedByAdmin && totalPercentage > 49 && (
                <Button
                  sx={{
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    fontSize: "22px",
                    textTransform: "none",
                    height: "50px",
                  }}
                  variant="contained"
                  onClick={() => {
                    navigate("/learning-detail/module/videos/quiz", {
                      state: {
                        moduleName,
                        moduleId,
                        courseId,
                        completedVideo,
                      },
                    });
                  }}
                  // disabled={quizSubmittedFlag}
                >
                  {data?.isQuizCreatedByAdmin
                    ? "Complete Quiz"
                    : "Quiz Already Submitted"}
                </Button>
              )}
            </Box>
            <Box
              px={0}
              mt={10}
              height="60px"
              display={quizSubmittedFlag ? "flex" : "none"}
              justifyContent="center"
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  fontSize: "22px",
                  textTransform: "none",
                  height: "50px",
                }}
                variant="contained"
                onClick={() => {
                  navigate("/learning-detail/module/videos/quiz", {
                    state: {
                      moduleName,
                      moduleId,
                      courseId,
                      totalPercentage,
                    },
                  });
                }}
                disabled={true}
              >
                Quiz Already Submitted
              </Button>
            </Box>
          </Box>
        </Container>
      )}
    </CousreVideosSection>
  );
};

export default CousreVideos;
