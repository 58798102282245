import React, { useEffect, useRef, useState } from "react";
import {
  AccountCircleOutlined,
  ArrowBack,
  AttachFile,
  KeyboardVoice,
  PhotoCamera,
  Search,
  Send,
  SentimentSatisfiedAlt,
} from "@mui/icons-material";
import { IconButton, InputAdornment, List, TextField } from "@mui/material";
import "./guestmasterMindCommunity.scss";
import { useNavigate, useParams } from "react-router";
// import { EmojiPicker } from "./EmojiPicker";
import EmojiPicker from "emoji-picker-react";
import { ApiConfig, socketUrl } from "../../services/ApiConfig";
import { socketEvent } from "./guestSocketConfig/guestSocket";
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import customAxios from "../../utils/customAxios";
import { Container } from "@mui/system";
import { useSelector } from "react-redux";
export default function GuestMasterMindCommunity() {
  const [currentChat, setCurrentChat] = useState(0);
  const [currentTopic, setCurrentTopic] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [topicList, setTopicList] = useState([]);
  const [messageList, setMessageList] = useState([]);
  console.log("messageList-=-=--", messageList);
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isConnected, setIsConnected] = useState(false);
  const [myUniqueId, setmyUniqueId] = useState(localStorage.getItem("uid"));
  const chatScreen = useRef();
  const [topicFilter, setTopicFilter] = useState("");
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  let socket = io(socketUrl, {
    secure: true,
    reconnect: true,
  });
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    getInitialTopics();
    if (!localStorage.getItem("uid")) {
      localStorage.setItem("uid", uuidv4());
      setmyUniqueId(localStorage.getItem("uid"));
    }
    socket.current = io(socketUrl, {
      secure: true,
      reconnect: true,
    });

    return () => {
      if (socket.current) {
        socket.current.disconnect();
      }
    };
  }, []);

  const getInitialTopics = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllTopic,
        params: {
          cohort_id: id,
          status: "GUEST",
        },
      });
      console.log("getAllTopic-=-=-", res);
      if (!res?.data) {
        return;
      }
      setTopicList(res?.data?.result?.topics || []);
      if (res?.data?.result?.topics?.length > 0) {
        setCurrentTopic(res?.data?.result?.topics[0]);
      }
    } catch (err) {
      console.log(err, "hfsf");
    }
  };

  const handleInitialSocketConnect = () => {
    const roomData = {
      userId: myUniqueId,
      RoomId: currentTopic?.id,
    };
    socket.emit(socketEvent.initiateChat, roomData);
    handleChatData();
  };

  useEffect(() => {
    if (isConnected) {
      handleInitialSocketConnect();
    }
  }, [currentTopic]);

  const handleChatData = () => {
    socket.on("chatInitiated", (data) => {
      // console.log();
      setMessageList([]);
      setMessageList(data?.messages);
      setRoomId(data?.chatRoomId);
    });
  };

  const receiveMessageHandler = () => {
    console.log("recieve");
    socket.on("receiveMessage", (data) => {
      setMessageList((p) => [...p, data]);
      // chatScreen
      // window.scrollTo(0, chatScreen.current.body.scrollHeight);
    });
  };
  useEffect(() => {
    if (isConnected) {
      receiveMessageHandler();
    }
  }, [isConnected]);

  useEffect(() => {
    socket.on("connect", () => setIsConnected(true));
    return () => {
      socket.off("connect");
      socket.off(socketEvent.initiateChat);
    };
  }, []);

  const sendMessageHandler = async () => {
    try {
      socket.emit(socketEvent.sendMessage, {
        from: myUniqueId,
        RoomId: roomId,
        message: message?.trim(),
      });
      setMessage("");
      // receiveMessageHandler();
    } catch (error) {
      console.log(error, "ahsgfaf");
    }
  };

  return (
    <div
      className="guestmasterMindCommunity"
      style={{ background: currentTheme == "DARK" ? "#151515" : "#f8f8f8" }}
    >
      <div
        className="header"
        style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
      >
        <IconButton
          onClick={() => {
            navigate(-1);
          }}
        >
          <ArrowBack
            style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          />
        </IconButton>
        <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
          Master Mind Community
        </h2>
      </div>
      <Container maxWidth="xl">
        <div className="chatContainer">
          <div
            className="chatList"
            style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
          >
            <div className="header">
              <h3 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                Topics
              </h3>
              <TextField
                value={topicFilter}
                onChange={(e) => {
                  setTopicFilter(e?.target?.value);
                }}
                id="outlined-basic"
                variant="outlined"
                fullWidth
                style={{
                  backgroundColor: "#FCFCFC",
                  padding: "0",
                  background: currentTheme == "DARK" ? "#151515" : "#FCFCFC",
                }}
                placeholder="Search..."
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <IconButton>
                        <Search
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: {
                    padding: "0 5px",
                    color: currentTheme == "DARK" ? "#fff" : "#000",
                  },
                }}
              />
            </div>
            <div
              className="chats"
              style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
            >
              {topicList &&
                topicList?.length > 0 &&
                topicList
                  ?.filter((e) => e?.topicName?.includes(topicFilter))
                  ?.map((topic, i) => {
                    return (
                      <div
                        key={i}
                        style={{
                          background: currentChat !== i && "#fff",
                          color: currentChat === i && "#fff",
                        }}
                        onClick={() => {
                          setCurrentChat(i);
                          setCurrentTopic(topic);
                        }}
                      >
                        <span
                          style={{ background: currentChat === i && "#fff" }}
                        >
                          {topic?.topicName[0]}
                        </span>
                        <p>{topic?.topicName}</p>
                      </div>
                    );
                  })}
            </div>
          </div>
          <div className="chatScreen">
            <div
              className="header"
              style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
            >
              <span></span>
              <p style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
                Community Chat
              </p>
            </div>
            <div className="chatDetails" ref={chatScreen}>
              {messageList?.map((data, i) => {
                const isSentByUser = data.from === myUniqueId;
                return (
                  <div
                    key={i}
                    className={`chatBubble ${
                      isSentByUser ? "chatBubbleSelf" : "chatBubbleOther"
                    }`}
                  >
                    {data?.message}
                    <span className="bubbleChatDate">
                      {data?.createdAt
                        ? dayjs(data?.createdAt)?.format("lll")
                        : "NA"}
                    </span>
                  </div>
                );
              })}
            </div>
            <div
              className="chatKeyboard"
              style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
            >
              {/* <AttachFile
                style={{
                  color: "#909090",
                  margin: "0 25px 0 25px",
                  rotate: "30deg",
                  color: currentTheme == "DARK" ? "#fff" : "#909090"
                }}
              /> */}
              {/* <PhotoCamera style={{ color: "#909090", margin: "0 23px 0 0", color: currentTheme == "DARK" ? "#fff" : "#909090" }} /> */}
              <div
                className="keypad"
                style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
              >
                <input
                  placeholder="Type a message..."
                  value={message}
                  onChange={(e) => {
                    setMessage(e?.target?.value);
                  }}
                />
                <KeyboardVoice
                  style={{
                    color: "#909090",
                    margin: "0 25px 0 0",
                    color: currentTheme == "DARK" ? "#fff" : "#909090",
                  }}
                />
                <SentimentSatisfiedAlt
                  style={{
                    color: "#909090",
                    margin: "0 29px 0 0",
                    cursor: "pointer",
                    color: currentTheme == "DARK" ? "#fff" : "#909090",
                  }}
                  onClick={() => setShowEmojiPicker(true)}
                />
                <div>
                  <IconButton
                    onClick={() => {
                      if (message) {
                        sendMessageHandler();
                      }
                    }}
                  >
                    <Send />
                  </IconButton>
                </div>
              </div>
              {showEmojiPicker && (
                <EmojiPicker
                  onEmojiClick={(event, emojiObject) => {
                    setMessage(message + emojiObject.emoji);
                  }}
                  disableSearchBar
                  disableSkinTonePicker
                  groupVisibility={{
                    recently_used: false,
                  }}
                />
              )}
            </div>
          </div>
          <div
            className="stasutScreen"
            style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
          >
            <div
              className="header"
              style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
            >
              <div
                className="inner"
                style={{ background: currentTheme == "DARK" ? "#000" : "#fff" }}
              >
                <AccountCircleOutlined
                  style={{
                    fontSize: "3em",
                    color: currentTheme == "DARK" ? "#fff" : "#000",
                  }}
                />
                <div>
                  <h3
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    Umair Siddiqui
                  </h3>
                  <p
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    Business Advisor
                  </p>
                </div>
              </div>
              <button
                style={{
                  background: currentTheme == "DARK" ? "#151515" : "#fff",
                }}
              >
                <img src="/images/zoom.png" alt="" />
                <span
                  style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                >
                  Zoom with Advisor
                </span>
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
