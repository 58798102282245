import React, { useEffect, useState } from "react";
// import Assessments from "../../../components/assessments/Assessments";
import { useLocation } from "react-router-dom";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import MonitorAssessment from "./MonitorAssessment";

export default function MonitorAssessmentPage() {
  const [activeStep, setActiveStep] = useState(1);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [assessmentData, setAssessmentData] = useState([]);

  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1);
    }
  };

  const getAssessmentData = async (path) => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllQuestionsList,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        // data: {
        //   assignment_id: path[2],
        // },
      });
      if (res?.data?.statusCode === 200) {
        setAssessmentData(res?.data?.result);
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInitialAnswers = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getUserAnswers,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        // data: {
        //   user_id: sessionStorage.getItem("userId"),
        //   assesment_id: path[2],
        //   version_control: parseInt(sessionStorage.getItem("version")),
        // },
      });
      if (res.data.statusCode === 200) {
        console.log(res);
        // getAssessmentData(path);
        // if (res.data?.result !== "Assessment not found.") {
        //   setInitialAnswers(res.data?.result);
        // }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssessmentData();
    handleInitialAnswers();
  }, []);

  return (
    <>
      {assessmentData?.length > 0 && (
        <MonitorAssessment
          //   pageTitle={assessmentData[0]?.assesment_name}
          renderData={assessmentData}
          activeStep={activeStep}
          handlePreviousStep={handlePreviousStep}
          handleNextStep={handleNextStep}
          initialAnswers={initialAnswers}
          handleInitialAnswers={handleInitialAnswers}
        />
      )}
    </>
  );
}
