import {
  Button,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Box, height } from "@mui/system";
import { Form, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
// import { getApiHandlerWithToken } from "../../../config/service";
import { async } from "regenerator-runtime";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import CircularProgressBox from "../../../component/CircularProgressBox";
import CancelIcon from "@mui/icons-material/Cancel";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  borderRadius: "14px",
  minHeight: "500px",
  "@media(max-width:600px)": {
    minWidth: "96vw",
  },
};

const validationSchema = Yup.object({
  category: Yup.string().trim().required("Category is required."),
  descritpion: Yup.string().trim().required("Description is required."),
  businessOwner: Yup.string().trim().required("Business owner is required."),
  assign: Yup.string().trim().required("Assignee name is required."),
  date: Yup.date("Enter valid date.").required("Due date is required."),
});

const SchedulePopUp = ({ setOpen, open, date, setDate }) => {
  const [ownerList, setOwnerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentOwnerId, setCurrentOwnerId] = useState("");
  useEffect(() => {
    getOwnerList();
  }, []);

  const getOwnerList = async () => {
    //
    // setIsLoading(true);
    // const res = await getApiHandlerWithToken("allBusinessOwnerListing");
    // setIsLoading(false);
    // setOwnerList(
    //   res?.result?.ownerListing?.filter(
    //     (e) => e?.business_adviser_id == localStorage.getItem("adviser_id")
    //   )
    // );
  };

  return (
    <Fragment>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={{ ...style }}>
          {isLoading ? (
            <></>
          ) : (
            <>
              <IconButton
                sx={{
                  position: "absolute",
                  right: "0px",
                  top: "0px",
                  color: "#1f1f1f",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <CancelIcon />
              </IconButton>
              <Grid container item xs={12}>
                <Typography variant="h6">Create Action</Typography>
              </Grid>
              <Divider sx={{ width: "100%" }} />
              <Grid container item xs={12} mt={4}>
                <Formik
                  initialValues={{
                    category: "",
                    descritpion: "",
                    businessOwner: "",
                    assign: "",
                    date: "",
                  }}
                  onSubmit={() => {}}
                  validationSchema={validationSchema}
                >
                  {({ errors, touched, handleChange, handleBlur, values }) => (
                    <Form style={{ width: "100%" }}>
                      <Box>
                        <label> Category</label>
                        <TextField
                          name="category"
                          placeholder="Enter category"
                          sx={{ mt: 1 }}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.category && Boolean(errors.category)}
                          helperText={touched.category && errors.category}
                        />
                      </Box>
                      <Box mt={2}>
                        <label> Short Description</label>
                        <TextField
                          name="descritpion"
                          placeholder="Enter description"
                          sx={{ mt: 1 }}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={
                            touched.descritpion && Boolean(errors.descritpion)
                          }
                          helperText={touched.descritpion && errors.descritpion}
                        />
                      </Box>
                      <Box mt={2}>
                        <label>Select Business</label>
                        <Select
                          name="businessOwner"
                          value={values.businessOwner}
                          onChange={(e) => {
                            setCurrentOwnerId(e?.target?.value);
                            handleChange(e);
                          }}
                          onBlur={handleBlur}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          sx={{
                            mt: 1,
                            "& .MuiSelect-select": {
                              padding: "10.5px 14px",
                            },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          error={
                            touched.businessOwner &&
                            Boolean(errors.businessOwner)
                          }
                          helperText={
                            touched.businessOwner && errors.businessOwner
                          }
                        >
                          {ownerList.map((data, i) => {
                            return (
                              <MenuItem key={i} value={data?.full_name}>
                                {data?.full_name}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Box>
                      <Box mt={2}>
                        <label>Assign To</label>
                        <TextField
                          name="assign"
                          placeholder="Enter name"
                          sx={{ mt: 1 }}
                          fullWidth
                          inputProps={{
                            style: { height: "10px" },
                          }}
                          FormHelperTextProps={{
                            style: {
                              marginLeft: "0px",
                            },
                          }}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={touched.assign && Boolean(errors.assign)}
                          helperText={touched.assign && errors.assign}
                        />
                      </Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Box
                          sx={{
                            width: "100%",
                            mt: 2,
                          }}
                          components={["DatePicker"]}
                        >
                          <label>Assign To</label>
                          <DatePicker
                            value={values.date ? values.date : date}
                            onChange={(e) => {
                              setDate(e);
                              handleChange(e);
                            }}
                            disablePast={true}
                            sx={{
                              mt: 1,
                              width: "100%",

                              "& .MuiOutlinedInput-root": {
                                borderRadius: "4px",
                              },

                              "& .MuiInputLabel-root": {
                                top: "-5px",
                              },
                              input: {
                                padding: "10px 10px",
                              },
                            }}
                            FormHelperTextProps={{
                              style: {
                                marginLeft: "0px",
                              },
                            }}
                            error={touched.assign && Boolean(errors.assign)}
                            helperText={touched.assign && errors.assign}
                          />
                        </Box>
                      </LocalizationProvider>
                      <Grid
                        container
                        item
                        xs={12}
                        justifyContent={"center"}
                        gap={2}
                        mt={6}
                        mb={2}
                      >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "gray" }}
                          sx={{
                            width: "180px",
                            py: 1.2,
                            textTransform: "none",
                          }}
                        >
                          Schedule Call
                        </Button>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: "#cc3d0b" }}
                          type="submit"
                          sx={{
                            width: "180px",
                            py: 1.2,
                            textTransform: "none",
                          }}
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </>
          )}
        </Box>
      </Modal>
    </Fragment>
  );
};

export default SchedulePopUp;
