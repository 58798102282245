import { Box, Button, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  BarElement,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
// import faker from "faker";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(() => faker.datatype.number({ max: 1000 })),
      borderColor: "#E3661F",
      backgroundColor: "#eb934c",
    },
    {
      label: "Dataset 2",
      data: labels.map(() => faker.datatype.number({ max: 1000 })),
      borderColor: "#523168",
      backgroundColor: "#925bb6",
    },
    {
      label: "Dataset 3",
      data: labels.map(() => faker.datatype.number({ max: 1000 })),
      borderColor: "#1BAFA6",
      backgroundColor: "#8bf3e5",
    },
  ],
};

function MonitorrMeasureStatistics() {
  const navigate = useNavigate();
  return (
    <Container maxWidth={"lg"}>
      <Grid sx={{ mt: 15 }} container>
        <Grid item md={6}>
          {" "}
          <Typography variant="h4" fontWeight={600}>
            Customer Satisfaction Analytics
          </Typography>
          <Typography variant="h6" fontWeight={300}>
            Strategic Insights: Business Information & Baseline Assessment
            Overview
          </Typography>
          <Button
            variant="outlined"
            sx={{
              color: "purple",
              marginBottom: "40px",
              fontSize: "18px",
              marginTop: "20px",
              //   width: "200px",
              textTransform: "none",
              borderRadius: "8px",
              border: "1px solid purple",
            }}
            onClick={() => {
              //   navigate("/my-cohort");
              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            Grow
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "purple",
              mx: 2,
              marginBottom: "40px",
              fontSize: "18px",
              marginTop: "20px",
              //   width: "200px",
              textTransform: "none",
              borderRadius: "8px",
              border: "1px solid purple",
            }}
            onClick={() => {
              //   navigate("/my-cohort");
              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            Scale
          </Button>
          <Button
            variant="outlined"
            sx={{
              color: "purple",
              marginBottom: "40px",
              fontSize: "18px",
              marginTop: "20px",
              //   width: "200px",
              textTransform: "none",
              borderRadius: "8px",
              border: "1px solid purple",
            }}
            onClick={() => {
              //   navigate("/my-cohort");
              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            Infrastructure
          </Button>
        </Grid>
        <Grid item md={2}></Grid>
        <Grid
          display="flex"
          alignItems={"center"}
          justifyContent={"end"}
          item
          md={4}
        >
          {" "}
          <Button
            variant="outlined"
            sx={{
              color: "purple",
              mx: 2,
              marginBottom: "40px",
              fontSize: "18px",
              marginTop: "20px",
              //   width: "200px",
              textTransform: "none",
              borderRadius: "8px",
              border: "1px solid purple",
            }}
            onClick={() => {
              //   navigate("/my-cohort");
              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            Update
          </Button>
          <Button
            sx={{
              background:
                "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
              //   marginX: "auto",
              marginBottom: "40px",
              fontSize: "18px",
              marginTop: "20px",
              //   width: "200px",
              textTransform: "none",
              borderRadius: "8px",
            }}
            variant="contained"
            onClick={() => {
              navigate("/my-cohort");
              // toast.success("Password reset successfully", {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
            }}
          >
            View Versions
          </Button>
        </Grid>
      </Grid>
      <Container maxWidth={"xl"}>
        {" "}
        <Box>
          <Line options={options} data={data} />
        </Box>
        <Box>
          <Line options={options} data={data} />
        </Box>
      </Container>

      <Grid container>
        <Grid item md={6}>
          <Bar options={options} data={data} />
        </Grid>
        <Grid item md={6}>
          <Bar options={options} data={data} />
        </Grid>
      </Grid>
    </Container>
  );
}

export default MonitorrMeasureStatistics;
