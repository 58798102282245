import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Pagination,
  useMediaQuery,
  Typography,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router";
import React, { useEffect, useState } from "react";

import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import { Box, textAlign } from "@mui/system";
import dayjs from "dayjs";
import { ApiConfig } from "../../services/ApiConfig";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import { Assignment } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";

import {
  setAction,
  setSchedule,
} from "../../redux/features/actionScheduleRefresherSlice";
import { LoadingButton } from "@mui/lab";
import ActionCallModalPopup from "../ActionCallModalPopup";
import SchedulePopUp from "./SchedulePopUp";
import UpdateStatusModal from "./UpdateStatusModal";

const AssignedAction = ({
  updateAssignedActionList,
  setUpdateAssignedActionList,
}) => {
  const [isActionListLoading, setIsActionListLoading] = useState(false);
  const [actionList, setActionList] = useState([]);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const matches = useMediaQuery("(min-width:800px)");
  const [acceptLoadingStatus, setAcceptLoadingStatus] = useState(false);
  const [editLoadingStatus, setEditLoadingStatus] = useState(false);
  const [declineLoadingStatus, setDeclineLoadingStatus] = useState(false);
  const [cancelLoadingStatus, setCancelLoadingStatus] = useState(false);
  const [rejectLoadingStatus, setRejectLoadingStatus] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentActionId, setCurrentActionId] = useState("");
  const [message, setMessage] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [cancelledActions, setCancelledActions] = useState([]);
  const [actionModal, setActionModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState("");

  const [showUpdateButton, setShowUpdateButton] = useState(false);
  const [taskStatus, setTaskStatus] = useState("");

  const actionScheduleRefresher = useSelector(
    (state) => state.actionScheduleRefresher
  );
  let amIHost;
  const dispatch = useDispatch();
  const [filter, setFilter] = useState({
    page: 1,
    status: "All",
    limit: 10,
  });
  useEffect(() => {
    getActionList();
  }, [updateAssignedActionList, filter, actionScheduleRefresher?.action]);

  const getActionList = async () => {
    setIsActionListLoading(true);
    const statusToSend = filter.status == "All" ? null : filter.status;
    const options = {
      method: "GET",
      url: ApiConfig.findAction,
      params: { ...filter, status: statusToSend },
    };
    const actionList = await apiWithToken(options);
    if (actionList?.data?.statusCode === 200) {
      setActionList(actionList?.data?.result?.actions);
      setTotalPage(actionList?.data?.result?.totalPages);
      setIsActionListLoading(false);
    }
    setIsActionListLoading(false);
  };
  // const statusUpdateHandler = async (id, status) => {
  //   // setIsActionListLoading(true);
  //   setCurrentId(id);
  //   if (status == "ACCEPTED") {
  //     setAcceptLoadingStatus(true);
  //   } else {
  //     setRejectLoadingStatus(true);
  //   }
  //   const options = {
  //     method: "PUT",
  //     url: ApiConfig.updateAction,
  //     data: {
  //       action_id: id,
  //       status: status,
  //     },
  //   };
  //   const res = await apiWithToken(options);
  //   if (status == "ACCEPTED") {
  //     setAcceptLoadingStatus(false);
  //   } else if (status == "CANCELLED") {
  //     const updatedCancelledActions = [...cancelledActions, id];
  //     setCancelledActions(updatedCancelledActions);
  //     localStorage.setItem(
  //       "cancelledActions",
  //       JSON.stringify(updatedCancelledActions)
  //     );
  //   } else {
  //     setRejectLoadingStatus(false);
  //   }
  //   if (res?.data?.statusCode === 200) {
  //     getActionList();
  //     dispatch(setAction());
  //   }
  // };
  const statusUpdateHandler = async (id, status, taskStatus) => {
    // setIsActionListLoading(true);
    setCurrentId(id);
    if (status === "ACCEPTED") {
      setAcceptLoadingStatus(true);
    } else {
      setRejectLoadingStatus(true);
    }
    let payload = { action_id: id };
    if (taskStatus) {
      payload.task_status = taskStatus;
    } else {
      payload.status = status;
    }
    const options = {
      method: "PUT",
      url: ApiConfig.updateAction,
      data: payload, // Use the modified payload here
    };
    const res = await apiWithToken(options);
    if (status === "ACCEPTED") {
      setAcceptLoadingStatus(false);
    } else if (status === "CANCELLED") {
      const updatedCancelledActions = [...cancelledActions, id];
      setCancelledActions(updatedCancelledActions);
      localStorage.setItem(
        "cancelledActions",
        JSON.stringify(updatedCancelledActions)
      );
    } else {
      setRejectLoadingStatus(false);
    }
    if (res?.data?.statusCode === 200) {
      getActionList();
      dispatch(setAction());
    }
  };

  useEffect(() => {
    const cancelledActionsFromStorage =
      JSON.parse(localStorage.getItem("cancelledActions")) || [];
    setCancelledActions(cancelledActionsFromStorage);
  }, []);
  // const handleUpdateButtonClick = () => {
  //   setCurrentId(action?.id);
  //   setIsUpdateModalOpen(true);
  //   setShowUpdateButton(false);
  // };
  return (
    <>
      <Grid
        contaner
        item
        xs={12}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Grid
          container
          item
          xs={10}
          justifyContent={"flex-end"}
          alignItems={"flex-end"}
        >
          <Box
            sx={{
              width: "300px",
              display: "flex",
              // bgcolor: "orange",
              justifyContent: "flex-end",
              mr: 6,
              gap: 2,
            }}
          >
            <Select
              value={filter.status}
              name="status"
              onChange={(e) => {
                const newFilter = {
                  ...filter,
                  status: e?.target?.value,
                };
                setFilter(newFilter);
              }}
              fullWidth
              inputProps={{
                style: { height: "10px" },
              }}
              sx={{
                mt: 1,
                "& .MuiSelect-select": {
                  padding: "10.5px 14px",
                  border: "none",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "none",
                },
                "& .MuiSelect-icon": {
                  color: "#fff",
                },
                width: "50%",
                backgroundColor: "#c9380d",
                color: "white",
                borderRadius: "8px",
                border: "none",
              }}
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"ACCEPTED"}>ACCEPTED</MenuItem>
              <MenuItem value={"REJECTED"}>REJECTED</MenuItem>
              <MenuItem value={"PENDING"}>PENDING</MenuItem>
              <MenuItem value={"CANCELLED"}>CANCELLED</MenuItem>
            </Select>
          </Box>
        </Grid>
      </Grid>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "0rem 0",
          padding: "0 10%",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "800px",
            overflowY: "auto",
            borderRadius: "10px",
          }}
        >
          {actionList.length > 0 ? (
            actionList?.map((action, i) => {
              amIHost = sessionStorage.getItem("userId") == action?.assigned_to;
              return (
                <div
                  key={i}
                  style={{
                    background:
                      currentTheme === "DARK"
                        ? "#000"
                        : i % 2 === 0
                        ? "#FFDEDE63"
                        : "#FFF2DE63",
                    borderRadius: "10px",
                    marginTop: "1rem",
                    display: "flex",
                    alignItems: "center",
                    padding: "20px",
                    boxShadow:
                      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
                  }}
                >
                  {matches && (
                    <img
                      src="/images/listicon.png"
                      alt=""
                      style={{
                        width: "114px",
                        height: "114px",
                        margin: "auto 41px auto auto",
                      }}
                    />
                  )}

                  <ListItemText
                    // primary={action?.category}
                    // primary={
                    //   action.actionTypeMaster.action_type
                    //     ? action.actionTypeMaster.action_type
                    //     : "NA"
                    // }
                    // primaryTypographyProps={{
                    //   sx: { color: "#202123" },
                    // }}

                    primary={
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          flexWrap: "wrap",
                        }}
                      >
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#202123",
                            fontSize: "18px",
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          {/* {action?.category ? action?.category : "NA"} */}
                          {action.actionTypeMaster.action_type
                            ? action.actionTypeMaster.action_type
                            : "NA"}
                        </Typography>
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#202123",
                              fontSize: "0.875rem",
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Assigned Date -{" "}
                            {action?.createdAt
                              ? dayjs(action?.createdAt).format("lll")
                              : "NA"}
                          </Typography>

                          {action?.task_status === "COMPLETED" ||
                          action?.task_status === "CONFIRMED" ||
                          action?.task_status === "REOPENED" ||
                          action?.task_status === "IN-PROGRESS" ? (
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#202123",
                                fontSize: "0.875rem",
                              }}
                            >
                              Task Status -{" "}
                              {action?.task_status ? action?.task_status : "NA"}
                            </Typography>
                          ) : null}
                        </Box>
                      </Box>
                    }
                    secondary={
                      <>
                        <Tooltip
                          title={action?.description}
                          placement="top-start"
                        >
                          <Typography
                            component="span"
                            variant="body2"
                            color="#202123"
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            {action?.description
                              ? action?.description?.length > 150
                                ? action?.description?.slice(0, 150) + "..."
                                : action?.description
                              : "NA"}
                          </Typography>
                        </Tooltip>
                        <Box
                          display={"flex"}
                          gap={1.4}
                          alignItems="center"
                          mt={0.5}
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          <CalendarMonthIcon />
                          <Typography
                            variant="body2"
                            display={"inline"}
                            color="#202123"
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Due Date -{" "}
                            {action?.due_date
                              ? dayjs(action?.due_date)?.format("ll")
                              : "NA"}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          gap={1.4}
                          alignItems="center"
                          mt={0.5}
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          <PersonIcon />
                          <Typography
                            variant="body2"
                            display={"inline"}
                            color="#202123"
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Assigned To:{" "}
                            {/* {action?.User?.full_name
                              ? action?.assigned_to === action?.assigned_by
                                ? "ME"
                                : action?.User?.full_name
                              : "NA"} */}
                            {action?.User?.full_name
                              ? action?.User?.full_name
                              : "NA"}
                            {/* {action?.name ==
                            sessionStorage.getItem("ownerFullName")
                              ? "me"
                              : action?.name} */}
                          </Typography>
                        </Box>
                        <Box
                          display={"flex"}
                          gap={1.4}
                          alignItems="center"
                          mt={0.5}
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          <PersonIcon />
                          <Typography
                            variant="body2"
                            display={"inline"}
                            color="#202123"
                            style={{
                              color: currentTheme == "DARK" ? "#fff" : "#000",
                            }}
                          >
                            Assigned By:{" "}
                            {action?.assignedBy?.full_name
                              ? action?.assignedBy?.full_name
                              : "NA"}
                            {/* {action?.assignedBy?.full_name
                              ? action?.assigned_to === action?.assigned_by
                                ? "ME"
                                : action?.assignedBy?.full_name
                              : "NA"} */}
                            {/* {action?.name ==
                            sessionStorage.getItem("ownerFullName")
                              ? "me"
                              : action?.name} */}
                          </Typography>
                        </Box>
                        <Grid
                          container
                          xs={12}
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems="center"
                          mt={0.5}
                        >
                          <Grid container item xs={12} md={4}>
                            {amIHost ? (
                              <Typography
                                variant="body2"
                                display={"inline"}
                                // color={
                                //   (action?.host_status === "PENDING" &&
                                //     "#EEC213") ||
                                //   (action?.host_status === "ACCEPTED" &&
                                //     "#26ae60") ||
                                //   (action?.host_status === "REJECTED" &&
                                //     "#FF4848")
                                // }
                                color={
                                  (action?.status === "PENDING" && "#EEC213") ||
                                  (action?.status === "ACCEPTED" &&
                                    "#26ae60") ||
                                  (action?.status === "REJECTED" && "red") ||
                                  (action?.status === "CANCELLED" && "#6d6d6d")
                                }
                              >
                                <span
                                  style={{
                                    color: "#202123",
                                    color:
                                      currentTheme == "DARK" ? "#fff" : "#000",
                                  }}
                                >
                                  Status:{" "}
                                </span>
                                {/* {action?.host_status
                                  ? action?.host_status
                                  : "NA"} */}
                                <span
                                  style={{
                                    fontWeight: "600",
                                    color:
                                      currentTheme == "DARK"
                                        ? "red"
                                        : "default",
                                  }}
                                >
                                  {action?.status ? action?.status : "NA"}
                                </span>
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                display={"inline"}
                                color={
                                  (action?.status === "PENDING" && "#EEC213") ||
                                  (action?.status === "ACCEPTED" &&
                                    "#26ae60") ||
                                  (action?.status === "REJECTED" && "red") ||
                                  (action?.status === "CANCELLED" && "#6d6d6d")
                                }
                              >
                                <span
                                  style={{
                                    color: "#202123",
                                    color:
                                      currentTheme == "DARK" ? "#fff" : "#000",
                                  }}
                                >
                                  Status:{" "}
                                </span>
                                <span style={{ fontWeight: "600" }}>
                                  {action?.status ? action?.status : "NA"}
                                </span>
                              </Typography>
                            )}
                          </Grid>
                          <Grid container item xs={12} md={3}>
                            {secondaryAction(
                              action,
                              statusUpdateHandler,
                              acceptLoadingStatus,
                              rejectLoadingStatus,
                              editLoadingStatus,
                              currentId,
                              isModalOpen,
                              setIsModalOpen,
                              currentActionId,
                              setCurrentActionId,
                              currentStatus,
                              setCurrentStatus,
                              message,
                              setMessage,
                              selectedDate,
                              setSelectedDate,
                              actionModal,
                              setActionModal,
                              getActionList,
                              updateAssignedActionList,
                              setCurrentId,
                              cancelledActions,
                              setUpdateAssignedActionList,
                              isUpdateModalOpen,
                              setIsUpdateModalOpen,
                              showUpdateButton,
                              setShowUpdateButton,
                              // acceptLoadingStatus,
                              setAcceptLoadingStatus,
                              setDeclineLoadingStatus,
                              // handleUpdateButtonClick,
                              taskStatus,
                              setTaskStatus
                            )}
                          </Grid>
                        </Grid>
                      </>
                    }
                  />
                </div>
              );
            })
          ) : isActionListLoading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            <Grid
              container
              item
              xs={12}
              sx={{
                minHeight: { xs: "0px", md: "122px" },
              }}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <h3
                style={{
                  textAlign: "center",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                No actions found
              </h3>
            </Grid>
          )}
        </div>
        <Grid
          container
          item
          xs={12}
          justifyContent={"flex-end"}
          alignItems={"center"}
          mt={2}
        >
          {totalPage > 1 && (
            <Pagination
              count={totalPage}
              defaultPage={filter.page}
              onChange={(e, page) => {
                const updatedFilter = { ...filter, page: page };
                setFilter(updatedFilter);
              }}
              sx={{ color: "#681e65" }}
            />
          )}
        </Grid>
      </div>
    </>
  );
};

export default AssignedAction;

const secondaryAction = (
  action,
  statusUpdateHandler,
  acceptLoadingStatus,
  rejectLoadingStatus,
  editLoadingStatus,

  currentId,
  isModalOpen,
  setIsModalOpen,
  currentActionId,
  setCurrentActionId,
  currentStatus,
  setCurrentStatus,
  message,
  setMessage,
  actionModal,
  setActionModal,
  selectedDate,
  setSelectedDate,
  getActionList,
  updateAssignedActionList,
  setCurrentId,
  cancelledActions,
  setUpdateAssignedActionList,
  isUpdateModalOpen,
  setIsUpdateModalOpen,
  showUpdateButton,
  setShowUpdateButton,
  // acceptLoadingStatus,
  setAcceptLoadingStatus,
  setDeclineLoadingStatus,
  // handleUpdateButtonClick,
  taskStatus,
  setTaskStatus
) => {
  const amIHost = sessionStorage.getItem("userId") == action?.assigned_by;
  console.log(currentStatus, "ACCEPTED");
  const btn = (
    <Grid container xs={12} sx={{ mt: { xs: 2, md: 0 }, gap: 1 }}>
      <>
        <Grid item xs={12} sm={5.6}>
          <LoadingButton
            loading={currentId == action?.id && rejectLoadingStatus}
            // disabled={rejectLoadingStatus}
            fullWidth
            edge="end"
            style={{
              background: "#e40303",
              // borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            sx={{ color: "#fff", py: 1, px: 4 }}
            onClick={() => {
              // statusUpdateHandler(action?.id, "REJECTED");
              setCurrentId(action?.id);

              setCurrentStatus("REJECTED");
              setMessage("Would you like to decline this action?");
              setIsModalOpen(true);
              setTaskStatus(null);
            }}
          >
            Decline
          </LoadingButton>
        </Grid>
        <Grid item xs={12} sm={5.6}>
          <LoadingButton
            loading={currentId == action?.id && acceptLoadingStatus}
            disabled={acceptLoadingStatus}
            fullWidth
            edge="end"
            style={{
              background: "#19c37d",
              // borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
            sx={{
              color: "#fff",
              py: 1,
              px: 4,
              mr: 1.4,
              "(min-width:800px)": {
                mr: 0,
              },
            }}
            onClick={() => {
              // statusUpdateHandler(action?.id, "ACCEPTED");
              setCurrentId(action?.id);
              setCurrentStatus("ACCEPTED");
              setMessage("Would you like to accept this action?");
              setIsModalOpen(true);
              setTaskStatus(null);
            }}
          >
            Accept
          </LoadingButton>
        </Grid>
      </>
      {/* )} */}

      <ActionCallModalPopup
        open={isModalOpen}
        setOpen={setIsModalOpen}
        funcToExecute={statusUpdateHandler}
        message={message}
        currentId={currentId}
        action={action}
        currentStatus={currentStatus}
        setAcceptLoadingStatus={setAcceptLoadingStatus}
        setDeclineLoadingStatus={setDeclineLoadingStatus}
        setShowUpdateButton={setShowUpdateButton}
        showUpdateButton={showUpdateButton}
        taskStatus={taskStatus}
      />
    </Grid>
  );
  const btn2 = (
    <>
      {!cancelledActions.includes(action.id) && (
        <Grid container xs={12} sx={{ mt: { xs: 2, md: 0 }, gap: 1 }}>
          <Grid item xs={12} sm={5.6}>
            <LoadingButton
              // loading={currentId == action?.id && cancelLoadingStatus }
              // disabled={cancelLoadingStatus}
              fullWidth
              edge="end"
              style={{
                backgroundColor: "#e40303",
                // borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              sx={{
                color: "#fff",
                py: 1,
                px: 4,
                mr: 1.4,
                "(min-width:800px)": {
                  mr: 0,
                },
              }}
              onClick={() => {
                // statusUpdateHandler(action?.id, "CANCELLED");
                console.log(action?.id);
                setCurrentId(action?.id);
                setCurrentStatus("CANCELLED");
                setMessage("Would you like to cancel this action?");
                setIsModalOpen(true);
                setTaskStatus(null);
              }}
            >
              Cancel
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={5.6}>
            <Button
              loading={currentId == action?.id && editLoadingStatus}
              disabled={editLoadingStatus}
              fullWidth
              edge="end"
              style={{
                backgroundColor: "#632876",
                // borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              sx={{
                color: "#fff",
                py: 1,
                px: 4,
                mr: 1.4,
                "(min-width:800px)": {
                  mr: 0,
                },
              }}
              onClick={() => {
                setCurrentActionId(action?.id);
                console.log(action?.id);
                // statusUpdateHandler(action?.id, "ACCEPTED");
                // setCurrentStatus("ACCEPTED");
                // setMessage("Would you like to accept this action?");
                setActionModal(true);
              }}
            >
              Edit
            </Button>
          </Grid>

          <SchedulePopUp
            title="Edit Action"
            date={selectedDate}
            currentId={currentActionId}
            setDate={setSelectedDate}
            open={actionModal}
            setOpen={setActionModal}
            action={action}
            getActionList={getActionList}
            updateAssignedActionList={updateAssignedActionList}
            setUpdateAssignedActionList={setUpdateAssignedActionList}
          />
          <ActionCallModalPopup
            open={isModalOpen}
            setOpen={setIsModalOpen}
            funcToExecute={statusUpdateHandler}
            message={message}
            currentId={currentId}
            action={action}
            // currentId={currentActionId}
            currentStatus={currentStatus}
            setAcceptLoadingStatus={setAcceptLoadingStatus}
            setDeclineLoadingStatus={setDeclineLoadingStatus}
            setShowUpdateButton={setShowUpdateButton}
            showUpdateButton={showUpdateButton}
            taskStatus={taskStatus}
          />
        </Grid>
      )}
    </>
  );
  const updateButton = (
    <>
      {action?.task_status !== "CONFIRMED" ? (
        <Grid container gap={1}>
          <Grid item xs={12} sm={5.6}></Grid>
          <Grid item xs={12} sm={5.6}>
            <LoadingButton
              edge="end"
              fullWidth
              style={{
                background: "blue",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              sx={{ color: "#fff", py: 1, px: 4 }}
              onClick={() => {
                setCurrentId(action?.id);
                setIsUpdateModalOpen(true);
                setShowUpdateButton(false);
              }}
            >
              Update
            </LoadingButton>
          </Grid>
        </Grid>
      ) : null}
      <UpdateStatusModal
        open={isUpdateModalOpen}
        setOpen={setIsUpdateModalOpen}
        funcToExecute={statusUpdateHandler}
        message="Update Status Of Your Task"
        currentId={currentId}
        currentStatus={currentStatus}
        setAcceptLoadingStatus={setAcceptLoadingStatus}
        setDeclineLoadingStatus={setDeclineLoadingStatus}
        setShowUpdateButton={setShowUpdateButton}
        showUpdateButton={showUpdateButton}
        taskStatus={taskStatus}
      />
    </>
  );

  const completedButton = (
    <>
      <Grid container xs={12} sx={{ mt: { xs: 2, md: 0 }, gap: 1 }}>
        <Grid item xs={12} sm={5.6}>
          {(action?.task_status === "COMPLETED" ||
            action?.task_status !== "CONFIRMED") && (
            <LoadingButton
              edge="end"
              style={{
                background: "#555516",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              sx={{
                color: "#fff",
                py: 1,
                px: 3,
                mr: 1.4,
                "(min-width:800px)": {
                  mr: 0,
                },
              }}
              onClick={() => {
                setCurrentId(action?.id);
                setTaskStatus("CONFIRMED");
                setMessage("Would you like to confirm this action?");
                setIsModalOpen(true);
              }}
            >
              CONFIRM
            </LoadingButton>
          )}
        </Grid>
        <Grid item xs={12} sm={5.6}>
          {(action?.task_status === "COMPLETED" ||
            action?.task_status === "CONFIRMED") && (
            <LoadingButton
              loading={currentId === action?.id && acceptLoadingStatus}
              disabled={acceptLoadingStatus}
              edge="end"
              style={{
                background: "rgb(204, 61, 11)",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
              sx={{
                color: "#fff",
                py: 1,
                px: 3,
                // mr: 1.4,
                "(min-width:800px)": {
                  mr: 0,
                },
              }}
              onClick={() => {
                setCurrentId(action?.id);
                setTaskStatus("REOPENED");
                setMessage("Would you like to re-open this action?");
                setIsModalOpen(true);
              }}
            >
              REOPEN
            </LoadingButton>
          )}
        </Grid>
        <ActionCallModalPopup
          open={isModalOpen}
          setOpen={setIsModalOpen}
          funcToExecute={statusUpdateHandler}
          message={message}
          currentId={currentId}
          action={action}
          currentStatus={currentStatus}
          setAcceptLoadingStatus={setAcceptLoadingStatus}
          setDeclineLoadingStatus={setDeclineLoadingStatus}
          setShowUpdateButton={setShowUpdateButton}
          showUpdateButton={showUpdateButton}
          taskStatus={taskStatus}
        />
      </Grid>
    </>
  );

  return amIHost
    ? action?.task_status === "COMPLETED" ||
      action?.task_status === "CONFIRMED" ||
      action?.task_status === "REOPENED"
      ? completedButton
      : action?.status !== "REJECTED" && btn2
    : action?.status === "PENDING"
    ? btn
    : action?.status === "ACCEPTED"
    ? updateButton
    : null;
};
