import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  Grid,
  Box,
  Typography,
  Button,
  Tooltip,
  CircularProgress,
  Container,
} from "@mui/material";
import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DashboardAdvisorList } from "../../utils/discoveryCoaching";
import "./calendar.scss";
import { ApiConfig } from "../../services/ApiConfig";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import { useLocation, useNavigate } from "react-router";
import SchedulePopUp from "../../components/scheduleAction/SchedulePopUp";
import AssignedAction from "../../components/scheduleAction/AssignedAction";
import ScheduleCallTab from "../../components/scheduleAction/ScheduleCallTab";
import ScheduleCallPopUp from "../../components/scheduleAction/ScheduleCallPopUp";
import { PickersDay } from "@mui/x-date-pickers";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";

const MainContainer = styled("Box")(({ theme }) => ({
  "& .createBtn": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    left: "160px",
    bottom: "20px",
    [theme.breakpoints.down("md")]: {
      left: "265px",
      bottom: "85px",
    },
    [theme.breakpoints.down("sm")]: {
      left: "90px",
      bottom: "180px",
    },
    "@media only screen and (max-width: 375px)": {
      left: "30px",
      bottom: "180px",
    },

    // [theme.breakpoints.down("xs")]: {
    //   left: "30px",
    //   bottom: "180px",
    // },
    // "@media only screen and (max-width: 375px)": {
    //   "& .createBtn ": {
    //     left: "60px !important",
    //     bottom: "180px !important",
    //   },
    // },
  },

  "& .actionPlnText": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));
const HighlightedDay = styled(PickersDay)(({ theme }) => ({
  "&.MuiPickersDay-root.Mui-selected": {
    background: "#de5c05 !important",
    color: "#ffffff !important",
    "@media screen and (max-width: 450px)": {
      fontSize: "14px !important",
    },
  },
  "&.MuiPickersDay-root.Mui-selected:hover": {
    background: "#de5c05 !important",
    color: "#ffffff !important",
    "@media screen and (max-width: 450px)": {
      fontSize: "14px !important",
    },
  },
}));

function ActionPlanning(
  updateAssignedActionList,
  setUpdateAssignedActionList,
  updateScheduleCallList,
  setUpdateScheduleCallList
) {
  const [value, setValue] = useState(dayjs("2022-04-17"));
  const [actionModal, setActionModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [scheduleModal, setScheduleModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [actionsList, setActionsLists] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const actionScheduleRefresher = useSelector(
    (state) => state.actionScheduleRefresher
  );
  const [acceptLoadingStatus, setAcceptLoadingStatus] = useState(false);
  const [rejectLoadingStatus, setRejectLoadingStatus] = useState(false);
  const getActionsList = async () => {
    setIsLoading(true);
    const payloadToSend = {
      // status: location.pathname == "/action-planning" ? "ACCEPTED" : null,
      status: "ACCEPTED",
      fromDate: startDate
        ? dayjs(startDate).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
      toDate: endDate
        ? dayjs(endDate).format("YYYY-MM-DD")
        : dayjs().format("YYYY-MM-DD"),
    };

    const res = await apiWithToken({
      url: ApiConfig.findAction,
      method: "GET",
      params: payloadToSend,
    });
    setIsLoading(false);
    if (res?.data?.statusCode === 200) {
      setActionsLists(res?.data?.result?.actions);
    }
  };

  useEffect(() => {
    getActionsList();
  }, [
    endDate,
    startDate,
    updateAssignedActionList,
    location.pathname,
    actionScheduleRefresher?.action,
  ]);

  const ServerDay = (props) => {
    const { day, outsideCurrentMonth, ...other } = props;
    const isSelected =
      !props.outsideCurrentMonth &&
      (day.isAfter(startDate, "day") || day.isSame(startDate, "day")) &&
      (day.isBefore(endDate, "day") || day.isSame(endDate, "day"));

    return (
      <HighlightedDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        selected={isSelected}
      />
    );
  };
  const dateChangeHandler = (e) => {
    if (!startDate) {
      setStartDate(e);
      setEndDate(e);
      return;
    } else {
      if (dayjs(startDate).isAfter(dayjs(e))) {
        const stD = startDate;
        const endD = e;
        setStartDate(endD);
        setEndDate(stD);
        return;
      }
      setEndDate(e);
    }
  };

  const actionHandler = async (type, data) => {
    setCurrentId(data?.id);
    if (type == "ACCEPTED") {
      setAcceptLoadingStatus(true);
    } else {
      setRejectLoadingStatus(true);
    }

    const options = {
      method: "PUT",
      url: ApiConfig.updateAction,
      data: {
        action_id: data?.id,
        status: type,
      },
    };
    const res = await apiWithToken(options);
    if (type == "ACCEPTED") {
      setAcceptLoadingStatus(false);
    } else {
      setRejectLoadingStatus(false);
    }
    if (res?.data?.statusCode === 200) {
      getActionsList();
    }
  };

  return (
    <MainContainer>
      <Box
        // container
        // item
        // xs={12}
        // justifyContent={"center"}
        alignItems={"center"}
        sx={{
          backgroundColor:
            location?.pathname === "/dashboard" ? "#523168" : "#202123",
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            // xs={12}
            // md={10.7}
            sx={{
              backgroundColor:
                location?.pathname === "/dashboard" ? "#523168" : "#202123",
              paddingTop: "32px",
              paddingBottom: "70px",
              mr: { xs: 0, sm: 10 },
            }}
            justifyContent={"center"}
          >
            <Grid
              item
              container
              xs={12}
              md={6}
              position={"relative"}
              alignItems={"flex-start"}
              // bgcolor={"orange"}
              justifyContent={"flex-start"}
            >
              <Box
                // container
                // item
                // xs={12}
                // justifyContent={"center"}
                // alignItems={"center"}
                width={"100%"}
              >
                <Box
                  className="actionPlnText"
                  // component={"div"}
                  // width={"100%"}
                  // sx={{
                  //   display: "flex",
                  //   justifyContent: "space-between",
                  //   alignItems: "center",
                  // }}
                >
                  <Typography
                    sx={{
                      color: "#fff",
                      fontSize: { md: "45px", xs: "22px" },
                      fontWeight: "600",
                      fontFamily: "Outfit",
                      // px: "80px",
                      mb: 3,
                      // ml: -2,
                    }}
                  >
                    Action Planning
                  </Typography>
                  {startDate && endDate && (
                    <Button
                      onClick={() => {
                        setStartDate("");
                        setEndDate("");
                      }}
                      sx={{
                        textTransform: "none",
                        color: "#fff",
                        fontSize: "1em",
                        fontWeight: "600",
                        fontFamily: "Outfit",
                        // mb: 1,
                        px: 4,
                      }}
                      style={{
                        backgroundColor: "#d95307",
                      }}
                    >
                      Reset
                    </Button>
                  )}
                </Box>
              </Box>
              <Grid container item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    component={"div"}
                    className="dashBoardCalendar"
                    sx={{
                      width: "100%",
                      height: { xs: "650px", lg: "550px" },
                      display: "flex",
                    }}
                  >
                    <DateCalendar
                      sx={{
                        "&.MuiDateCalendar-root": {
                          borderRadius: "20px",
                          borderTopLeftRadius: "20px",
                          borderTopRightRadius: "20px",
                          marginLeft: "0px",
                          marginRight: "0px",
                          width: "100% !important",
                        },
                      }}
                      slots={{
                        day: ServerDay,
                      }}
                      // disablePast={true}
                      value={selectedDate}
                      onChange={dateChangeHandler}
                    />
                  </Box>
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                item
                xs={12}
                justifyContent={"center"}
                position={"absolute"}
                bottom={"28px"}
              >
                <Box
                  className="createBtn"
                  // container
                  // xs={11}
                  // bgcolor={"#fff"}
                  // justifyContent={"center"}
                  // position={"absolute"}
                  // left={"90px"}
                  // bottom={"20px"}
                  // sx={{
                  //   width: "70% !important",
                  //   bottom: { xs: "144px", sm: "41px", lg: "50px" },
                  //   borderBottomLeftRadius: "20px",
                  //   borderBottomRightRadius: "20px",
                  //   py: 2,
                  //   "@media screen and (min-width: 902px)": {
                  //     bottom: "0px",
                  //   },
                  // }}
                >
                  <Button
                    variant="contained"
                    style={{
                      background:
                        "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    }}
                    sx={{
                      px: "64px",
                      py: "8px",
                      borderRadius: "12px",
                      textTransform: "none",
                      fontSize: { lg: "20px", xs: "15px" },
                    }}
                    onClick={() => {
                      setActionModal(true);
                    }}
                  >
                    Create Action
                  </Button>
                </Box>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={6}
              sx={{
                mt: {
                  xs: 2,
                  md: 0,
                },
                // mr: { xs: 0, md: 10 },
              }}
              className="scrollBarCustomColor"
            >
              <Box
                // item
                // xs={12}
                // xl={11.5}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "10px",
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { md: "25px", xs: "18px" },
                    fontWeight: "600",
                    fontFamily: "Outfit",
                    // ml: 3,
                    mt: 4,
                  }}
                >
                  Your Upcoming Actions
                </Typography>
                {location?.pathname === "/dashboard" ? (
                  <Button
                    variant="contained"
                    sx={{
                      background:
                        "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                      marginTop: "32px",
                      fontSize: "18px",
                      py: "8px",
                      borderRadius: "4px",
                      textTransform: "capitalize",
                    }}
                    onClick={() => {
                      navigate("/action-planning");
                    }}
                  >
                    Actions and Scheduled call
                  </Button>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      padding: "15px 20px",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <img src="/images/zoom.png" alt="" />
                    <span
                      style={{
                        color: "#0B5CFF",
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                      onClick={() => setScheduleModal(true)}
                    >
                      Zoom With Advisor
                    </span>
                  </div>
                )}
              </Box>
              <Box
                component={OverlayScrollbarsComponent}
                style={{
                  overflowY: "scroll",
                  overflowX: "hidden",
                  height: "100%",
                  maxHeight: "522px",
                  display: actionsList.length < 1 && "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                sx={{ px: { xs: 1, sm: 4 }, width: { xs: "100%" } }}
              >
                {isLoading ? (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <CircularProgress sx={{ color: "#e16104" }} />
                  </Grid>
                ) : actionsList.length > 0 ? (
                  actionsList
                    ?.filter((e) => {
                      console.log(e?.status, "asjbkgea");
                      return e?.status != "REJECTED";
                    })
                    ?.map((action, i) => (
                      <Grid
                        alignItems="center"
                        container
                        item
                        xs={12}
                        key={i}
                        style={{
                          background: "#fff",
                          borderRadius: "10px",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src="/images/actionIcon.png"
                          alt=""
                          height={"40px"}
                          style={{ margin: "0 15px 0 10px" }}
                        />
                        <Grid container item lg={10} md={10} xs={10}>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              "@media (max-width:600px)": {
                                display: "grid",
                              },
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "18px",
                                fontWeight: "500",
                                color: "#202123",
                              }}
                            >
                              {/* {action.category ? action.category : "NA"} */}

                              {action.actionTypeMaster.action_type
                                ? action.actionTypeMaster.action_type
                                : "NA"}
                            </Typography>
                            <Typography
                              style={{
                                color: "#e26404",
                                fontSize: "14px",
                                fontWeight: "400",
                              }}
                              sx={{
                                ml: { xs: 0, sm: "20px" },
                                mt: 0.5,
                              }}
                            >
                              Assigned Date:{" "}
                              <span
                                style={{
                                  color: "#202123",
                                  fontSize: "14px",
                                  fontWeight: "400",
                                }}
                              >
                                {action.createdAt
                                  ? dayjs(action.createdAt).format("ll")
                                  : "NA"}
                              </span>
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                              alignItems: "center",
                              "@media (max-width:600px)": {
                                display: "grid",
                              },
                            }}
                          >
                            <div style={{ maxWidth: "360px", width: "100%" }}>
                              <Tooltip
                                title={action.description}
                                placement="top"
                              >
                                <Typography
                                  style={{
                                    color: "rgba(32, 33, 35, 0.75)",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {action.description
                                    ? action.description?.length > 80
                                      ? action.description?.slice(0, 80) + "..."
                                      : action.description
                                    : "NA"}
                                </Typography>
                              </Tooltip>
                            </div>
                          </Box>
                          <Grid
                            container
                            item
                            xs={12}
                            justifyContent={"space-between"}
                            alignItems={"center"}
                            flexDirection={"row"}
                            mt={1}
                          >
                            {location.pathname == "/dashboard" && (
                              <>
                                {action?.status != "ACCEPTED" ? (
                                  <>
                                    <Grid
                                      item
                                      container
                                      // bgcolor="orange"
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        style={{
                                          color: "#d14609",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        Due Date:{" "}
                                        <span
                                          style={{
                                            color: "#202123",
                                            fontSize: "14px",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {action.due_date
                                            ? dayjs(action.due_date)?.format(
                                                "ll"
                                              )
                                            : "NA"}
                                        </span>
                                      </Typography>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          gap: "16px",
                                        }}
                                      >
                                        <LoadingButton
                                          loading={
                                            currentId == action?.id &&
                                            rejectLoadingStatus
                                          }
                                          disable={rejectLoadingStatus}
                                          style={{
                                            textTransform: "none",
                                            height: "36px",
                                            borderRadius: "8px",
                                            width: "80px",
                                            boxShadow:
                                              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                                            backgroundColor: "#e40303",
                                          }}
                                          sx={{
                                            color: "#fff",
                                          }}
                                          onClick={() => {
                                            actionHandler("REJECTED", action);
                                          }}
                                        >
                                          Decline
                                        </LoadingButton>
                                        <LoadingButton
                                          loading={
                                            currentId == action?.id &&
                                            acceptLoadingStatus
                                          }
                                          disabled={acceptLoadingStatus}
                                          style={{
                                            textTransform: "none",
                                            height: "36px",
                                            borderRadius: "8px",
                                            width: "80px",
                                            boxShadow:
                                              "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
                                            backgroundColor: "#19c37d",
                                          }}
                                          sx={{
                                            color: "#fff",
                                          }}
                                          onClick={() => {
                                            actionHandler("ACCEPTED", action);
                                          }}
                                        >
                                          Accept
                                        </LoadingButton>
                                      </Box>
                                    </Grid>
                                  </>
                                ) : (
                                  <>
                                    <Typography
                                      style={{
                                        color: "#d14609",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      Due Date:{" "}
                                      <span
                                        style={{
                                          color: "#202123",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {action.due_date
                                          ? dayjs(action.due_date)?.format("ll")
                                          : "NA"}
                                      </span>
                                    </Typography>
                                    <Typography
                                      style={{
                                        color: "#ce5b04",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      Assigned To:{" "}
                                      <span
                                        style={{
                                          color: "#202123",
                                          fontSize: "14px",
                                          fontWeight: "400",
                                        }}
                                      >
                                        {action?.User?.full_name
                                          ? action?.assigned_to ===
                                            action?.assigned_by
                                            ? "ME"
                                            : action?.User?.full_name
                                          : "NA"}
                                      </span>
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}

                            {location.pathname != "/dashboard" && (
                              <>
                                <Typography
                                  style={{
                                    color: "#d14609",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Due Date:{" "}
                                  <span
                                    style={{
                                      color: "#202123",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {action.due_date
                                      ? dayjs(action.due_date)?.format("ll")
                                      : "NA"}
                                  </span>
                                </Typography>
                                <Typography
                                  style={{
                                    color: "#ce5b04",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  Assigned To:{" "}
                                  <span
                                    style={{
                                      color: "#202123",
                                      fontSize: "14px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    {action?.User?.full_name
                                      ? action?.assigned_to ===
                                        action?.assigned_by
                                        ? "ME"
                                        : action?.User?.full_name
                                      : "NA"}
                                  </span>
                                </Typography>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))
                ) : (
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    alignItems={"center"}
                    sx={{
                      minHeight: { xs: 0, md: "522px" },
                    }}
                  >
                    <h3 style={{ color: "#fff", margin: "0" }}>
                      No upcoming actions.
                    </h3>
                  </Grid>
                )}
              </Box>
            </Grid>
            <SchedulePopUp
              title={"Create Action"}
              date={selectedDate}
              setDate={setSelectedDate}
              open={actionModal}
              setOpen={setActionModal}
              getActionsList={getActionsList}
              setIsActionListUpdated={setUpdateAssignedActionList}
              isActionListUpdated={updateAssignedActionList}
            />
            <ScheduleCallPopUp
              setOpen={setScheduleModal}
              open={scheduleModal}
              isCallListUpdated={updateScheduleCallList}
              setIsCallListUpdated={setUpdateScheduleCallList}
            />
          </Grid>
        </Container>
      </Box>
    </MainContainer>
  );
}

export default ActionPlanning;
