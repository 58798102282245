import customAxios from "../../utils/customAxios";

export const apiWithToken = async ({ method, url, data, params }) => {
  try {
    const res = await customAxios({
      method: method,
      url: url,
      headers: {
        token: sessionStorage.getItem("userToken"),
      },
      data: data,
      params: params,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
