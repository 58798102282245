import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  Grid,
  CircularProgress,
  InputAdornment,
  IconButton,
  MenuItem,
  Select,
  FormHelperText,
} from "@mui/material";
import { Formik, Form } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ApiConfig } from "../../services/ApiConfig";
import { ToastContainer, toast } from "react-toastify";
import NativeSelect from "@mui/material/NativeSelect";
import InputBase from "@mui/material/InputBase";
import InputLabel from "@mui/material/InputLabel";
import { Link } from "react-router-dom";
import { VisibilityOff, RemoveRedEye } from "@mui/icons-material";
import { ErrorMessage } from "formik";
import { Field } from "formik";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./signUp.scss";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import { height, maxHeight } from "@mui/system";

// import { useLocation, useNavigate } from "react-router-dom";

const LoginWrapper = styled("div")({
  // height: "100vh",
  width: "45%",
  overflowY: "scroll",
  marginTop: "120px",
  marginRight: "100px",
  "&::-webkit-scrollbar": {
    display: "none", // Hide the scrollbar
  },
  "@media(max-width:1200px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    // marginTop: "200px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
  // backgroundColor: "yellow",
});

const FormContainer = styled(Form)({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",

  "@media(max-width:640px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});

const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "4px",

  "@media(max-width:1200px)": {
    // width: "50vw !important",
    // marginRight: "10px",
    // margin: "10px 0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
});

const useStyle = {
  textFieldWidth: {
    width: "100%",
    backgroundColor: "#fff",
    borderRadius: "10px",
    height: "27px",
    // maxWidth: "500px",
    "@media (max-width:600px)": { maxWidth: "100%" },
  },

  grid: {
    textAlign: "left",
    // paddingRight: { sm: "0px", xs: "20px" },
    paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "16px", xs: "0px" },
    "@media (max-width:1200px)": { paddingLeft: "30px !important" },
  },
  phoneNumber: {
    marginTop: "20px",
    height: "50px",
    width: "100%",
    maxWidth: "500px",
    borderRadius: "8px",
    "@media (max-width:600px)": { maxWidth: "100%" },
  },
};

const initialValues = {
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  title: "",
  businessName: "",
  mobileNumber: "",
  confirmPassword: "",
  selectedOption: "",
  termsAndConditions: false,
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter a valid email address.")
    .required("Email address is required.")
    .matches(
      /^[a-zA-Z0-9._%+$-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      "Please enter a valid email address."
    )
    .min(8, "Email address must be at least 8 characters."),
  password: Yup.string()
    .required("Password is required.")
    .min(6, "Password must be at least 6 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,

      "Password requires uppercase, lowercase, number, and special character."
    ),
  firstName: Yup.string()
    .required("First name is required.")
    .min(2, "First name must be at least 2 characters.")

    .matches(
      /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/,
      "First name should not have spaces."
    ),
  lastName: Yup.string()
    .min(2, "Last Name must be at least 2 characters.")

    .required("Last name is required."),
  // .matches(/^[a-zA-Z\s]+$/, "Last name should only contain letters."),
  title: Yup.string()
    .min(2, "Title must be at least 2 characters.")
    // .matches(/^[a-zA-Z\s]+$/, "Title should only contain letters.")
    .required("Title is required."),
  businessName: Yup.string()
    .min(3, "Business name must be at least 3 characters.")
    .required("Business name is required."),
  mobileNumber: Yup.string()
    .min(10, "Mobile number must be atleast 10 digits.")
    .required("Mobile number is required.")
    .matches(/^(?!0+$)[0-9]{10,}$/, "Invalid mobile number."),
  confirmPassword: Yup.string()
    .required("Confirmation of your password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
  selectedOption: Yup.string().required("*Please select an option."),
  termsAndConditions: Yup.boolean().oneOf(
    [true],
    "Please accept Terms & Conditions."
  ),
});

const SignUp = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("");
  const [isHidden, setIsHidden] = useState(false);

  const [isConfirmHidden, setIsConfirmHidden] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const [age, setAge] = useState("");

  const [category, setCatgory] = useState([]);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const getCategory = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getBusinessCategoryList,
      });
      if (res.data?.statusCode == 200) {
        setCatgory(res?.data?.result?.allCategory);
        // sessionStorage.setItem("userToken", res.data.result.token);
      } else {
        console.log(
          res.data?.responseMessage ||
            "Something went wrong. Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  const signUpHandler = async (values) => {
    setLoading(true);

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.userRegistration,
        data: {
          email: values.email?.toLowerCase(),
          first_name: values.firstName,
          last_name: values.lastName,
          business_name: values.businessName,
          mobile_number: values.mobileNumber,
          password: values.password,
          title: values.title,
          user_type: "BUSINESS_OWNER",
          business_category: values.selectedOption,
          country_code: countryCode,
        },
      });
      if (res.data?.statusCode == 200) {
        // sessionStorage.setItem("userToken", res.data.result.token);
        // sessionStorage.setItem("userId", res.data.result.id);
        // sessionStorage.setItem("userType", res.data.result.user_type);
        sessionStorage.setItem("userEmail", values?.email?.toLowerCase());
        sessionStorage.setItem("userMobille", values?.mobileNumber);
        navigate("/otp", {
          state: { email: values?.email, otp: values?.otp, type: "signup" },
        });
      } else {
        setErrorMessage(
          res.data?.responseMessage ||
            "Something went wrong. Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);

        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  useEffect(() => {}, []);

  return (
    <LoginWrapper
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
      }}
    >
      <Typography
        color={currentTheme == "DARK" ? "#fff" : "#000"}
        style={{ textAlign: "left" }}
        variant="h4"
        fontWeight={"500"}
        mt={2}
      >
        Get Started Now
      </Typography>
      <Typography
        style={{ color: currentTheme == "DARK" ? "#fff" : "#a3a3a3" }}
        sx={{
          textAlign: "left",

          color: "rgba(32, 33, 35, 0.60)",
          fontSize: "18px",
        }}
        variant="body1"
        fontWeight={"400"}
        mt={2}
      >
        Already have an account?{" "}
        <Link style={{ textDecoration: "none" }} to="/login">
          {" "}
          <span
            style={{
              color: "#523168",
              fontWeight: "600",
              color: currentTheme == "DARK" ? "#fff" : "#523168",
            }}
          >
            Login
          </span>
        </Link>
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          signUpHandler(values);
        }}
      >
        {({
          errors,
          touched,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <Grid container spacing={2} mt={1.5} mr={2}>
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   // paddingRight: { sm: "0px", xs: "20px" },
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              {" "}
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                First Name
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 60,
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                variant="outlined"
                name="firstName"
                placeholder="Enter your first name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                onInput={(e) => {
                  const regex =
                    /^[a-zA-Z0-9!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]*$/;

                  if (!regex.test(e.target.value)) {
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    e.target.dataset.previousValue = e.target.value;
                  }
                  setErrorMessage("");
                }}
              />
            </Grid>
            <Grid item sx={useStyle.grid} xl={6} md={6} sm={6} xs={12}>
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                Last Name
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 60,
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                variant="outlined"
                name="lastName"
                placeholder="Enter your last name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                onInput={(e) => {
                  // Regular expression to allow only letters and spaces
                  // const regex = /^[a-zA-Z\s]*$/;

                  // // Check if the input matches the regex
                  // if (!regex.test(e.target.value)) {
                  //   // If not, set the value to the previous valid value
                  //   e.target.value = e.target.dataset.previousValue || "";
                  // } else {
                  //   // If valid, update the previous valid value
                  //   e.target.dataset.previousValue = e.target.value;
                  // }
                  setErrorMessage("");
                }}
              />
            </Grid>
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                Title (Designation){" "}
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 60,
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                variant="outlined"
                name="title"
                placeholder="Enter title"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.title && Boolean(errors.title)}
                helperText={touched.title && errors.title}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                onInput={(e) => {
                  // Regular expression to allow only letters and numbers
                  const regex = /^[a-zA-Z0-9\s]*$/;

                  // Check if the input matches the regex
                  if (!regex.test(e.target.value)) {
                    // If not, set the value to the previous valid value
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    // If valid, update the previous valid value
                    e.target.dataset.previousValue = e.target.value;
                  }
                  setErrorMessage("");
                }}
              />
            </Grid>{" "}
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   // paddingRight: { sm: "0px", xs: "20px" },
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                Business Name
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 60,
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                variant="outlined"
                name="businessName"
                placeholder="Enter business name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.businessName && Boolean(errors.businessName)}
                helperText={touched.businessName && errors.businessName}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                onInput={() => {
                  setErrorMessage("");
                }}
              />
            </Grid>{" "}
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                Email
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 60,
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                variant="outlined"
                name="email"
                placeholder="Enter email address"
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={(e) => {
                  let value = e.target.value;

                  value = value.replace(/\s/g, "");

                  e.target.value = value.toLowerCase();
                  setErrorMessage("");
                }}
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
              />
            </Grid>{" "}
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
                mb="4px"
              >
                Mobile Number
              </Typography>
              {/* <div style={{ display: "flex", alignItems: "center" }}> */}

              <PhoneInput
                country={"us"}
                value={values.mobileNumber}
                onChange={(mobileNumber, cc) => {
                  setFieldValue("mobileNumber", mobileNumber);
                  setCountryCode(cc?.dialCode);
                  setErrorMessage("");
                }}
                className="mobilefield"
                onBlur={handleBlur}
                placeholder="Enter your mobile number"
                inputClass="form-control"
                // error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                // helperText={touched.mobileNumber && errors.mobileNumber}
                inputProps={{
                  name: "mobileNumber",
                  required: true,
                  style: {
                    marginTop: "20px",

                    width: "100%",
                    maxWidth: "500px",
                    borderRadius: "10px",

                    "@media (max-width:600px)": { maxWidth: "100% !important" },
                  },
                }}
              />
              {touched.mobileNumber && errors.mobileNumber && (
                <FormHelperText sx={{ color: "#d32f2f" }}>
                  {errors.mobileNumber}
                </FormHelperText>
              )}
              {/* <TextField
                  inputProps={{
                    style: {
                      height: "20px",
                      width: "280px",
                    },
                  }}
                  variant="outlined"
                  name="mobileNumber"
                  placeholder="Enter your mobile number"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.mobileNumber}
                  error={touched.mobileNumber && Boolean(errors.mobileNumber)}
                  helperText={touched.mobileNumber && errors.mobileNumber}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: "0px",
                    },
                  }}
                /> */}
              {/* </div> */}
            </Grid>{" "}
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                Create Password
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 20,
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                type={isHidden ? "text" : "password"}
                variant="outlined"
                name="password"
                placeholder="Create your password"
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                    lineHeight: "10px",
                  },
                }}
                onInput={(e) => {
                  const regex = /^[^\s]*$/;

                  if (!regex.test(e.target.value)) {
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    e.target.dataset.previousValue = e.target.value;
                  }
                  setErrorMessage("");
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      style={{ backgroundColor: "#fff" }}
                      position="end"
                    >
                      <IconButton onClick={() => setIsHidden((p) => !p)}>
                        {isHidden ? <RemoveRedEye /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={6}
              md={6}
              sm={6}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                {" "}
                Confirm Password
              </Typography>
              <FormTextField
                inputProps={{
                  maxLength: 20,
                  onPaste: (e) => e.preventDefault(),
                  style: {
                    backgroundColor: "#fff",
                    height: "27px",
                  },
                }}
                sx={useStyle.textFieldWidth}
                type={isConfirmHidden ? "text" : "password"}
                variant="outlined"
                name="confirmPassword"
                placeholder="Confirm your password"
                onChange={handleChange}
                onBlur={handleBlur}
                onInput={(e) => {
                  const regex = /^[^\s]*$/;

                  if (!regex.test(e.target.value)) {
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    e.target.dataset.previousValue = e.target.value;
                  }
                  setErrorMessage("");
                }}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsConfirmHidden((p) => !p)}>
                        {isConfirmHidden ? <RemoveRedEye /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              // sx={{
              //   textAlign: "left",
              //   paddingRight: { xs: "16px", sm: "16px", md: "16px", lg: "0px" },
              // }}
              sx={useStyle.grid}
              xl={12}
              md={12}
              sm={12}
              xs={12}
            >
              <Typography
                sx={{
                  marginLeft: { lg: "0vw" },
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
                fontSize="18px"
              >
                <label htmlFor="selectedOption">Business Industry</label>
              </Typography>
              <FormControl fullWidth>
                <Select
                  style={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    height: "60px",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="selectedOption"
                  // variant="outlined"
                  displayEmpty
                  // fullWidth

                  value={values.selectedOption}
                  onChange={handleChange}
                  sx={useStyle.textFieldWidth}
                  inputProps={{
                    style: {
                      height: "20px",
                      width: "230px",
                    },
                  }}
                  onBlur={handleBlur}
                  error={
                    touched.selectedOption && Boolean(errors.selectedOption)
                  }
                  helperText={touched.selectedOption && errors.selectedOption}
                >
                  <MenuItem
                    sx={{ color: "#4d4d4d", backgroundColor: "#fff" }}
                    value=""
                  >
                    Select a business industry
                  </MenuItem>
                  {category
                    .filter((e, i) => e?.status != "BLOCKED")
                    .map((res, idx) => (
                      <MenuItem value={res.id}>{res.category_name}</MenuItem>
                    ))}
                </Select>
                <FormHelperText style={{ marginLeft: "0px", color: "red" }}>
                  {touched.selectedOption && errors.selectedOption}
                </FormHelperText>
              </FormControl>
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      style={{ color: "#C7340D", padding: "0px, 2px 0px 5px" }}
                      checked={values.termsAndConditions}
                      onChange={handleChange}
                      name="termsAndConditions"
                    />
                  }
                  label={
                    <span
                      style={{
                        fontSize: "15px",
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      I agree to the{" "}
                      <a
                        href="termsandconditions"
                        target="_blank"
                        style={{
                          color: "#C7340D",
                          // color: currentTheme === "DARK" ? "#fff" : "#000",
                          textDecoration: "underline",
                          color: currentTheme == "DARK" ? "#fff" : "#c7340d",
                        }}
                      >
                        Terms & Conditions
                      </a>{" "}
                      and{" "}
                      <a
                        href="privacypolicy"
                        target="_blank"
                        style={{
                          color: "#C7340D",
                          // color: currentTheme === "DARK" ? "#fff" : "#000",
                          textDecoration: "underline",
                          color: currentTheme == "DARK" ? "#fff" : "#c7340d",
                        }}
                      >
                        Privacy Policy
                      </a>
                      .
                    </span>
                  }
                />
                <FormHelperText style={{ marginTop: "-5px", color: "red" }}>
                  {touched.termsAndConditions && errors.termsAndConditions}
                </FormHelperText>
              </Box>

              {/* <FormControl fullWidth>
                <Box>
                  <Checkbox
                    className="fullCheckbox"
                    checked={values.termsAndConditions}
                    onChange={handleChange}
                    id="termsAndConditions"
                    name="termsAndConditions"
                    onBlur={handleBlur}
                    style={{ color: "#C7340D", padding: "0px, 2px 0px 9px" }}
                  />

                  <span variant="body2">
                    I agree to the &nbsp;
                    <span
                      className="termsAndPrivacy"
                      // onClick={() => history.push("/terms-and-conditions")}
                    >
                      Terms & Conditions
                    </span>{" "}
                    and{" "}
                    <span
                      className="termsAndPrivacy"
                      // onClick={() => history.push("/privacy")}
                    >
                      Privacy Policy
                    </span>
                  </span>
                  <FormHelperText style={{ marginLeft: "10px", color: "red" }}>
                    {touched.termsAndConditions && errors.termsAndConditions}
                  </FormHelperText>
                </Box>
              </FormControl> */}
              <Typography
                sx={{
                  textAlign: "left",
                  color: "#d32f2f",
                  fontSize: "14px",
                }}
                fontWeight={"500"}
              >
                {errorMessage}
              </Typography>
            </Grid>
            {/* <Grid item xl={12} xs={12}>
              <InputLabel htmlFor="demo-customized-select-native">
                Age
              </InputLabel>
              <NativeSelect
                id="demo-customized-select-native"
                // value={age}
                // onChange={handleChange}

                sx={{ width: "400px" }}
              >
                <option aria-label="None" value="" />
                <option value={10}>Ten</option>
                <option value={20}>Twenty</option>
                <option value={30}>Thirty</option>
              </NativeSelect>
            </Grid> */}
            <Grid
              item
              mx="auto"
              mt={{ xs: 0, sm: 1 }}
              mb={{ xs: 2, sm: 2 }}
              xl={12}
              md={12}
              sm={12}
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  textTransform: "none",
                  borderRadius: "10px",
                  width: "200px",
                  fontSize: "18px",
                  paddingX: "30px",
                  paddingY: "10px",
                }}
                variant="contained"
                type="submit"
                onClick={handleSubmit}
                disabled={loading}
              >
                Sign Up
                {loading && (
                  <CircularProgress
                    size={20} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
    </LoginWrapper>
  );
};

export default SignUp;
