import React, { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BlockIcon from "@mui/icons-material/Block";
import {
  Avatar,
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import PhoneInput from "react-phone-input-2";
import ChangePasswordOtpModal from "./ChangePasswordOtpModal";
import axios from "axios";
import { ApiConfig } from "../../services/ApiConfig";
import PasswordResetModal from "../PasswordResetModal";
import { CleaningServices } from "@mui/icons-material";
import { toast } from "react-toastify";

const PasswordEmailChangeModal = ({
  open,
  setOpen,
  funcToExecute,
  message,
  type,
  currentData,
  currentType,
  currentStatus,
}) => {
  const formikInnerRef = useRef();
  const [otpModalStatus, setotpModalStatus] = useState(false);
  // const sentOtp = async () => {
  //   const res = await axios({
  //     url: ApiConfig.resentOtp,
  //     data: {
  //       email: currentData,
  //     },
  //   });
  // };
  const emailValidationSchema = Yup.object({
    email: Yup.string()
      .required("Email is required.")
      .email("Enter valid email.")
      .test(
        "no-old-password",
        "New email should not match the old email.",
        function (value) {
          return value !== currentData;
        }
      ),
  });

  const mobileValidationSchema = Yup.object({
    mobile: Yup.string().required("Phone number is required."),
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [isModalOpen, setModalOpen] = useState(false);
  const [smsModalOpen, setSmsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState();

  const [newEmail, setNewEmail] = useState("");
  const [newSms, setNewSms] = useState("");
  const [minutes, setMinutes] = useState(
    sessionStorage.getItem("minutes")
      ? parseInt(sessionStorage.getItem("minutes"))
      : 2
  );
  const [seconds, setSeconds] = useState(
    sessionStorage.getItem("seconds")
      ? parseInt(sessionStorage.getItem("seconds"))
      : 0
  );
  console.log(currentStatus);

  const handlePasswordResetSuccess = () => {
    // Handle successful password reset logic
    setModalOpen(true);
  };

  const handleNewSmsSuccess = () => {
    // Handle successful password reset logic
    setSmsModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const reSendOtp = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.resentOtp,
        data: {
          email: sessionStorage.getItem("newEmail"),
          user_id: sessionStorage.getItem("userId"),
        },
      });
      console.log(res);
      if (res?.data?.statusCode == 200) {
        type == "email" ? handlePasswordResetSuccess() : handleNewSmsSuccess();
        setMinutes(2);
        setSeconds(0);

        setLoading(false);

        // navigate("/dashboard");
      } else {
        setLoading(false);
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
      setLoading(false);
    }
  };
  const reSendOtp2 = async () => {
    setLoading(true);
    try {
      const res = await axios({
        method: "POST",
        url: ApiConfig.resentOtp,
        data: {
          mobile: sessionStorage.getItem("newSms"),
          user_id: sessionStorage.getItem("userId"),
        },
      });
      console.log(res);
      if (res?.data?.statusCode == 200) {
        type == "email" ? handlePasswordResetSuccess() : handleNewSmsSuccess();
        setMinutes(2);
        setSeconds(0);

        setLoading(false);

        // navigate("/dashboard");
      } else {
        setLoading(false);
        setErrorMessage(res.data?.responseMessage || "Something went wrong", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return null;
      }
    } catch (error) {
      setErrorMessage(
        error?.response?.data?.responseMessage || "Something went wrong"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("newEmail", newEmail);
  }, [newEmail]);
  useEffect(() => {
    sessionStorage.setItem("newSms", newSms);
  }, [newSms]);
  return (
    <React.Fragment>
      <Dialog
        open={open}
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            paddingTop: "30px",
            height: "430px",
            width: { sm: "600px", xs: "300px" },
            borderRadius: "20px",
            position: "relative",
          },
        }}
      >
        <Box
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: "15px",
            left: { sm: "565px", xs: "290px" },
            cursor: "pointer",
          }}
          src="/images/xx.png"
          component="img"
        />
        <DialogTitle variant="h5">{message}</DialogTitle>
        <Typography
          sx={{
            textAlign: "center",
            color: "#d32f2f",
            fontSize: "15px",
          }}
          fontWeight={"500"}
          mt={2}
        >
          {errorMessage}
        </Typography>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: { sm: "400px", xs: "auto" },
            // height: { xs: "300px" },
          }}
        >
          <Formik
            innerRef={formikInnerRef}
            validationSchema={
              type == "mobile" ? mobileValidationSchema : emailValidationSchema
            }
            initialValues={
              type == "mobile"
                ? { mobile: currentData ? currentData : "" }
                : { email: currentData ? currentData : "" }
            }
            onSubmit={async () => {
              currentStatus === "mobile"
                ? await reSendOtp2()
                : await reSendOtp();
              // type == "email"
              //   ? handlePasswordResetSuccess()
              //   : handleNewSmsSuccess();
            }}
          >
            {({
              errors,
              touched,
              handleBlur,
              handleChange,
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form
                onSubmit={handleSubmit}
                style={{ height: "100%", width: "100%" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  {currentStatus == "mobile" ? (
                    <FormControl sx={{ width: "100%" }}>
                      <label style={{ textAlign: "left", marginBottom: "3px" }}>
                        Mobile Number
                      </label>

                      <PhoneInput
                        dropdownStyle={{
                          zIndex: "99",
                          position: "absolute",
                          top: "0px",
                        }}
                        country={"us"}
                        className="phoneInput"
                        value={values.mobile}
                        onChange={(mobileNumber, cc) => {
                          setFieldValue("mobile", mobileNumber);
                          setNewSms(mobileNumber);
                        }}
                        onBlur={handleBlur}
                        placeholder="Enter your mobile number"
                        inputClass="form-control"
                        inputProps={{
                          name: "mobileNumber",
                          required: true,
                        }}
                      />
                      {touched.mobile && errors.mobile && (
                        <FormHelperText
                          sx={{
                            color: "#d32f2f",
                            marginLeft: "0px !important",
                          }}
                        >
                          {errors.mobile}
                        </FormHelperText>
                      )}
                    </FormControl>
                  ) : (
                    <FormControl sx={{ width: "100%" }}>
                      <label>Email</label>
                      <TextField
                        variant="outlined"
                        name="email"
                        placeholder="Please enter your email."
                        value={values.email}
                        inputProps={{
                          maxLength: 60,
                          style: {
                            height: "20px",
                          },
                        }}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: "0px",
                          },
                        }}
                        type="email"
                        onChange={handleChange}
                        onInput={(e) => {
                          console.log(e.target.value);
                          setNewEmail(e.target.value);
                          setErrorMessage("");
                        }}
                        onBlur={handleBlur}
                        error={touched.email && Boolean(errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </FormControl>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: { sm: "35px", xs: "10px" },
            mb: "20px",
          }}
        >
          <Grid container item xs={5}>
            <Button
              onClick={handleClose}
              fullWidth
              sx={{
                marginX: "auto",
                marginBottom: "40px",
                width: "160px",
                fontSize: "18px",
                border: "0.747px solid var(--Linear, #E46703)",
                color: "black",
                textTransform: "none",
              }}
              variant="outlined"
              disabled={loading}
            >
              Close
            </Button>
          </Grid>
          <Grid container item xs={5}>
            <Button
              type="submit"
              variant="contained"
              fullWidth
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                marginX: "auto",
                marginBottom: "40px",
                width: "160px",
                fontSize: "18px",
                textTransform: "none",
              }}
              onClick={() => {
                formikInnerRef?.current?.submitForm();
              }}
              disabled={loading}
            >
              Continue
              {loading && (
                <CircularProgress
                  size={16} // Set the size of CircularProgress
                  sx={{
                    marginLeft: "10px", // Half of the size for horizontal centering
                    color: "white", // Set the color of CircularProgress
                  }}
                />
              )}
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <PasswordResetModal
        resendOtp={reSendOtp}
        type="updateEmail"
        open={isModalOpen}
        onClose={handleCloseModal}
      />
      <PasswordResetModal
        resendOtp={reSendOtp}
        type="updateSms"
        open={smsModalOpen}
        onClose={handleCloseModal}
      />
      {/* <ChangePasswordOtpModal
        data={currentData}
        open={otpModalStatus}
        setOpen={setotpModalStatus}
      />*/}
    </React.Fragment>
  );
};

export default PasswordEmailChangeModal;
