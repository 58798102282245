import React, { useEffect, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { Typography } from "@mui/material/";
import { Box, Grid } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import axios from "axios";
import { useSelector } from "react-redux";

export default function Faq() {
  const [expanded, setExpanded] = React.useState(0);
  const [faq, setFaq] = useState([]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  // useEffect(() => {
  //   // Scroll to the top of the page when the component is loaded
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // }, []);
  const getFaq = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getAllFAQ,
      });
      if (res?.data?.statusCode === 200) {
        setFaq(res?.data?.result);
        console.log("faq", res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getFaq();
  }, []);
  return (
    <Box
      sx={{
        paddingX: { md: "144px", xs: "30px" },
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
      }}
      py={4}
    >
      <Typography
        sx={{
          fontSize: "45px",
          fontWeight: "600",
          color: currentTheme == "DARK" ? "#fff" : "#000",
        }}
      >
        Frequently Asked Question
      </Typography>
      <Box my={3}>
        {faq.map((item, i) => {
          return (
            <Accordion
              expanded={expanded === i}
              onChange={handleChange(i)}
              sx={{
                marginY: "20px",
                borderRadius: "8px",
                boxShadow: "none",
              
              }}
              classes={{
                root: "custom-accordion-root",
                expanded: "custom-accordion-expanded",
              }}
            >
              <AccordionSummary
                expandIcon={
                  expanded === i ? (
                    <Remove style={{ color: "#fff", fontWeight: "600" }} />
                  ) : (
                    <Add />
                  )
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  background:
                    expanded === i
                      ? "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)"
                      : "#fff",
                  borderRadius: "8px",
                  color: "#202123",
                  padding: "5px 20px",
                  borderRadius: expanded === i ? "8px 8px 0 0" : "8px",
                  color: expanded === i ? "#fff" : "#202123",
                  border:
                    expanded === i ? "none" : "1px solid rgba(0, 0, 0, 0.25)",
                    
                }}
              >
                <Typography>
                  {item?.question}
                  {/* How Lorem ipsum dolor sit amet, consectetur adipiscing? */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  borderBottom: "1px solid #E46703",
                  borderLeft: "1px solid #E46703",
                  borderRight: "1px solid #E46703",
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <Typography>
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing Lorem
                    ipsum dolor sit amet, consectetur adipiscing Lorem ipsum
                    dolor sit amet, consectetur adipiscing Lorem ipsum dolor sit
                    amet, consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit amet,
                    consectetur adipiscing Lorem ipsum dolor sit */}
                  {item?.answer}
                </Typography>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </Box>
    </Box>
  );
}
