import React from "react";
import "./Footer.scss";
import { Instagram, Language, Telegram, Twitter } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Container } from "@mui/system";
import { Box } from "@mui/material";
import styled from "@emotion/styled";

const FooterStyled = styled("Box")(({ theme }) => ({
  "& .bottomStrip": {
    height: "100%",
    background: "#523168",
    padding: "24px 0px",
    "& .footerBg": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      cursor: "pointer",
      [theme.breakpoints.down("md")]: {
        flexWrap: "wrap",
        justifyContent: "center",
        padding: "10px 0px",
      },
      "& span": {
        color: "#fff",
        fontSize: "14px",
        fontWeight: "400",
        [theme.breakpoints.down("sm")]: {
          fontSize: "12px",
        },
      },
      "& .sideText": {
        display: "flex",
        gap: "40px",
        "& span": {
          color: "rgba(255, 255, 255, 0.75)",
        },
        [theme.breakpoints.down("md")]: {
          display: "flex",
          // gap: "10px",
          columnGap: "15px",
          flexWrap: "wrap",
        },
        [theme.breakpoints.down("sm")]: {
          gap: "5px !important",
          justifyContent: "center",
        },
      },
    },
  },
}));

function Footer() {
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  return (
    <FooterStyled>
      <div
        style={{
          backgroundColor: currentTheme == "DARK" ? "#111111" : "#FAF7FC",
        }}
        className="footerContainer"
      >
        <Container maxWidth="lg">
          <div className="footerInfo">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "60px",
              }}
              className="imgContainer"
            >
              <img
                alt="logo"
                src={
                  currentTheme === "DARK"
                    ? "/images/boostlogo2.png"
                    : "/images/boostlogo2.png"
                }
                className="companyImg"
              />
            </div>
            <div className="info1">
              <div className="inner1">
                <div className="icons">
                  <a href="https://web.telegram.org/" target="blank">
                    <span>
                      <Telegram />
                    </span>
                  </a>
                  <a href="/">
                    <span>
                      <Language />
                    </span>
                  </a>
                  <a href="https://twitter.com/" target="blank">
                    <span>
                      <Twitter />
                    </span>
                  </a>
                  <a href="https://www.instagram.com/" target="blank">
                    <span>
                      <Instagram />
                    </span>
                  </a>
                </div>
              </div>
              <div className="inner2">
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => {
                    sessionStorage.getItem("userToken")
                      ? navigate("/dashboard")
                      : navigate("/");
                  }}
                >
                  Home
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/aboutUs")}
                >
                  About Us
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/")}
                >
                  Learning & Lives
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/")}
                >
                  Monitor & Measure
                </span>
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(38, 38, 38, 0.87)",
                  }}
                  onClick={() => navigate("/contactUs")}
                >
                  Contact Us
                </span>
              </div>
            </div>

            <div className="contactUS">
              <p
                style={{
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              >
                Contact Us
              </p>
              <span
                style={{
                  color:
                    currentTheme == "DARK"
                      ? "#a3a3a3"
                      : "rgba(38, 38, 38, 0.87)",
                }}
              >
                BOOST STRATEGY GROUP LLC
              </span>
              <span
                style={{
                  color:
                    currentTheme == "DARK"
                      ? "#a3a3a3"
                      : "rgba(38, 38, 38, 0.87)",
                }}
              >
                Atlanta GA 30326
              </span>
              <span
                style={{
                  color:
                    currentTheme == "DARK"
                      ? "#a3a3a3"
                      : "rgba(38, 38, 38, 0.87)",
                }}
              >
                3979 Peachtree Rd Ste 700
              </span>
              <span
                style={{
                  color:
                    currentTheme == "DARK"
                      ? "#a3a3a3"
                      : "rgba(38, 38, 38, 0.87)",
                }}
              >
                contact@businessboostsociety.com
              </span>
              <span
                style={{
                  color:
                    currentTheme == "DARK"
                      ? "#a3a3a3"
                      : "rgba(38, 38, 38, 0.87)",
                }}
              >
                Tel: +254 797 771771
              </span>
            </div>
          </div>
        </Container>
        <Box className="bottomStrip">
          <Container maxWidth="lg">
            <Box className="footerBg">
              <span>© 2024 Give Me a Boost | BOOST STRATEGY GROUP LLC</span>
              <Box className="sideText">
                <span onClick={() => navigate("/termsandconditions")}>
                  Terms & Conditions
                </span>
                <span onClick={() => navigate("/privacypolicy")}>
                  Privacy Policy
                </span>
                <span onClick={() => navigate("/cookiespolicy")}>
                  Cookies Policies
                </span>
                <span onClick={() => navigate("/faq")}>FAQs</span>
                <span onClick={() => navigate("/contactus")}>Contact Us</span>
              </Box>
            </Box>
          </Container>
        </Box>
      </div>
    </FooterStyled>
  );
}

export default Footer;
