import React, { useState } from "react";
import "./common.scss";

import { useSelector } from "react-redux";
export default function CheckOption({
  formField,
  error,
  key,
  setAnswersDetails,
  answersDetails,
  handleUpdateForm,
}) {
  const [answerId, setAnswerId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const handleChange = (e, item) => {
    if (answersDetails[formField?.id]?.answer_id) {
      setAnswerId(answersDetails[formField?.id]?.answer_id);
    }
    const version = parseInt(sessionStorage.getItem("version"));
    const isChecked = e.target.checked;

    const currentAnswer = answersDetails[formField.id]?.answer || "";

    let updatedAnswer;

    if (isChecked) {
      // If the checkbox is checked, add the new option to the current answer
      updatedAnswer = currentAnswer ? `${currentAnswer};${item}` : item;
    } else {
      // If the checkbox is unchecked, remove the option from the current answer
      const itemsArray = currentAnswer.split(";");
      updatedAnswer = itemsArray.filter((answer) => answer !== item).join(";");
    }

    setAnswersDetails({
      ...answersDetails,
      [formField.id]: {
        question_id: formField.id,
        assesment_id: formField.assesment_master_id,
        assesment_category_id: formField.assesment_category_,
        category_id: formField?.category_id,

        answer: updatedAnswer,
        version_control: version || formField.version_control,
      },
    });
  };

  const handleUpdateChange = () => {
    if (answerId) {
      const data = {
        business_owner_id: sessionStorage.getItem("userId"),
        answer_id: answerId,
        answer: answersDetails[formField.id]?.answer,
      };
      handleUpdateForm(data);
    }
  };

  return (
    <div className="checkboxAreaContainer" key={key}>
      {formField.question && (
        <p
          className="heading"
          style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
        >
          {formField.question}
        </p>
      )}
      <div>
        {formField.answer.length > 0 &&
          formField.answer.map((item, i) => (
            <div
              style={{ margin: "15px 0" }}
              className="checkboxWrapper"
              key={i}
            >
              <input
                type="checkbox"
                id={i}
                name={formField.id}
                onChange={(e) => handleChange(e, item)}
                onBlur={handleUpdateChange}
                checked={
                  answersDetails[formField.id]?.answer !== undefined &&
                  answersDetails[formField.id]?.answer.split(";").includes(item)
                }
              />
              <label
                htmlFor={i}
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              >
                {item}
              </label>
            </div>
          ))}
      </div>
      <span>{error}</span>
    </div>
  );
}
