import React from "react";
import "./stepper.scss";
import { useSelector } from "react-redux";

export default function Stepper({ data, activeStep }) {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  console.log(data, activeStep);
  return (
    <div className="stepperCotainer">
      {data &&
        data.map((item, i) => {
          return (
            <div className="stepperWrapper">
              <div
                className="circle"
                style={{
                  backgroundColor:
                    item.step <= activeStep ? "#E46703" : "#523168",
                  height: item.step === activeStep && "20px",
                  width: item.step === activeStep && "20px",
                }}
              >
                <span
                  style={{
                    color:
                      currentTheme == "DARK"
                        ? "#a3a3a3"
                        : "rgba(22, 30, 41, 0.75)",
                    fontSize: item.step <= activeStep ? "16px" : undefined,
                    ...(item.step <= activeStep && { color: "#E46703" }),
                  }}
                >
                  {item?.title}
                </span>
              </div>
              <span
                className="line"
                style={{
                  display: i === data.length - 1 && "none",
                  borderColor:
                    item.step <= activeStep - 1 ? "#E46703" : "#523168",
                }}
              ></span>
            </div>
          );
        })}
    </div>
  );
}
