import React from "react";
import "./assessment.scss";

export default function QuizPercentage({ type, percentage }) {
  console.log("percentage==>>", percentage);

  return (
    <div className="assessmentPercentage">
      <div>
        {type == "quiz" ? (
          <h2>{percentage}% of this quiz has been completed.</h2>
        ) : (
          <h2>{percentage}% of this module has been completed.</h2>
        )}

        <div>
          <span style={{ width: `${Number(percentage).toFixed(1)}%` }}></span>
        </div>
      </div>
    </div>
  );
}
