import { useEffect, useRef, useState } from "react";
import AssessMentPercentage from "../../../widgets/assessmentPersentage/AssessMentPercentage";
import "./fundersList.scss";
import {
  Bookmark,
  BookmarkBorderOutlined,
  LocationOn,
} from "@mui/icons-material";
import usePagination from "@mui/material/usePagination";
import Pagination from "../../../components/common/Pagination";
import { apiWithToken } from "../../../services/apiWithToken/apiWithToken";
import { ApiConfig } from "../../../services/ApiConfig";
import { toast } from "react-toastify";
import {
  CircularProgress,
  Grid,
  Typography,
  Button,
  Tooltip,
  Box,
} from "@mui/material";
import axios, { Axios } from "axios";
import { FaLocationDot } from "react-icons/fa6";
import { v4 as uuidv4 } from "uuid";
import customAxios from "../../../utils/customAxios";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

export default function FundersList() {
  const [currentOption, setCurrentOption] = useState("saved");
  const [funderListing, setFunderListing] = useState([]);
  const [showSave, setShowSave] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [dataKeys, setDataKeys] = useState([]);
  const [dataEnteries, setDataEnteries] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [funderListData, setFunderListData] = useState([]);
  const [savedFunderList, setSavedFunderList] = useState([]);
  const [isFunderDataLoading, setIsFunderDataLoading] = useState(false);
  const [isSavedFunderListLoading, setIsSavedFunderListLoading] =
    useState(false);
  const [currentFunderId, setCurrentFunderId] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [isFunderSavedLoading, setIsFunderSavedLoading] = useState(false);
  const cancelTokenSourceRef = useRef();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [filter, setFilter] = useState({
    page: "1",
    limit: "10",
  });
  const location = useLocation();
  // const { type } = state;
  const navigate = useNavigate();

  const normalizeKey = (key) => {
    return key.replace(/\s+/g, "").toLowerCase();
  };

  const normalizeArrayOfObjects = (array) => {
    return array.map((obj) => {
      const normalizedObject = {};

      for (const key in obj) {
        const normalizedKey = normalizeKey(key);
        normalizedObject[normalizedKey] = obj[key];
      }

      return normalizedObject;
    });
  };

  const handleFundersList = async () => {
    setIsFunderDataLoading(true);

    try {
      setIsFunderDataLoading(true);

      const cancelTokenSource = axios.CancelToken.source();
      cancelTokenSourceRef.current = cancelTokenSource;

      if (isPending) {
        if (cancelTokenSourceRef.current) {
          setIsFunderDataLoading(true);
          cancelTokenSourceRef.current.cancel("Request canceled");
        }
      }

      setIsPending(true);
      const res = await customAxios({
        method: "GET",
        cancelToken: cancelTokenSource.token,
        url: ApiConfig.getAnalysisforUser,

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      setIsPending(false);
      setIsFunderDataLoading(false);

      if (res?.data?.statusCode === 200) {
        console.log(res);
        setTotalPages(res.data?.result?.totalPages || 1);
        setFunderListData(
          // funders
          normalizeArrayOfObjects(
            (
              res.data?.result?.funders_list ||
              res.data?.result?.funders ||
              res.data?.result?.fundersList
            ).map((e) => {
              return {
                ...e,
                ...{ id: uuidv4(), isSaved: false },
              };
            })
          )
        );
      }
    } catch (error) {
      console.log(error);
      setIsFunderDataLoading(false);
    }
  };
  useEffect(() => {
    if (location.state.type === "result") {
      setCurrentOption("result");
    }
  }, [location.state.type]);

  useEffect(() => {
    console.log(funderListData, "asbfafja");
  }, [funderListData]);

  const getSavedFunders = async () => {
    const options = {
      url: ApiConfig.getSavedFunderList,
      method: "GET",
      params: {
        userid: sessionStorage.getItem("userId"),
      },
    };
    setIsSavedFunderListLoading(true);
    const res = await apiWithToken(options);
    setIsSavedFunderListLoading(false);
    if (res?.data?.statusCode === 200) {
      if (res.data?.result?.totalPages === "1") {
        setFilter({ ...filter, page: "0" });
      }
      setShowSave(false);
      setSavedFunderList(
        res.data?.result?.map((e) => {
          return {
            ...e,
            ...{ isSaved: true },
          };
        })
      );
    }
  };
  const handleSaveFunder = async (data) => {
    try {
      setIsFunderSavedLoading(true);
      setCurrentFunderId(data?.id);
      const options = {
        url: ApiConfig.saveFunder,
        method: "POST",
        data: {
          userid: sessionStorage.getItem("userId"),
          companyname: data?.company_name ? data?.company_name : null,
          fundingamount: data?.funding_amount
            ? String(data?.funding_amount)
            : null,
          website: data?.website ? data?.website : null,
          shortdescription: data?.short_description
            ? data?.short_description
            : null,
          applicationdeadline: data?.application_deadline
            ? data?.application_deadline
            : null,
          eligibilitycriteria: data?.eligibility_criteria
            ? data?.eligibility_criteria
            : null,
          fundercategory: data?.funder_category ? data?.funder_category : null,
          address: data?.address ? data?.address : null,
        },
      };
      const res = await apiWithToken(options);
      setIsFunderSavedLoading(false);
      if (!res) {
        toast.error("Funder already saved.");
      }
      if (res?.data?.statusCode === 200) {
        setFunderListData(
          funderListData?.map((e) => {
            if (e?.id == data?.id) {
              return { ...e, isSaved: true };
            }
            return e;
          })
        );
      }
    } catch (error) {
      console.log(error, "asbfabfdadfs");
    }
  };

  useEffect(() => {
    setFunderListing([]);
    if (currentOption === "saved") {
      getSavedFunders();
    } else {
      if (funderListData?.length == 0) {
        handleFundersList();
      }
    }
  }, [currentOption]);

  const handleCancelGetNewFunderList = () => {
    // source.cancel();
    handleFundersList();
  };

  const removeSaved = async (data) => {
    try {
      // setIsSavedFunderListLoading(true);
      setCurrentFunderId(data?.id);
      setIsFunderSavedLoading(true);
      const res = await axios({
        method: "PUT",
        url: ApiConfig.removeSavedFunders,
        data: {
          id: data?.id,
        },
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      // setIsSavedFunderListLoading(false);
      setIsFunderSavedLoading(false);
      if (res?.data?.statusCode == 200) {
        getSavedFunders();
      }
    } catch (error) {
      console.log(error, "RemoveFunderSaved");
      // setIsSavedFunderListLoading(false);
      setIsFunderSavedLoading(false);
    }
  };

  return (
    <div
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
      className="fundersListContainer"
    >
      <div className="introSection">
        <h1>Capital Boost & Funding Finder</h1>
        <p>Welcome to the new and advance Funding Finder! </p>
      </div>
      <div
        style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
        className="listWrapper"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexDirection: { xs: "column", lg: "row" },
          }}
        >
          {" "}
          <div className="optionWrapper">
            <div
              style={{
                background: currentOption === "result" && "#523168",
                color: currentOption === "result" && "#fff",
              }}
              onClick={() => setCurrentOption("result")}
            >
              Search Result
            </div>
            <div
              style={{
                background: currentOption === "saved" && "#523168",
                color: currentOption === "saved" && "#fff",
              }}
              onClick={() => setCurrentOption("saved")}
            >
              Saved
            </div>
          </div>
          <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              sx={{
                background:
                  "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                borderRadius: "10px",
                fontSize: "18px",
                paddingX: "40px",
                paddingY: "10px",

                textTransform: "none",
              }}
              // disabled={loading}
              variant="contained"
              type="submit"
              onClick={() => navigate("/findingFunders/fundersAssessment")}
            >
              Update Search Criteria
              {/* {loading && (
                <CircularProgress
                  size={20}
                  sx={{
                    marginLeft: "10px",
                    color: "white",
                  }}
                />
              )} */}
              {/* {loading && (
                      <CircularProgress
                        size={20} // Set the size of CircularProgress
                        sx={{
                          marginLeft: "10px", // Half of the size for horizontal centering
                          color: "white", // Set the color of CircularProgress
                        }}
                      />
                    )} */}
            </Button>
          </Box>
        </Box>

        <div className="resourceHeading">
          <h2 style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}>
            Funding Resources For You
          </h2>
          {currentOption !== "saved" && (
            <Button
              variant="contained"
              disabled={isFunderDataLoading || isPending}
              sx={{
                height: 40,
                textTransform: "none",
              }}
              style={{
                backgroundColor: "#ca3a0c",
              }}
              onClick={() => {
                handleCancelGetNewFunderList();
              }}
            >
              Refresh
            </Button>
          )}
        </div>
        <Box
          className=" fundersList scrollBarCustomColor"
          component={OverlayScrollbarsComponent}
        >
          {currentOption === "saved"
            ? List(
                currentOption,
                currentFunderId,
                isFunderSavedLoading,
                savedFunderList,
                handleSaveFunder,
                removeSaved,
                isSavedFunderListLoading,
                false
              )
            : List(
                currentOption,
                currentFunderId,

                isFunderSavedLoading,
                funderListData,
                handleSaveFunder,
                removeSaved,
                isFunderDataLoading || isPending,
                true
              )}
        </Box>
        {totalPages > 1 && (
          <div style={{ margin: "30px 0 0 auto", width: "fit-content" }}>
            <Pagination />
          </div>
        )}
      </div>
    </div>
  );
}

const List = (
  currentOption,
  currentFunderId,
  isFunderSavedLoading,
  funderData,
  handleSaveFunder,
  removeSaved,
  isDataLoading,
  isFunderList,
  funderListData
) => {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  console.log(isDataLoading, "lasbfkabfa");
  if (isDataLoading) {
    if (isFunderList) {
      return (
        <Grid
          container
          item
          xs={12}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          minHeight={100}
          overflow={"hidden"}
        >
          <CircularProgress sx={{ color: "#cb3c0c" }} />
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            variant="body2"
            mt={2}
            sx={{ opacity: "0.3" }}
          >
            Retrieving funders list from AI assistance. Please wait...
          </Typography>
        </Grid>
      );
    } else {
      if (isFunderList?.length < 0) {
        return (
          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Typography variant="h6"> No saved funder found </Typography>
          </Grid>
        );
      } else {
        return (
          <Grid
            container
            item
            xs={12}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress sx={{ color: "#cb3c0c" }} />
          </Grid>
        );
      }
    }
  }
  //  else if (!isDataLoading) {
  //   return (
  //     <h3
  //       style={{
  //         color: currentTheme == "DARK" ? "#fff" : "#000",
  //       }}
  //     >
  //       No results found based on this criteria. Please update your search
  //       Criteria.
  //     </h3>
  //   );
  // }

  return (
    <>
      <Box>
        {funderListData?.length == 0 && (
          <h3
            style={{
              color: currentTheme == "DARK" ? "#fff" : "#000",
            }}
          >
            No results found based on this criteria. Please update your search
            Criteria.
          </h3>
        )}
        {funderData?.length == 0 && (
          <h3
            style={{
              color: currentTheme == "DARK" ? "#fff" : "#000",
              display: "flex",
              justifyContent: "center",
            }}
          >
            No funders found. Please update your search criteria.
          </h3>
        )}{" "}
        {funderData &&
          funderData?.map((data, i) => (
            <Box
              sx={{
                backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
                my: 0.5,
              }}
              className="funder scrollBarCustomColor"
              component={OverlayScrollbarsComponent}
              key={i}
            >
              <div className="funderDetails   ">
                <div className="companyName">
                  <h2
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    {data?.company_name ? data?.company_name : "NA"}
                  </h2>
                </div>
                <div className="companyLocation">
                  <span>
                    <FaLocationDot
                      color={currentTheme == "DARK" ? "#fff" : "#202123"}
                    />
                  </span>{" "}
                  <span
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    {data?.address ? data?.address : "NA"}
                  </span>
                </div>
                <div
                  style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  className="applicationDetails"
                >
                  {(data?.application_deadline
                    ? data?.application_deadline
                    : "NA") && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "30px",
                      }}
                    >
                      <div className="prize">
                        <p
                          style={{
                            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                          }}
                        >
                          Application Deadline:
                        </p>
                        <h3
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          {data?.application_deadline
                            ? data?.application_deadline
                            : "NA"}
                        </h3>
                      </div>
                      <div className="prize">
                        <p
                          style={{
                            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                          }}
                        >
                          Prize Amount or Benefit:
                        </p>
                        <h3
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          {data?.funding_amount ? data?.funding_amount : "NA"}
                        </h3>
                      </div>
                      <div className="prize">
                        <p
                          style={{
                            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                          }}
                        >
                          Funder category:
                        </p>
                        <h3
                          style={{
                            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                          }}
                        >
                          {data?.funder_category ? data?.funder_category : "NA"}
                        </h3>
                      </div>
                    </div>
                  )}

                  {/* <div className="prize">
                <p>Prize Amount or Benefit:</p>
                <h3>{data?.fundingamount ? data?.fundingamount : "NA"}</h3>
              </div> */}
                </div>
                <div className="eligibility">
                  <div>
                    <p
                      style={{
                        color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                      }}
                    >
                      Eligibility Criteria:
                    </p>

                    <h4
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {data?.eligibility_criteria
                        ? data?.eligibility_criteria?.slice(0, 100)
                        : "NA"}
                    </h4>
                  </div>
                </div>
                {data?.short_description && (
                  <div className="eligibility">
                    <div>
                      <p
                        style={{
                          color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                        }}
                      >
                        Description:
                      </p>

                      <h4
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {data?.short_description
                          ? data?.short_description?.slice(0, 100)
                          : "NA"}
                      </h4>
                    </div>
                    <div>
                      <button
                        onClick={() => {
                          const websiteUrl = data?.website;

                          if (websiteUrl) {
                            const fullUrl = websiteUrl.startsWith(
                              "https" || "http"
                            )
                              ? websiteUrl
                              : `https://${websiteUrl}`;
                            window.open(fullUrl, "_blank");
                            console.log(fullUrl);
                          }
                        }}
                      >
                        Visit Website
                      </button>
                    </div>
                  </div>
                )}

                <Tooltip title={currentOption == "saved" ? "remove" : "save"}>
                  <span
                    className="bookmark"
                    onClick={() => {
                      if (currentOption === "saved") {
                        removeSaved(data);
                      } else {
                        // data?.isSaved ? removeSaved(data) : handleSaveFunder(data);
                        if (data?.isSaved) {
                          toast.error("Funder is already saved");
                        } else {
                          handleSaveFunder(data);
                        }
                      }
                    }}
                  >
                    {/* isFunderSavedLoading && currentFunderId == data?.id */}
                    {isFunderSavedLoading && currentFunderId == data?.id ? (
                      <CircularProgress size="1rem" sx={{ color: "#cb3b0c" }} />
                    ) : (
                      <Bookmark
                        style={{
                          fontSize: "18px",
                          // color: (!isFunderList || data?.isSaved) && "#cb3c0c",
                          color: data?.isSaved && "#cb3c0c",
                        }}
                      />
                    )}
                  </span>
                </Tooltip>
              </div>
            </Box>
          ))}
      </Box>
    </>
  );
};
