import React, { useEffect, useState } from "react";
import "./learningLives.scss";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import MyCourse from "./MyCourse";
import AvailableCourse from "./AvailableCourse";
import { styled } from "@mui/system";
import { useSelector } from "react-redux";

const MainComponent = styled("Box")(({ theme }) => ({
  "& .learningLivesBox": {
    [theme.breakpoints.down("sm")]: {
      padding: "0px 40px 0px",
    },
  },
}));

export default function LearningLives() {
  const [currentOption, setCurrentOption] = useState(true);
  const navigate = useNavigate();
  const [currentTab, setCurrentTab] = useState(1);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const location = useLocation();

  console.log(location?.state?.data);

  useEffect(() => {
    if (location?.state?.data == "myCourses") {
      setCurrentTab(0);
    }
  }, []);
  return (
    <Container maxWidth={"lg"}>
      {" "}
      <MainComponent
        style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
      >
        <Box
          className="learningLivesBox"
          style={{
            backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff",
          }}
        >
          <div className="learningLivesContainer">
            <div className="courseList">
              <div className="header">
                <Typography
                  variant="h4"
                  fontSize="45px"
                  fontWeight={"600"}
                  mt={4}
                  style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                >
                  Learning & Lives
                </Typography>
                <Typography
                  fontSize="25px"
                  variant="h6"
                  mt={2}
                  style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                >
                  Join & Grow
                </Typography>
                <div className="optionWrapper">
                  <div
                    style={{
                      background: currentTab == 0 && "#fff",
                      color: currentTab == 0 && "#000",
                    }}
                    onClick={() => setCurrentTab(0)}
                  >
                    My Course
                  </div>
                  <div
                    style={{
                      background: currentTab == 1 && "#fff",
                      color: currentTab == 1 && "#000",
                    }}
                    onClick={() => setCurrentTab(1)}
                  >
                    All Available Courses
                  </div>
                </div>
                {/* <hr  */}
                <Divider
                  style={{ margin: "40px 0px 20px", color: "#00000033" }}
                />{" "}
              </div>
            </div>
            {currentTab == 0 && <MyCourse />}
            {currentTab == 1 && <AvailableCourse />}
          </div>
        </Box>
      </MainComponent>
    </Container>
  );
}
