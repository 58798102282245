import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import { FiSearch } from "react-icons/fi";
import { height, lineHeight, styled } from "@mui/system";

const MainComponent = styled("Box")(({ theme }) => ({
  "& .AvailableCourseBox": {
    "& .mainImageBox": {
      "& img": {
        width: "100%",
        height: "250px",
        position: "relative",
        top: "0px",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        objectFit: "fill",
        borderRadius: "20px 20px 0px 0px",
      },
    },
    "& .mainCardBox": {
      padding: "20px",
      borderRadius: "20px",
      backgroundColor: "#523168",
      height: "100%",
    },
    "& h5": {
      color: "#FFFFFF",
      fontWeight: 500,
      color: "#FFFFFF",
      fontWeight: 500,
      fontSize: "25px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
      },
    },
    "& .description": {
      wordBreak: "break-all",
      color: "#FFFFFFBF",
      fontWeight: 400,
      height: "70px",
      lineHeight: "25px",
      fontSize: "16px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "12px",
      },
    },
  },
}));
const AvailableCourse = () => {
  const navigate = useNavigate();
  const [availableCourseList, setAvailableCourseList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [joinLoading, setJoinLoading] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [currentId, setCurrentId] = useState("");
  const [filter, setFilter] = useState({
    page: 1,
    limit: 10,
    name: "",
  });
  useEffect(() => {
    getCourseList();
  }, [filter]);

  const getCourseList = async () => {
    try {
      const res = await customAxios({
        url: ApiConfig.findAllCourse,
        method: "GET",
        params: Object.fromEntries(
          Object.entries(filter).filter(([_, v]) => v != "")
        ),

        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      setIsLoading(false);
      if (!res?.data) {
        return;
      }
      setAvailableCourseList(res?.data?.result?.data || []);
      console.log(res?.data?.result?.data);
      setTotalPages(res?.data?.result?.totalPages || 1);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MainComponent>
      <Box className="AvailableCourseBox">
        <Box className="displayEnd" mb={2}>
          <TextField
            style={{
              backgroundColor: currentTheme == "DARK" ? "#fff" : "#fff",
            }}
            value={filter.name}
            placeholder="Search by course category name"
            onChange={(e) => {
              setFilter({ ...filter, name: e?.target?.value });
            }}
            sx={{
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "8px",
              },
            }}
            inputProps={{
              style: {
                width: "290px",
                height: "10px",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <FiSearch />
                </InputAdornment>
              ),
            }}
          />
        </Box>

        <Grid container spacing={3}>
          {availableCourseList &&
            availableCourseList?.map((data, _i) => (
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box className="mainCardBox">
                  <Box className="mainImageBox">
                    <img src={data?.course?.image_url} alt="" />
                  </Box>

                  <Box className="displayStart" my={2}>
                    <img src="/images/playCardBtn.png" alt="" />
                    <Typography variant="h5" ml={1}>
                      {data?.course?.courseName
                        ? data?.course?.courseName?.length > 20
                          ? data?.course?.courseName?.slice(0, 20) + "..."
                          : data?.course?.courseName
                        : "NA"}
                    </Typography>
                  </Box>
                  <Typography className="description">
                    {data?.course?.description
                      ? data?.course?.description?.length > 110
                        ? data?.course?.description?.slice(0, 110) + "..."
                        : data?.course?.description
                      : "NA"}
                  </Typography>
                  <Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Typography
                        sx={{
                          color: "#FFFFFFBF",
                          fontSize: "16px",
                          fontWeight: 400,
                        }}
                      >
                        {" "}
                        Available Videos:
                      </Typography>
                      <Typography
                        style={{
                          fontWeight: "600",
                          fontSize: "25px",
                          marginLeft: "10px",
                          color: "#fff",
                        }}
                      >
                        {data.total_videos}
                      </Typography>
                    </Box>
                  </Box>
                  <Grid
                    mt={2}
                    container
                    item
                    xs={12}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Button
                      sx={{
                        background:
                          "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                        marginX: "110px",
                        borderRadius: "6px",
                        width: "160px",
                        padding: "5px 100px",
                        fontSize: "18px",

                        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                      }}
                      variant="contained"
                      onClick={() => {
                        // joinHandler(data?.course);
                        navigate("/learning-detail", { state: { data: data } });
                      }}
                      disabled={
                        (joinLoading && data?.id == currentId) ||
                        data?.course?.isModuleAvailable == false
                      }
                    >
                      {joinLoading && data?.id == currentId && (
                        <CircularProgress
                          sx={{ color: "#523168" }}
                          size="20px"
                        />
                      )}
                      {"  "}
                      <span> Join</span>
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            ))}
        </Grid>
        <Box className="displayCenter">
          {availableCourseList && availableCourseList?.length === 0 && (
            <Typography
              variant="subtitle1"
              style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
            >
              No course data found!
            </Typography>
          )}
        </Box>
        <Box className="displayCenter" my={3}>
          {totalPages > 1 && (
            <Pagination
              size="small"
              count={totalPages}
              defaultPage={filter.page}
              onChange={(_e, page) => {
                setCurrentPage(page);
                const updatedFilter = { ...filter, page: page };
                setFilter(updatedFilter);
              }}
              sx={{
                "& .Mui-selected": {
                  backgroundColor: "rgba(226, 99, 4, 1) !important",
                  color: "#fff",
                },
                "& .MuiPaginationItem-page:hover": {
                  backgroundColor: "rgba(226, 99, 4, 1)",
                  color: "#fff",
                },
                "& .MuiPaginationItem-page": {
                  borderRadius: "1px solid rgba(217, 217, 217, 1)",
                },
              }}
            />
          )}
        </Box>
      </Box>
    </MainComponent>
  );
};

export default AvailableCourse;
