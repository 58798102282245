import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

// import { KeyboardDatePicker } from "@mui/lab";
// import { KeyboardDatePicker } from "@material-ui/pickers";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AiOutlineSearch } from "react-icons/ai";
import SearchIcon from "@mui/icons-material/Search";
import moment from "moment";
import { useSelector } from "react-redux";
export default function MainFilter({
  type,
  handleClear,
  search,
  setsearch,
  setFromDate,
  fromDate,
  toDate,
  setToDate,
}) {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    elevation: 0,
    PaperProps: {
      style: {
        maxHeight: 200,
      },
    },
  };

  return (
    <Box>
      {type === "session" && (
        <>
          <Grid container spacing={3} alignItems="flex-end">
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                style={{
                  backgroundColor: "#fff",
                  width: "100%",
                  maxWidth: "250px",

                  borderRadius: "5px",
                }}
                variant="outlined"
                placeholder="Search by location"
                onChange={(e) => setsearch(e.target.value)}
                value={search}
                InputProps={{
                  maxLength: 30,
                  endAdornment: (
                    <InputAdornment
                      style={{ cursor: "pointer" }}
                      position="start"
                    >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2.5}>
              <FormControl fullWidth>
                <DatePicker
                  style={{ backgroundColor: "#fff" }}
                  className="filter"
                  inputVariant="outlined"
                  id="date-picker-dialog"
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  disableFuture
                  value={fromDate}
                  sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
                  onChange={(date) => {
                    setFromDate(moment(date));
                  }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={2.5}>
              <FormControl fullWidth>
                <DatePicker
                  inputVariant="outlined"
                  id="date-picker-dialog"
                  format="YYYY-MM-DD"
                  placeholder="YYYY-MM-DD"
                  disableFuture
                  value={toDate}
                  onChange={(date) => {
                    setToDate(date);
                  }}
                  sx={{ backgroundColor: "#fff", borderRadius: "5px" }}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6} md={3} alignItems={"end"}>
              <Button
                variant="contained"
                style={{
                  color: "#fff",
                  textDecoration: "none",
                  background: "#523168",
                  padding: "0px 10px",
                  borderRadius: "30px",
                  width: "150px",
                  height: "56px",
                  fontSize: "18px",
                }}
                onClick={() => handleClear()}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}
