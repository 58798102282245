import axios from "axios";

const customAxios = axios.create({});

customAxios.interceptors.request.use(
  (config) => {
    console.log("Request intercepted:", config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    console.log("Response intercepted:", response);
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      //   logoutUser();
    }
    return Promise.reject(error);
  }
);

// function logoutUser() {
//   console.log("User logged out!");
// }

export default customAxios;
