import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  currentAssessment: {},
};

export const discoverySlice = createSlice({
  name: "discovery",
  initialState,
  reducers: {
    setCurrentAssessmentData: (state, payload) => {
      state.currentAssessment = payload;
    },
  },
});

export const { setCurrentAssessmentData } = discoverySlice.actions;

export default discoverySlice.reducer;
