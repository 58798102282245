import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import QuizPercentage from "../../widgets/assessmentPersentage/QuizPercentage";
import Assessments from "../../components/assessments/QuizAssessment";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import QuizAssessment from "../../components/assessments/QuizAssessment";
import SurveyAssessment from "../../components/assessments/SurveyAssessment";

function Surveymanagement() {
  const [activeStep, setActiveStep] = useState(1);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [surveyAssessmentData, setSurveyAssessmentData] = useState([]);
  const [pageLimit, setPageLimit] = useState(1);
  const { state } = useLocation();
  console.log(state);
  //   const { moduleId, moduleName, courseId } = state;
  //   console.log(moduleId, moduleName);

  const handleNextStep = async () => {
    if (activeStep < pageLimit) {
      const nextPage = activeStep + 1;

      try {
        const res = await customAxios({
          method: "GET",
          url: ApiConfig.getCohortSurvey,
          headers: {
            token: sessionStorage.getItem("userToken"),
          },
          params: {
            // module_id: moduleId,
            page: nextPage,
            limit: 2,
          },
        });

        if (res?.data?.statusCode === 200) {
          setSurveyAssessmentData(res?.data?.result?.data);
          setActiveStep(nextPage);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handlePreviousStep = async () => {
    if (activeStep > 1) {
      const prevPage = activeStep - 1;

      try {
        const res = await customAxios({
          method: "GET",
          url: ApiConfig.getCohortSurvey,
          headers: {
            token: sessionStorage.getItem("userToken"),
          },
          params: {
            // module_id: moduleId,
            page: prevPage,
            limit: 2,
          },
        });

        if (res?.data?.statusCode === 200) {
          setSurveyAssessmentData(res?.data?.result?.data);
          setActiveStep(prevPage);
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const getSurveyAssessmentData = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig?.getCohortSurvey,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          cohort_id: state?.cohortId,
          type: "Quarterly",
          // page: activeStep,
          // limit: 4,
        },
      });
      if (res?.data?.statusCode === 200) {
        console.log(res?.data?.result?.data);
        setSurveyAssessmentData(res?.data?.result?.data);
        setPageLimit(res?.data?.result?.totalPages);
        console.log(surveyAssessmentData, "ghjghhggh");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // handleInitialAnswers(path);
    getSurveyAssessmentData();
  }, []);

  return (
    <div>
      <Grid container px={8}>
        <Grid md={4} item>
          <img src="/images/surveyhero.png" />
        </Grid>
        <Grid item md={0.5}></Grid>
        <Grid md={7.5} item>
          <Typography mt={8} sx={{ fontSize: "28px", fontWeight: "600" }}>
            Survey
          </Typography>
          <div
            style={{
              width: "50px",
              height: "5px",
              backgroundColor: "black",
              borderRadius: "5px",
            }}
          ></div>
          {surveyAssessmentData?.length > 0 && (
            <SurveyAssessment
              pageTitle={surveyAssessmentData.map(
                (question) => question.question
              )}
              questionId={surveyAssessmentData.map((question) => question.id)}
              cohortId={state?.cohortId}
              //   moduleId={moduleId}
              renderData={surveyAssessmentData}
              activeStep={activeStep}
              handlePreviousStep={handlePreviousStep}
              handleNextStep={handleNextStep}
              pageLimit={pageLimit}
              initialAnswers={initialAnswers}

              // handleInitialAnswers={handleInitialAnswers}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
}

export default Surveymanagement;
