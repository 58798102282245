import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";

import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { Grid, Box, Typography, Container } from "@mui/material";
import { styled } from "@mui/system";
import { ApiConfig } from "../../services/ApiConfig";
import customAxios from "../../utils/customAxios";
import dayjs from "dayjs";
import axios from "axios";

const MainComponent = styled("Box")(({ theme }) => ({
  "& .componentCard": {
    width: "275px",
    height: "249px",
    padding: "30px",
    // marginY: { md: "30px", xs: "0px" },
    // margin: "0px 30px",
    // background: "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
    borderRadius: "20px",
    display: "flex",
    flexDirection: "column",
    border: "3px solid #FFF",
    [theme.breakpoints.down("md")]: {
      width: "100% !important",
    },
    [theme.breakpoints.up("lg")]: {
      width: "100% !important",
    },
    // [theme.breakpoints.up("xlg")]: {
    //   width: "100% !important",
    // },
    // "@media screen and (max-width: 149%)": {
    //   "& .componentCard ": {
    //     width: "100% !important",
    //   },
    // },
  },
}));

const CustomHeading = styled(Typography)`
  color: white;
  text-transform: uppercase;
  fontsize: "22px";
  fontweight: "700";
`;

const Discovery = () => {
  const [discoveryPercentage, setDiscoveryPercentage] = useState(0);
  const [discoveryData, setDiscoveryData] = useState([]);
  const [courseCount, setCourseCount] = useState(0);
  const [planCount, setPlanCount] = useState(0);
  const [documentDate, setDocumentDate] = useState();
  const navigate = useNavigate();

  const getAssessmentPercentage = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig?.findAllAssesmentMasterList,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          business_owner_id: sessionStorage.getItem("userId"),
        },
      });
      if (res.data?.statusCode == 200) {
        console.log(res?.data?.result?.assesmentDetails);
        setDiscoveryData(res?.data?.result?.assessmentPercentage);
      } else {
        console.log(
          res?.data?.responseMessage ||
            "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };
  const getDashboardDataList = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig?.userDashboardDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data?.statusCode == 200) {
        console.log(res.data.result);
        setCourseCount(res.data.result.allCourses);
        setPlanCount(res.data.result.allUpcomingActions);
        setDocumentDate(res.data.result.cbdUpdatedAt);
      } else {
        console.log(
          res?.data?.responseMessage ||
            "Something went wrong. Please try again."
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again."
      );
    }
  };

  async function getDiscoveryPercentage() {
    const totalPercentage = discoveryData?.reduce(
      (sum, assessment) => sum + assessment.calculatedPercentage,
      0
    );
    const averagePercentage = totalPercentage / discoveryData?.length;
    setDiscoveryPercentage(Math.floor(averagePercentage));
    console.log(averagePercentage, "alsfa");
  }

  useEffect(() => {
    getAssessmentPercentage();
  }, []);
  useEffect(() => {
    getDashboardDataList();
  }, []);

  useEffect(() => {
    getDiscoveryPercentage();
  }, [discoveryData]);

  return (
    <MainComponent>
      <Box
        /* container  */ style={{ background: "#523168", padding: "30px 0px" }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            // sx={{
            //   display: "flex",
            //   // justifyContent: "center",
            //   gap: "1.4rem",
            //   cursor: "pointer",
            // }}
            // px={0}
            // py={4}
          >
            <Grid
              className="grow"
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
              onClick={() => navigate("/discovery-coaches")}
            >
              <Box
                className="componentCard"
                sx={{
                  // width: "275px",
                  // height: "249px",
                  // paddingY: "30px",
                  // paddingX: "30px",
                  // marginY: { md: "30px", xs: "0px" },
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  // borderRadius: "20px",
                  // display: "flex",
                  // flexDirection: "column",
                  // border: "3px solid #FFF",
                }}
              >
                <Box
                  // item
                  // container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // spacing={2}
                >
                  <Box
                    component="img"
                    sx={{ width: "45px", height: "45px" }}
                    src={"/images/loupe.png"}
                  />
                  <Box width="100px" height="100px">
                    <CircularProgressbar
                      value={
                        isNaN(discoveryPercentage) ? "0" : discoveryPercentage
                      }
                      text={`${
                        isNaN(discoveryPercentage)
                          ? "0"
                          : discoveryPercentage > 100
                          ? "100"
                          : discoveryPercentage
                      }%`}
                      styles={buildStyles({
                        textSize: "25px",

                        pathColor: `blue, ${discoveryPercentage / 100})`,
                        textColor: "#fff",
                        trailColor: "white",
                      })}
                    />
                  </Box>
                </Box>
                <Box mt={4} style={{ textAlign: "center" }}>
                  <CustomHeading
                    sx={{ fontSize: "22px", fontWeight: 700 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Discovery
                  </CustomHeading>
                </Box>
              </Box>
            </Grid>
            <Grid
              onClick={() =>
                navigate("/learning&lives", {
                  state: { data: "myCourses" },
                })
              }
              className="grow"
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Box
                className="componentCard"
                sx={{
                  // width: "275px",
                  // paddingY: "30px",
                  // height: "249px",
                  // paddingX: "30px",
                  // marginY: { md: "30px", xs: "0px" },

                  backgroundColor: "#19C37D",
                  // borderRadius: "20px",
                  // display: "flex",
                  // flexDirection: "column",
                  // border: "3px solid #FFF",
                }}
              >
                <Box
                  // item
                  // container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // spacing={2}
                >
                  <Box
                    component="img"
                    style={{ width: "45px", height: "45px" }}
                    src={"/images/Bookmark.png"}
                  />
                  <Box position="relative">
                    <Box
                      sx={{ width: "100px" }}
                      component="img"
                      src={"/images/greenellipse.png"}
                    />
                    <Typography
                      sx={{
                        color: "white",
                        position: "absolute",
                        fontSize: "30px",
                        top: "28px",
                        left: "40px",
                        fontWeight: 600,
                      }}
                    >
                      {courseCount == 0 ? "0" : courseCount}
                    </Typography>
                  </Box>
                </Box>
                <Box item mt={4} sx={{ textAlign: "center" }}>
                  <CustomHeading
                    sx={{ fontSize: "22px", fontWeight: 700 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Joined Courses
                  </CustomHeading>
                </Box>
              </Box>
            </Grid>
            <Grid
              onClick={() => navigate("/action-planning")}
              className="grow"
              item
              xs={12}
              sm={6}
              md={4}
              lg={3}
            >
              <Box
                className="componentCard"
                sx={{
                  // width: "275px",
                  // paddingY: "30px",
                  // height: "249px",

                  // paddingX: "30px",
                  // marginY: { md: "30px", xs: "0px" },

                  background: "#A4ADFF",
                  // borderRadius: "20px",
                  // display: "flex",
                  // flexDirection: "column",
                  // border: "3px solid #FFF",
                }}
              >
                <Box
                  // item
                  // container
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  // spacing={2}
                >
                  <Box
                    component="img"
                    style={{ width: "45px", height: "45px" }}
                    src={"/images/calendar.png"}
                  />
                  <Box position="relative">
                    <Box
                      sx={{ width: "100px" }}
                      component="img"
                      src={"/images/EllipsePurple.png"}
                    />
                    <Typography
                      sx={{
                        color: "white",
                        position: "absolute",
                        fontSize: "30px",
                        top: "28px",
                        left: "40px",
                        fontWeight: 600,
                      }}
                    >
                      {planCount == 0 ? "0" : planCount}
                    </Typography>
                  </Box>
                  {/* <span
                      style={{
                        position: "absolute",
                        top: "20px",
                        right: "30px",
                        zIndex: "1",
                      }}
                    >
                      2
                    </span> */}
                  {/* <Typography
                        sx={{
                          color: "white",
                          position: "absolute",
                          fontSize: "30px",
                          top: "25px",
                          left: "30px",
                          fontWeight: 600,
                        }}
                      >
                        5
                      </Typography> */}
                </Box>
                <Box mt={4} sx={{ textAlign: "center" }}>
                  <CustomHeading
                    sx={{ fontSize: "22px", fontWeight: 700 }}
                    gutterBottom
                  >
                    Upcoming Plan
                  </CustomHeading>
                </Box>
              </Box>
            </Grid>
            <Grid className="grow" item xs={12} sm={6} md={4} lg={3}>
              <Box
                className="componentCard"
                onClick={() => navigate("/core-business-document")}
                sx={{
                  // width: "275px",
                  // paddingTop: "30px",
                  // paddingBottom: "30px",
                  // height: "249px",

                  // paddingX: "34px",
                  // marginY: { md: "30px", xs: "0px" },

                  // borderRadius: "20px",
                  // display: "flex",
                  // flexDirection: "column",
                  backgroundColor: "#0CCBF5",
                  // border: "3px solid #FFF",
                }}
              >
                <Box
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  display="flex"
                  // spacing={2}
                  pt={2}
                >
                  <Box
                    component="img"
                    style={{ width: "45px", height: "45px" }}
                    src={"/images/document.png"}
                  />
                  <Typography
                    variant="body1"
                    color="white"
                    sx={{ fontSize: "14px", textAlign: "center" }}
                    pt={2}
                  >
                    Last Updated On -{" "}
                    {documentDate
                      ? dayjs(documentDate).format("MMMM D, YYYY")
                      : "NA"}
                  </Typography>
                </Box>
                <Box mt={3} sx={{ textAlign: "center" }}>
                  <CustomHeading
                    sx={{ fontSize: "22px", fontWeight: 700 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    Core business document
                  </CustomHeading>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </MainComponent>
  );
};

export default Discovery;
