import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';

const JoinModal = ({ open, onClose, iframeSrc,funcToExecute }) => {
    console.log("ghhhgh------->",open,iframeSrc)


    // useEffect(() => {
    //     if (open && funcToExecute) {
    //       funcToExecute();
    //     }
    //   }, [open, funcToExecute]);
    
  return (
    <Dialog open={open} onClose={onClose}  PaperProps={{
        style: {
            maxWidth: "1000px", // Adjust maximum width
            maxHeight: "90%",   // Adjust maximum height
            width: "90%",       // Adjust width
            height: "90%",      // Adjust height
            overflow: "hidden",
            padding: "10px 0px",
          },
      }}>
      <DialogTitle>Join Meeting</DialogTitle>
      <DialogContent>
        <iframe
          src={iframeSrc}
          width="1000"
          height="600"
          frameBorder="0"
          allow="microphone; camera"
          title="Zoom Meeting"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JoinModal;
