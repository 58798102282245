import {
  Box,
  Button,
  Typography,
  Pagination,
  CircularProgress,
  Grid,
  Container,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./cohortCategory.scss";
import { FaRegCommentDots } from "react-icons/fa";
// import Pagination from "../../components/common/Pagination";
import PasswordResetModal from "../../components/PasswordResetModal";
import { Navigate, useLocation, useNavigate } from "react-router";
import customAxios from "../../utils/customAxios";
import { ApiConfig } from "../../services/ApiConfig";
import dayjs from "dayjs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function MyCohortTopics() {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [cohortTopic, setCohortTopic] = useState();
  const [topicName, setTopicName] = useState("");
  const [noTopic, setNoTopic] = useState("");
  const [loadingTopic, setLoadingTopic] = useState(false);
  const [page, setPage] = useState(1);
  const [noOfPages, setNoOfPages] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [inputStr, setInputStr] = useState("");
  const [openEmoji, setOpenEmoji] = useState(false);
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);

  const handleOpenLogoutModal = () => {
    setIsPopupOpen(true);
  };

  const handleCloseModals = () => {
    setIsPopupOpen(false);
    // You can perform additional actions on modal close if needed
  };

  const getMyCohortTopics = async () => {
    setIsLoading(true);
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.viewMyTopics,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        params: {
          page: page,
          limit: 10,
        },
      });
      if (res.data.statusCode === 200) {
        setCohortTopic(res?.data?.result?.data);
        setNoOfPages(res?.data?.result?.totalPages);
        setNoTopic(res?.data?.result);
        console.log(res?.data?.result);
        setIsLoading(false);
      } else {
        setNoOfPages(1);
        setPage(1);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setNoOfPages(1);
      setPage(1);
    }
  };

  const addCohortPosts = async (topicId, cohortId) => {
    // setLoadingPost(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.createPost,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          cohort_id: cohortId,
          topic_id: topicId,
          user_id: sessionStorage.getItem("userId"),
          description: inputStr,
        },
      });
      if (res.data.statusCode === 200) {
        // setLoadingPost(false);
        handleCloseModals();

        setInputStr("");
        setOpenEmoji(!openEmoji);
      } else {
        // setLoadingPost(false);
      }
    } catch (error) {
      console.log(error);
      // setLoadingPost(false);
    }
  };

  const addCohortTopics = async () => {
    setLoadingTopic(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.addTopic,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          cohort_id: state?.cohortId,
          topicName: topicName,
          createdBy: sessionStorage.getItem("userId"),
        },
      });
      if (res.data.statusCode === 200) {
        await addCohortPosts(res?.data?.result?.id, res?.data?.result?.id);
        await getMyCohortTopics();
        handleCloseModals();
        setTopicName("");
        setLoadingTopic(false);
      } else {
        setLoadingTopic(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.responseMessage);

      setLoadingTopic(false);
    }
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handlePrevious = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  // console.log("noOfPages", noOfPages);
  const buttonStyle = {
    color: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages > 1 ? "#523168" : "rgba(0, 0, 0, 0.12)",
  };
  const PrevbuttonStyle = {
    color: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
    borderColor: noOfPages !== 1 ? "#523168" : "rgba(0, 0, 0, 0.26)",
  };
  const handleNext = () => {
    console.log("before", noOfPages, page);
    if (page < noOfPages) {
      setPage(page + 1); // Increment the page only if it's not the last page
      console.log("after", noOfPages, page);
    }
  };

  useEffect(() => {
    getMyCohortTopics();
  }, [page]);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        width={"100vw"}
        height={"80vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress
          sx={{ height: "180px", width: "180px", color: "#ce4920" }}
        />
      </Grid>
    );
  }

  return (
    <div
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      <Container maxWidth="lg">
        <div className="main-wrapper">
          <div className="first-container">
            <Box display="flex" justifyContent="center" alignItems="center">
              {" "}
              <ArrowBackIcon
                onClick={() => navigate(-1)}
                style={{
                  margin: "0px 10px 0px 0px",
                  cursor: "pointer",
                  color: currentTheme == "DARK" ? "#fff" : "#000",
                }}
              />
              <h1
                style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                className="main-heading"
              >
                {state?.cohortName} - My Topics
              </h1>
            </Box>

            <div>
              <Button
                onClick={handleOpenLogoutModal}
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  marginX: "auto",
                  // marginBottom: "20px",
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "10px",
                }}
                variant="contained"
              >
                Create Your Topic
              </Button>
            </div>
          </div>
          <hr style={{ background: "#DFDFDF" }} />

          <div>
            {cohortTopic?.filter((item) => item.is_active === true)?.length ===
              0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={4}
              >
                <Typography variant="h6" fontWeight={800}>
                  No topic has been created by you yet. Please click on "Create
                  Your Topic" button to create one.
                </Typography>
              </Box>
            )}
            {noTopic?.length === 0 && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                mt={4}
              >
                <Typography variant="h6" fontWeight={800}>
                  No topic has been created by you yet. Please click on "Create
                  Your Topic" button to create one.
                </Typography>
              </Box>
            )}

            {cohortTopic
              ?.filter((item) => item.is_active === true)
              ?.map((item, index) => (
                <div
                  onClick={() =>
                    navigate("/cohort-posts", {
                      state: {
                        topicID: item?.id,
                        topicName: item?.topicName,
                        cohortID: state?.cohortId,
                      },
                    })
                  }
                  style={{
                    boxShadow:
                      "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                    backgroundColor:
                      index % 2 === 0
                        ? "rgba(228, 103, 3, 0.04)"
                        : "rgba(122, 105, 254, 0.04)",
                    cursor: "pointer",
                  }}
                  className="second-container"
                >
                  <div className="inner-1">
                    <div className="letter-div">
                      {" "}
                      {item?.topicName?.charAt(0).toUpperCase()}
                    </div>
                    <div className="inner1-container">
                      <h2
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        {" "}
                        {item?.topicName
                          ? item?.topicName?.length > 20
                            ? item?.topicName?.slice(0, 20) + "..."
                            : item?.topicName
                          : "NA"}
                      </h2>
                      {/* <h2>{item?.topicName ? item?.topicName : "NA"}</h2> */}
                      <div>
                        <span>
                          <span
                            style={{
                              color:
                                currentTheme == "DARK" ? "#a3a3a3" : "#000",
                            }}
                          >
                            {item.User.user_unique_id
                              ? `${item.User.user_unique_id}`
                              : "NA"}
                          </span>
                        </span>

                        <span
                          style={{
                            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                          }}
                        >
                          {" "}
                          {item?.createdAt
                            ? dayjs(item?.createdAt)?.format("ll")
                            : "NA"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "200px",
                      justifyContent: "center",
                    }}
                  >
                    <FaRegCommentDots
                      style={{ color: "#dc5806", fontSize: "17px" }}
                    />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: 400,
                        color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                      }}
                    >
                      {item?.totalComments}
                    </span>
                  </div>
                  <div className="inner-3">
                    <div className="inner3-container">
                      <span
                        style={{
                          color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                        }}
                      >
                        {" "}
                        {item?.createdAt
                          ? dayjs(item?.createdAt)?.format("ll")
                          : "NA"}
                      </span>
                      <span className="inner3-span">
                        {" "}
                        <span
                          style={{
                            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                          }}
                        >
                          {/* {item.User.user_unique_id
                          ? `BO${item.User.user_unique_id}`
                          : "NA"} */}
                          {/* {item?.createdBy
                          ? `BO${item.createdBy.slice(0, 3)}`
                          : "NA"} */}
                        </span>
                      </span>
                    </div>
                    <Typography
                      variant="h4"
                      style={{
                        width: "70px",
                        textAlign: "center",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: "70px",
                        background: "#fff",
                        border: "1px solid #E8E8E8",
                        borderRadius: "100px",
                        color: "#000",
                        fontSize: "30px",
                      }}
                    >
                      BO
                    </Typography>
                  </div>
                </div>
              ))}
          </div>
          <div className="pagination-wrapper">
            {" "}
            <Box
              mt={3}
              mb={1}
              style={{
                display: noOfPages > 1 ? "flex" : "none",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <Button
                style={PrevbuttonStyle}
                variant="outlined"
                disabled={page === 1}
                onClick={handlePrevious}
              >
                Previous
              </Button>
              <Pagination
                count={noOfPages}
                page={page}
                // className={
                //   // currentTheme == "DARK" ? "pagination-dark" : "pagination"
                // }
                onChange={handleChange}
                hideNextButton={true}
                hidePrevButton={true}
                // sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
              />
              <Button
                variant="outlined"
                disabled={page === noOfPages}
                onClick={handleNext}
                style={buttonStyle}
              >
                Next
              </Button>
            </Box>{" "}
          </div>

          <PasswordResetModal
            setTopicName={setTopicName}
            type="cohort-category"
            open={isPopupOpen}
            onClose={handleCloseModals}
            addCohortTopics={addCohortTopics}
            loadingTopic={loadingTopic}
            topicName={topicName}
            inputStr={inputStr}
            setInputStr={setInputStr}
          />
        </div>
      </Container>
    </div>
  );
}
