import { Visibility } from "@mui/icons-material";
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Box, Container } from "@mui/material";
import React from "react";
import { Navigate, useNavigate } from "react-router";

const main = {
  width: "100%",
  height: "100%",

  heading: {
    fontSize: "45px",
    fontWeight: "600",
    fontFamily: "Outfit",
    margin: "7% 0 0 0",
  },

  tableBox: {
    width: "100%",
    height: "100%",
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.2)",
    margin: "1% 0% 4% 0%",
    padding: "4% 8%",
  },
  tableHeader: {
    fontWeight: "400",
    fontSize: "20px",
  },
  tableBody: {
    fontWeight: "300",
    fontSize: "18px",
  },
  "& .MuiTableCell-root": {
    padding: " 14px 16px",
  },
  "& .MuiTableCell-root:first-child": {
    width: "42%",
    paddingLeft: "30px",
  },
  "& .MuiTableCell-root:nth-child(2)": {
    width: "43%",
  },
  "& .MuiTableCell-root:nth-child(3)": {
    Width: "15%",
  },

  iconButton: {
    border: "1px solid #681E65",
    borderRadius: "8px",
    width: "40px",
    height: "40px",
    color: "#681E65",
  },
};

export default function VersionHistory() {
  const navigate = useNavigate();
  const data = [
    { version: "Version 1", updatedDate: "12th Feb, 2024" },
    { version: "Version 2", updatedDate: "12th Feb, 2024" },
    { version: "Version 3", updatedDate: "12th Feb, 2024" },
    { version: "Version 4", updatedDate: "12th Feb, 2024" },
    { version: "Version 5", updatedDate: "12th Feb, 2024" },
    { version: "Version 6", updatedDate: "12th Feb, 2024" },
  ];

  return (
    <Container maxWidth={"lg"}>
      <Grid sx={main} lg={12}>
        <Box sx={{ width: "100%" }}>
          <Typography sx={main.heading}>Monitor & Measures</Typography>
        </Box>
        <Box sx={main.tableBox}>
          <TableContainer>
            <Table>
              <TableHead sx={{ background: "#681E650D" }}>
                <TableRow>
                  <TableCell sx={main.tableHeader} align="left">
                    Version
                  </TableCell>
                  <TableCell sx={main.tableHeader} align="left">
                    Updated Date
                  </TableCell>
                  <TableCell sx={main.tableHeader} align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {data.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell sx={main.tableBody}>{item.version}</TableCell>
                    <TableCell>{item.updatedDate}</TableCell>
                    <TableCell>
                      <Box className="displayCenter">
                        <Button
                          onClick={() => {
                            navigate("/monitor-measure/statististics");
                          }}
                        >
                          View statististics
                        </Button>
                        <IconButton sx={main.iconButton}>
                          <Visibility />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Container>
  );
}
