import { CircularProgress, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router";

const AuthGuard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const token = sessionStorage.getItem("userToken");
  const navigate = useNavigate();
  useEffect(() => {
    console.log("ajsbbafasd");
    if (token) {
      setIsLoading(false);
    } else {
      navigate("/");
      setIsLoading(false);
    }
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        height={"100dvh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <CircularProgress sx={{ color: "#d24809" }} />
      </Grid>
    );
  }

  return <Outlet />;
};

export default AuthGuard;
