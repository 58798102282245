import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  action: false,
  schedule: false,
};

export const actionScheduleRefresherSlice = createSlice({
  name: "actionScheduleRefresher",
  initialState,
  reducers: {
    setAction: (state, payload) => {
      state.action = !state.action;
    },
    setSchedule: (state, payload) => {
      state.schedule = !state.schedule;
    },
  },
});

export const { setAction, setSchedule } = actionScheduleRefresherSlice.actions;

export default actionScheduleRefresherSlice.reducer;
