import { Box, Container, Grid } from "@mui/material";
import React from "react";
import "./monitormeasure.scss";
import { useNavigate } from "react-router";

const MonitorMeasure = () => {
  const navigate = useNavigate();
  return (
    <Box>
      {" "}
      <Container maxWidth={"lg"}>
        <Grid sx={{ mt: 20, mb: 10 }} container>
          <Grid item xs={6}>
            <img style={{ height: "600px" }} src="/images/businesspeople.png" />
          </Grid>
          <Grid xs={6}>
            <div
              // style={{ background: currentTheme == "DARK" ? "#151515" : "#fff" }}
              className="findingFundersWrapper"
            >
              <h1>Monitor & Measures!</h1>
              <h2>
                Strategic Insights: Business Information & Baseline Assessment
                Overview
              </h2>
              <div className="bottom-div">
                <h3>COMPLETE THIS FIRST!</h3>
                <button
                  onClick={() => navigate("/monitor-measure/assessment")}
                  className="core-button"
                >
                  Get Started
                </button>
                <button
                  onClick={() => navigate("/monitor-measure/assessment")}
                  className="core-button"
                >
                  Update now
                </button>
                <button
                  onClick={() =>
                    navigate("/monitor-measure/assessment/version-history")
                  }
                  className="core-button"
                >
                  View Version
                </button>
              </div>

              <img src="/images/purpleVector.png" />
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default MonitorMeasure;
