import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  Box,
  TextField,
  Button,
  Grid,
  Paper,
  FormControl,
  Typography,
  CircularProgress,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import styled from "@emotion/styled";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "./corebusinessinfo.scss";
import Sidebar from "../../../components/profileSidebar/Sidebar";
import { ApiConfig } from "../../../services/ApiConfig";
import customAxios from "../../../utils/customAxios";
import { useSelector } from "react-redux";
import PasswordResetModal from "../../../components/PasswordResetModal";
import axios from "axios";

const CoreBusinessInfo = () => {
  const [userDetails, setUserDetails] = useState(null);
  const [profileImage, setProfileImage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sidebarRes, setSidebarRes] = useState();
  const [triggerSideBarUpdate, setTriggerSideBarUpdate] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [category, setCategory] = useState([]);
  const [currentIndustryCategory, setCurrentIndustryCategory] = useState({});
  const [updateDetailModalOpen, setUpdateDetailModalOpen] = useState(false);

  const handleOpenUpdatedetailModal = () => {
    setUpdateDetailModalOpen(true);
  };

  const handleCloseUpdatedetailModal = () => {
    setUpdateDetailModalOpen(false);
  };
  const getUserDetails = async () => {
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.getUserDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res.data.statusCode === 200) {
        setUserDetails(res?.data?.result);
        setSidebarRes(res?.data?.result);
        console.log(res?.data?.result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCategory();
  }, []);

  const getCategory = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: ApiConfig.getBusinessCategoryList,
      });
      if (res.data?.statusCode == 200) {
        setCategory(res?.data?.result?.allCategory);
        // sessionStorage.setItem("userToken", res.data.result.token);
      } else {
        console.log(
          res.data?.responseMessage ||
            "Something went wrong. Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );

        return null;
      }
    } catch (error) {
      console.log(
        error?.response?.data?.responseMessage ||
          "Something went wrong. Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    }
  };

  useEffect(() => {
    if (category && userDetails) {
      setCurrentIndustryCategory(
        category?.filter((e) => userDetails?.business_category == e?.id)
          ?.length > 0
          ? category?.filter((e) => userDetails?.business_category == e?.id)[0]
          : {}
      );
    }
  }, [userDetails, category]);

  useEffect(() => {
    console.log(currentIndustryCategory, "zkgjdsaif");
  }, [currentIndustryCategory]);

  const updateUserDetails = async (values) => {
    setLoading(true);
    // console.log(profileImage);
    // console.log(values?.businessType);
    const filter = {
      first_name: values.firstName,
      last_name: values.lastName,
      title: values.title,
      business_name: values.businessType,
      company_name: values.companyName,
      business_description: values.description,
      business_size: values.size,
      business_category: currentIndustryCategory?.id,
      user_id: sessionStorage.getItem("userId"),
    };
    const newObj = Object.fromEntries(
      Object.entries(filter).filter(([_, v]) => v != "")
    );

    try {
      const res = await customAxios({
        method: "PUT",
        url: ApiConfig.updateUser,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },

        data: newObj,
      });
      if (res.data.statusCode === 200) {
        console.log(res);
        setUserDetails(res?.data?.result);
        setTriggerSideBarUpdate((e) => !e);
        await getUserDetails();
        await handleOpenUpdatedetailModal();
        // window.location.reload();

        console.log(res?.data?.result);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  // useEffect(() => {
  //   // Move the initialization inside useEffect
  //   const initialValues = {
  //     firstName: userDetails.first_name || "",
  //     // lastName: userDetails.last_name || "",
  //     // title: userDetails.title || "",
  //     // businessType: userDetails.business_type || "",
  //     // companyName: userDetails.company_name || "",
  //     // description: userDetails.description || "",
  //     // operation: userDetails.operation || "",
  //     // size: userDetails.size || "",
  //   };

  //   setInitialValues(initialValues);
  // }, [userDetails]);

  // const [initialValues, setInitialValues] = useState({

  // });
  const validationSchema = Yup.object({
    firstName: Yup.string()
      // .required("First name is required.")
      .min(3, "First name must be at least 3 characters."),
    lastName: Yup.string()
      // .required("Last name is required.")
      .min(3, "Last name must be at least 3 characters."),
    title: Yup.string()
      // .required("Title is required.")
      .min(2, "Title must be at least 2 characters."),
    businessType: Yup.string().min(
      3,
      "Business name must be at least 3 characters."
    ),
    companyName: Yup.string()
      // .required("Company name is required.")
      .min(3, "Company name must be at least 3 characters."),
    description: Yup.string()
      // .required("This field is required.")
      .min(3, "Business description  must be at least 3 characters."),
    // operation: Yup.string()
    operation: Yup.object(),
    // .required("This field  is required.")
    // .min(3, "Business industry must be at least 3 characters.")
    size: Yup.string(),
    // .required("Business size is required.")
    // .min(3, "Business size must be at least 3 characters.")
  });
  const FormTextField = styled(TextField)({
    height: "60px!important",
    marginTop: "4px",

    "@media(max-width:1024px)": {
      // width: "70vw !important",
      // margin: "10px 0px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "2px solid rgba(82, 49, 104, 0.60)",
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        border: "2px solid rgba(82, 49, 104, 0.60)",
      },
      "&:focus": {
        "& .MuiOutlinedInput-notchedOutline": {
          border: "2px solid rgba(82, 49, 104, 0.60)",
          outline: "none", // Set outline to none on focus
        },
      },
    },
  });

  const navigate = useNavigate();
  const handleSubmit = async (values) => {
    try {
      const res = await updateUserDetails(values);
      if (res.data.statusCode === 200) {
        console.log(res.data.result);
      }
    } catch (error) {
      console.log(error);
      // setSubmitting(false);
    }
  };

  return (
    <div
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#f8f8f8",
      }}
    >
      <Box
        sx={{
          display: "flex",
          //   gap: "25px",
          alignItems: "center",
          paddingLeft: { sm: "70px", xs: "35px" },
          backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
        }}
      >
        <img
          style={{ cursor: "pointer", width: "30px" }}
          onClick={() => navigate("/my-profile")}
          src="/images/backicon.png"
        />
        <h1
          style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          className="profile-heading"
        >
          Core Business Information
        </h1>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          marginTop: "10px",

          padding: { sm: "0.6% 70px 1rem 70px", xs: "1% 20px 1rem 20px" },

          // marginBottom: "-2rem",
        }}
      >
        <Box
          sx={{
            display: { md: "block", xs: "none" },

            margin: 0,
            padding: 0,
            height: "790px",
            // paddingBottom:"30px",
            width: "450px",

            overflow: "none",
            alignItems: "center",
            gap: "2rem",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
              display: "none", // For Safari and Chrome
            },
            "@media(max-width:768px)": {
              ml: "0px",
            },
          }}
        >
          <Sidebar
            sidebarRes={sidebarRes}
            profileImage={profileImage}
            setProfileImage={setProfileImage}
            triggerSideBarUpdate={triggerSideBarUpdate}
          />
        </Box>

        <Box
          style={{
            width: "100%",
            // height: "78vh",
            overflow: "auto",
            // boxShadow: "rgba(0, 0, 0, 0.07) 6px 1px 18px 5px",
            backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
            padding: "10px 10%",
          }}
        >
          {!userDetails ? (
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="78vh"
            >
              <CircularProgress />
            </Box>
          ) : (
            <Formik
              initialValues={{
                firstName: userDetails?.first_name
                  ? userDetails?.first_name
                  : "",
                lastName: userDetails.last_name ? userDetails.last_name : "",
                title: userDetails.title ? userDetails.title : "",
                businessType: userDetails?.business_name
                  ? userDetails?.business_name
                  : "",
                companyName: userDetails?.company_name
                  ? userDetails?.company_name
                  : "",
                description: userDetails?.business_description
                  ? userDetails?.business_description
                  : "",
                operation: currentIndustryCategory
                  ? currentIndustryCategory
                  : "",
                size: userDetails?.business_size
                  ? userDetails?.business_size
                  : "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                values,
                isValid,
                dirty,
                setFieldValue,
              }) => (
                <Form>
                  <h2
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    Basic Information:
                  </h2>
                  <div
                    className="first-row"
                    //   style={{ display: "flex", width: "100%" }}
                  >
                    <div>
                      {" "}
                      <Typography
                        sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                        fontSize="18px"
                      >
                        First Name
                      </Typography>
                      <FormTextField
                        className="input"
                        // style={{ width: "48%" }}
                        fullWidth
                        inputProps={{
                          maxLength: 30,
                          sx: {
                            height: "20px",
                            // width: "394px",
                            marginBottom: "0px",
                            backgroundColor:
                              currentTheme == "DARK" ? "#fff" : "#fff",
                          },
                        }}
                        variant="outlined"
                        name="firstName"
                        placeholder="Enter your first name"
                        value={values.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.firstName && Boolean(errors.firstName)}
                        helperText={touched.firstName && errors.firstName}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: "0px",
                          },
                        }}
                        onInput={(e) => {
                          const regex = /^[a-zA-Z]*$/;

                          if (!regex.test(e.target.value)) {
                            e.target.value =
                              e.target.dataset.previousValue || "";
                          } else {
                            e.target.dataset.previousValue = e.target.value;
                          }
                          setErrorMessage("");
                        }}
                      />
                    </div>
                    <div>
                      <Typography
                        sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                        fontSize="18px"
                      >
                        Last Name
                      </Typography>
                      <FormTextField
                        fullWidth
                        inputProps={{
                          maxLength: 60,
                          sx: {
                            height: "20px",
                            // width: "394px",
                            marginBottom: "0px",
                            backgroundColor: "#fff",
                          },
                        }}
                        variant="outlined"
                        name="lastName"
                        placeholder="Enter Last name"
                        value={values.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={touched.lastName && Boolean(errors.lastName)}
                        helperText={touched.lastName && errors.lastName}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: "0px",
                          },
                        }}
                        onInput={(e) => {
                          // Regular expression to allow only letters and spaces
                          const regex = /^[a-zA-Z\s]*$/;

                          // Check if the input matches the regex
                          if (!regex.test(e.target.value)) {
                            // If not, set the value to the previous valid value
                            e.target.value =
                              e.target.dataset.previousValue || "";
                          } else {
                            // If valid, update the previous valid value
                            e.target.dataset.previousValue = e.target.value;
                          }
                          setErrorMessage("");
                        }}
                      />
                    </div>
                  </div>
                  <div className="wrapper">
                    <Typography
                      sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                      fontSize="18px"
                    >
                      Title
                    </Typography>
                    <FormTextField
                      fullWidth
                      inputProps={{
                        maxLength: 60,
                        style: {
                          height: "20px",
                          marginBottom: "0px",
                          backgroundColor: "#fff",
                        },
                      }}
                      variant="outlined"
                      name="title"
                      placeholder="Enter Title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      onInput={(e) => {
                        // Regular expression to allow only letters and numbers
                        const regex = /^[a-zA-Z0-9\s]*$/;

                        // Check if the input matches the regex
                        if (!regex.test(e.target.value)) {
                          // If not, set the value to the previous valid value
                          e.target.value = e.target.dataset.previousValue || "";
                        } else {
                          // If valid, update the previous valid value
                          e.target.dataset.previousValue = e.target.value;
                        }
                        setErrorMessage("");
                      }}
                      error={touched.title && Boolean(errors.title)}
                      helperText={touched.title && errors.title}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </div>
                  <h2
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    Business General Information:
                  </h2>
                  <div className="wrapper">
                    <Typography
                      sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                      fontSize="18px"
                    >
                      Business Name
                    </Typography>
                    <FormTextField
                      fullWidth
                      inputProps={{
                        maxLength: 60,
                        style: {
                          height: "20px",
                          marginBottom: "0px",
                          backgroundColor: "#fff",
                        },
                      }}
                      variant="outlined"
                      name="businessType"
                      placeholder="Enter your business name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.businessType}
                      error={
                        touched.businessType && Boolean(errors.businessType)
                      }
                      helperText={touched.businessType && errors.businessType}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                      // onInput={(e) => {
                      //   const regex = /^[a-zA-Z\s]*$/;

                      //   if (!regex.test(e.target.value)) {
                      //     e.target.value = e.target.dataset.previousValue || "";
                      //   } else {
                      //     e.target.dataset.previousValue = e.target.value;
                      //   }
                      //   setErrorMessage("");
                      // }}
                    />
                  </div>
                  <div className="wrapper">
                    <Typography
                      sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                      fontSize="18px"
                    >
                      Company Name
                    </Typography>
                    <FormTextField
                      fullWidth
                      inputProps={{
                        maxLength: 60,
                        style: {
                          height: "20px",

                          marginBottom: "0px",
                          backgroundColor: "#fff",
                        },
                      }}
                      variant="outlined"
                      name="companyName"
                      placeholder="Please provide your company name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.companyName}
                      error={touched.companyName && Boolean(errors.companyName)}
                      helperText={touched.companyName && errors.companyName}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </div>
                  <div className="wrapper">
                    <Typography
                      sx={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                        marginBottom: "4px",
                      }}
                      fontSize="18px"
                    >
                      Description Of Your Business
                    </Typography>

                    <FormControl fullWidth>
                      <FormTextField
                        fullWidth
                        inputProps={{
                          maxLength: 500,
                          style: {
                            height: "20px",

                            marginBottom: "0px",
                            backgroundColor: "#fff",
                          },
                        }}
                        multiline
                        rows={4}
                        // onInput={(e) => {
                        //   const regex = /^[0-9]*$/;

                        //   if (!regex.test(e.target.value)) {
                        //     e.target.value = e.target.dataset.previousValue || "";
                        //   } else {
                        //     e.target.dataset.previousValue = e.target.value;
                        //   }
                        //   setErrorMessage("");
                        // }}
                        variant="outlined"
                        name="description"
                        placeholder="Please provide description of your business"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.description}
                        error={
                          touched.description && Boolean(errors.description)
                        }
                        helperText={touched.description && errors.description}
                        FormHelperTextProps={{
                          style: {
                            marginLeft: "0px",
                          },
                        }}
                      />
                    </FormControl>
                  </div>

                  <div style={{ marginTop: "80px" }}>
                    <Typography
                      sx={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                        marginBottom: "4px",
                      }}
                      fontSize="18px"
                    >
                      What industry does your business operate in?
                    </Typography>

                    <FormControl fullWidth>
                      <Select
                        name="operation"
                        // variant="outlined"
                        // displayEmpty
                        // fullWidth

                        sx={{
                          "& .MuiOutlinedInput-notchedOutline": {
                            // borderColor: "",
                            border: "2px solid #927f9f",
                            borderRadius: "10px",
                            color: currentTheme === "DARK" ? "#fff" : "#000",
                            backgroundColor:
                              currentTheme === "DARK" ? "#fff" : "inherit",
                          },
                        }}
                        value={currentIndustryCategory}
                        onChange={(e) => {
                          handleChange(e);
                          // console.log(e?.target?.value, "zkjsiadfas");
                          setFieldValue("operation", e?.target?.value);
                          setCurrentIndustryCategory(e?.target?.value);
                        }}
                        // sx={useStyle.textFieldWidth}
                        inputProps={{
                          style: {
                            height: "20px",
                            width: "230px",
                          },
                        }}
                        onBlur={handleBlur}
                        error={touched.operation && Boolean(errors.operation)}
                        helperText={touched.operation && errors.operation}
                      >
                        <MenuItem sx={{ color: "#4d4d4d" }} value="">
                          <em>Select a business industry</em>
                        </MenuItem>
                        {category
                          .filter((e, i) => e?.status != "BLOCKED")
                          .map((res, idx) => (
                            <MenuItem value={res}>{res.category_name}</MenuItem>
                          ))}
                      </Select>
                      <FormHelperText style={{ marginLeft: "0px" }}>
                        {touched.operation && errors.operation}
                      </FormHelperText>
                    </FormControl>

                    {/* <FormTextField
                      fullWidth
                      inputProps={{
                        maxLength: 50,
                        style: {
                          height: "20px",

                          marginBottom: "0px",
                          backgroundColor: "#fff",
                        },
                      }}
                      onInput={(e) => {
                        const regex = /^[a-zA-Z\s]*$/;

                        if (!regex.test(e.target.value)) {
                          e.target.value = e.target.dataset.previousValue || "";
                        } else {
                          e.target.dataset.previousValue = e.target.value;
                        }
                        setErrorMessage("");
                      }}
                      variant="outlined"
                      name="operation"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="Please provide your business industry "
                      value={values.operation}
                      error={touched.operation && Boolean(errors.operation)}
                      helperText={touched.operation && errors.operation}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                    /> */}
                  </div>
                  <div className="wrapper">
                    <Typography
                      fontSize="18px"
                      sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                    >
                      What is the size of your business ?
                    </Typography>
                    <FormTextField
                      fullWidth
                      inputProps={{
                        maxLength: 20,
                        style: {
                          height: "20px",

                          marginBottom: "0px",
                          backgroundColor: "#fff",
                        },
                      }}
                      // onInput={(e) => {
                      //   const regex = /^[0-9]*$/;

                      //   if (!regex.test(e.target.value)) {
                      //     e.target.value = e.target.dataset.previousValue || "";
                      //   } else {
                      //     e.target.dataset.previousValue = e.target.value;
                      //   }
                      //   setErrorMessage("");
                      // }}
                      variant="outlined"
                      name="size"
                      placeholder="Number of employees"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.size}
                      error={touched.size && Boolean(errors.size)}
                      helperText={touched.size && errors.size}
                      FormHelperTextProps={{
                        style: {
                          marginLeft: "0px",
                        },
                      }}
                    />
                  </div>
                  <Box
                    mt={2}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      sx={{
                        background:
                          "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                        borderRadius: "10px",
                        fontSize: "18px",
                        paddingX: "40px",
                        paddingY: "10px",

                        textTransform: "none",
                      }}
                      disabled={loading}
                      variant="contained"
                      type="submit"
                    >
                      Update
                      {loading && (
                        <CircularProgress
                          size={20}
                          sx={{
                            marginLeft: "10px",
                            color: "white",
                          }}
                        />
                      )}
                      {/* {loading && (
                      <CircularProgress
                        size={20} // Set the size of CircularProgress
                        sx={{
                          marginLeft: "10px", // Half of the size for horizontal centering
                          color: "white", // Set the color of CircularProgress
                        }}
                      />
                    )} */}
                    </Button>
                  </Box>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </Box>
      <PasswordResetModal
        type="updateDetails"
        open={updateDetailModalOpen}
        onClose={handleCloseUpdatedetailModal}
      />
    </div>
  );
};

export default CoreBusinessInfo;
