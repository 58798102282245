import { createSlice } from "@reduxjs/toolkit";

export const ThemeMode = {
  LIGHT: "LIGHT",
  DARK: "DARK",
};

// Check if themeMode exists in localStorage, default to LIGHT if not found or invalid
const storedThemeMode = localStorage.getItem("themeMode");
const initialState = {
  mode: storedThemeMode && [ThemeMode.LIGHT, ThemeMode.DARK].includes(storedThemeMode) ? storedThemeMode : ThemeMode.LIGHT,
};

export const themeSwitcherSlice = createSlice({
  name: "themeSwitcher",
  initialState,
  reducers: {
    setTheme: (state, action) => {
      const { mode } = action.payload;
      state.mode = mode;
      localStorage.setItem("themeMode", mode);
    },
  },
});

export const { setTheme } = themeSwitcherSlice.actions;
export default themeSwitcherSlice.reducer;
