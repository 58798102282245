import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "./layout.scss";
import { Box, Typography } from "@mui/material";

export default function CohortLayout({ children }) {
  return (
    <Box>
      <div className="headerwrapper">
        <Header />
      </div>
      <Box
        mt={7}
        component={"img"}
        src="/images/cohortheroimg.png"
        sx={{
          position: "relative",
          top: "0px",
          width: "100%",
          height: "550px",
          // borderRadius: "15px",
          backgroundSize: "cover !important",
          backgroundRepeat: "no-repeat !important",
          objectFit: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      />
      <Typography
        sx={{
          position: "absolute",
          zIndex: 100,
          top: "32%",
          left: "42%",
        }}
        fontWeight={"700"}
        variant="h2"
        color="#fff"
      >
        COHORT
      </Typography>
      {/* </Box> */}
      {/* <div>
        <img width="100%" height="20%" src="/images/cohortheroimg.png" />
      </div> */}

      <div className="">{children}</div>
      <Footer />
    </Box>
  );
}
