import React, { useRef, useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Typography, useMediaQuery } from "@mui/material/";
import Button from "@mui/material/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import { Navigation, Autoplay } from "swiper/modules";
import { useSelector } from "react-redux";

function Testimonials({ testimonialData }) {
  const [activeStep, setActiveStep] = useState(0);
  const [isAutoScrollPaused, setIsAutoScrollPaused] = useState(false);
  const scrollableDivRef = useRef(null);
  const scrollInterval = useRef(null);
  const swiperRef = useRef();
  const matchesXxl = useMediaQuery((theme) => theme.breakpoints.up("xxl"));
  const matchesLg = useMediaQuery((theme) => theme.breakpoints.up("lg"));

  const matchesForSlider = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const matchesSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));
  const matchesXs = useMediaQuery("(max-width:615px)");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleSlideChange = (swiper) => {
    const slides = swiper.slides;

    slides.forEach((slide, index) => {
      const isActive = index === swiper.activeIndex + 1;
      const scale = isActive ? 1.1 : 0.9;
      slide.style.transition = "transform 0.5s";
      slide.style.transform = `scale(${scale})`;
    });
  };
  const applyInitialScale = (swiper) => {
    const slides = swiper.slides;

    slides?.forEach((slide, index) => {
      const isActive = index === swiper.activeIndex + 1;
      const scale = isActive ? 1.1 : 0.9;
      slide.style.transition = "transform 0.5s";
      slide.style.transform = `scale(${scale})`;
    });
  };

  useEffect(() => {
    const swiper = swiperRef.current.swiper;

    if (swiper) {
      applyInitialScale(swiper);
      swiper.update();
      swiper.autoplay.start();
    }
  }, [swiperRef.current]);

  return (
    <Box className="testimonialCardsContainer">
      <div className="testimonialHeader">
        <div>
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            fontSize="42px"
            marginTop="2rem"
            fontWeight={600}
          >
            You Are The Center Of Our Universe
          </Typography>
          <Typography
            color="#FB3F10"
            my={4}
            fontWeight={600}
            fontSize="32px"
            sx={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
          >
            Testimonials
          </Typography>
        </div>
      </div>
      <Swiper
        ref={swiperRef}
        modules={[Navigation, Autoplay]}
        spaceBetween={20}
        className="testimonialCardsWrapper"
        slidesPerView={
          matchesXxl
            ? 4
            : matchesLg
            ? 3
            : matchesForSlider
            ? 2.5
            : matchesXs
            ? 1
            : 2
        }
        navigation={true}
        pagination={true}
        autoplay={{
          delay: 1500,
          // disableOnInteraction: false,
        }}
        onSlideChange={matchesLg && handleSlideChange}
        style={{
          "--swiper-pagination-color": "#fff",
          "--swiper-navigation-color": "#fff",
          "--swiper-navigation-size": "25px",
        }}
      >
        {testimonialData.length > 0 &&
          testimonialData
            ?.filter((item) => item.status == "ACTIVE")
            ?.map((step, index) => (
              <SwiperSlide>
                <div
                  className="testimonialCard"
                  key={step.label}
                  style={{
                    backgroundColor:
                      currentTheme === "DARK"
                        ? index % 2 === 0
                          ? "#1f1f1f" // Dark background color for DARK theme
                          : "rgba(82, 49, 104, 0.10)" // Alternate color for DARK theme
                        : index % 2 === 0
                        ? "#e9e9e9" // Default background color for light theme
                        : "rgba(82, 49, 104, 0.10)", // Alternate color for light theme
                    userSelect: "none",
                    cursor: "pointer",
                    boxShadow:
                      "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)",
                  }}
                >
                  <p
                    className="wordBreak"
                    style={{
                      textAlign: "center",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    {step?.description}
                  </p>
                  <div
                    className="cardFooter"
                    style={{ color: currentTheme == "DARK" ? "#fff" : "#000" }}
                  >
                    <span className="wordBreak">{step?.founder_name},</span>
                    <span className="wordBreak">{step?.designation}</span>
                  </div>
                  <div style={{ backgroundColor: "white" }}>
                    <img src={step?.image_url} className="profilePic" />
                  </div>
                </div>
              </SwiperSlide>
            ))}
      </Swiper>
    </Box>
  );
}

export default Testimonials;

// function Testimonials({ testimonialData }) {
//   const [activeStep, setActiveStep] = useState(0);
//   const scrollableDivRef = useRef(null);
//   const scrollInterval = useRef(null);

//   const handleAutoScroll = (side) => {
//     const container = scrollableDivRef.current;
//     if (container) {
//       // const totalCards = testimonialData.length;
//       const newActiveStep =
//         side === "left"
//           ? activeStep === 0
//             ? testimonialData.length
//             : activeStep - 1
//           : activeStep + 1;

//       setActiveStep(newActiveStep);

//       // Calculate the scroll position to center the card
//       const cardWidth = container.scrollWidth / testimonialData.length;
//       const scrollPosition =
//         newActiveStep * cardWidth + cardWidth / 2 - container.clientWidth / 2;

//       container.scrollTo({
//         top: 0,
//         left: scrollPosition,
//         behavior: newActiveStep === 0 ? "auto" : "smooth",
//       });
//       if (newActiveStep === testimonialData.length) {
//         setActiveStep(-1);
//         // setTimeout(() => {
//         //   container.scrollTo({
//         //     top: 0,
//         //     left: 0,
//         //     behavior: "auto",
//         //   });
//         // }, 4000); // Adjust the delay based on your auto-scroll interval
//       }
//     }
//   };

//   const handleButtonClick = (side) => {
//     const container = scrollableDivRef.current;
//     if (container) {
//       const totalCards = testimonialData.length;
//       let newActiveStep;

//       if (side === "left") {
//         newActiveStep = (activeStep - 1 + totalCards) % totalCards;
//       } else {
//         newActiveStep = (activeStep + 1) % totalCards;
//       }

//       setActiveStep(newActiveStep);

//       // Calculate the scroll position to center the card
//       // const cardWidth = container.scrollWidth / totalCards;
//       // const scrollPosition = newActiveStep * cardWidth;
//       const cardWidth = container.scrollWidth / testimonialData.length;
//       const scrollPosition =
//         newActiveStep * cardWidth + cardWidth / 2 - container.clientWidth / 2;

//       container.scrollTo({
//         top: 0,
//         left: scrollPosition,
//         behavior: newActiveStep === 0 ? "smooth" : "smooth",
//       });
//     }
//   };

//   const startAutoScroll = () => {
//     scrollInterval.current = setInterval(() => {
//       handleAutoScroll();
//     }, 2000);
//   };

//   const stopAutoScroll = () => {
//     clearInterval(scrollInterval.current);
//   };

//   useEffect(() => {
//     startAutoScroll();

//     return () => {
//       stopAutoScroll();
//     };
//   }, [activeStep]);

//   // const handleButtonClick = (side) => {
//   //   const container = scrollableDivRef.current;
//   //   if (container) {
//   //     if (side === "left") {
//   //       if (activeStep > 1) {
//   //         setActiveStep(activeStep - 1);
//   //       }
//   //     } else {
//   //       if (activeStep + 1 !== testimonialData.length) {
//   //         setActiveStep(activeStep + 1);
//   //       }
//   //     }

//   //     container.scrollBy({
//   //       top: 0,
//   //       left: side === "left" ? "-550" : "550",
//   //       behavior: "smooth",
//   //     });
//   //   }
//   // };

//   return (
//     <Box className="testimonialCardsContainer">
//       <Button
//         className="arrowBtn"
//         style={{ right: "0" }}
//         onClick={() => handleButtonClick("right")}
//       >
//         <Box
//           sx={{ width: { md: "56px", xs: "30px" } }}
//           component="img"
//           src={"/images/rightarrow.png"}
//         />
//       </Button>
//       <div className="testimonialHeader">
//         <div>
//           <Typography fontSize="42px" mt={10} fontWeight={600}>
//             You Are The Center Of Our Universe
//           </Typography>
//           <Typography color="#FB3F10" my={4} fontWeight={600} fontSize="32px">
//             Testimonials
//           </Typography>
//         </div>
//       </div>
//       <Box mt={7} className="testimonialCardsWrapper" ref={scrollableDivRef}>
//         {testimonialData.length > 0 &&
//           testimonialData.map((step, index) => (
//             <div
//               className="testimonialCard"
//               key={step.label}
//               style={{
//                 width: index === activeStep && "550px",
//                 height: index === activeStep && "380px",
//                 marginTop: index === activeStep && "-2rem",
//                 background:
//                   index % 2 === 0 ? "rgba(82, 49, 104, 0.10)" : "#e9e9e9",
//                 userSelect: "none",
//                 cursor: "pointer",

//                 boxShadow:
//                   "0 1px 1px rgba(0,0,0,0.11), 0 2px 2px rgba(0,0,0,0.11), 0 4px 4px rgba(0,0,0,0.11), 0 6px 8px rgba(0,0,0,0.11), 0 8px 16px rgba(0,0,0,0.11)",
//               }}
//             >
//               <p
//                 style={{
//                   fontSize: index === activeStep && "20px",
//                   textAlign: "center",
//                 }}
//               >
//                 {step?.description}
//               </p>
//               <div
//                 className="cardFooter"
//                 style={{
//                   display: index === activeStep && "flex",
//                   justifyContent: index === activeStep && "right",
//                   marginTop: index === activeStep && "auto",
//                   marginBottom: index === activeStep && "10px",
//                   gap: index === activeStep && "0.5rem",
//                 }}
//               >
//                 <span
//                   style={{
//                     fontSize: index === activeStep && "16px",
//                     fontWeight: "600",
//                   }}
//                 >
//                   {step?.founder_name},
//                 </span>
//                 <span
//                   style={{
//                     fontSize: index === activeStep && "16px",
//                   }}
//                 >
//                   {step?.designation}
//                 </span>
//               </div>
//               <div style={{ backgroundColor: "white" }}>
//                 <img
//                   src={step?.image_url}
//                   style={{
//                     height: index === activeStep && "110px",
//                     width: index === activeStep && "110px",
//                     top: index === activeStep && "-50px",
//                     borderRadius: index === activeStep && "110px",
//                   }}
//                   className="profilePic"
//                 />
//               </div>
//             </div>
//           ))}
//       </Box>
//       <Button
//         className="arrowBtn"
//         style={{ left: "20px" }}
//         onClick={() => handleButtonClick("left")}
//       >
//         {" "}
//         <Box
//           sx={{ width: { md: "56px", xs: "30px" } }}
//           component="img"
//           src={"/images/leftarrow.png"}
//         />
//       </Button>
//     </Box>
//   );
// }

// export default Testimonials;
