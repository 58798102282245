import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Card,
  CardContent,
  Typography,
  CardActions,
  Button,
  Stack,
  Container,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import { styled } from "@mui/system";
import { useNavigate } from "react-router";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import axios from "axios";
import { ApiConfig } from "../../services/ApiConfig";
import { useSelector } from "react-redux";

const LearningCard = styled("Box")(({ theme }) => ({
  "& .mainLearnCard": {
    padding: "0px 128px",
    [theme.breakpoints.down("md")]: {
      padding: "0px 90px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0px 35px",
    },
  },

  "& .learSubTitle": {
    color: "#6d6d6e",

    cursor: "pointer",
    width: "100%",
    height: "70px",
    textTransform: "capitalize",

    [theme.breakpoints.down("sm")]: {
      lineHeight: "20px !important",
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "25px !important",
    },
  },
  "& .learnHeading": {
    fontSize: "25px",
    marginLeft: "5px",
    fontWeight: "500",
    marginTop: "12px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      fontSize: "15px",
      lineHeight: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      lineHeight: "20px",
    },
  },
  "& .learnAvailVid": {
    color: "#6d6d6e",
  },
  "& .descripText": {
    margin: "16px 0px",
  },
  "& .learSubTitle": {
    color: "#6d6d6e",
    fontFamily: "Outfit",
    cursor: "pointer",
    width: "100%",
    height: "70px",
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      lineHeight: "20px !important",
    },
    [theme.breakpoints.down("xs")]: {
      lineHeight: "25px !important",
    },
  },
}));

const learnObj = [
  {
    heroImg: "/images/hacker.png",
    heading: "Business Operations",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
  {
    heroImg: "/images/AI.png",

    heading: "Legal Matters",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "20",
    coursePrice: "$700",
  },
  {
    heroImg: "/images/hacker.png",
    heading: "Policies",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
  {
    heroImg: "/images/locking.png",

    heading: "Legal Matters",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "30",
    coursePrice: "$900",
  },
  {
    heroImg: "/images/AI.png",

    heading: "Policies",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "20",
    coursePrice: "$700",
  },
];

const ComponentHeading = styled(Typography)`
  color: "#161e29";
`;

const LearningLives = () => {
  const navigate = useNavigate();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const matchesForSliderXxl = useMediaQuery("(min-width:1800px)");
  const matchesForSlider3xl = useMediaQuery("(min-width:2100px)");
  const matchesForSlider4xl = useMediaQuery("(min-width:2500px)");
  const matchesForSliderXl = useMediaQuery((theme) =>
    theme.breakpoints.up("xl")
  );
  const matchesForSliderlg = useMediaQuery((theme) =>
    theme.breakpoints.up("lg")
  );
  const matchesForSlidersm = useMediaQuery((theme) =>
    theme.breakpoints.up("sm")
  );
  const matches = useMediaQuery("(min-width:600px)");
  const matchesForSlider = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [courseList, setCourseList] = useState([]);
  useEffect(() => {
    getLearningCourses();
  }, []);

  const getLearningCourses = async () => {
    const res = await axios({
      url: ApiConfig.findAllCourse,
      method: "GET",
      headers: {
        token: sessionStorage.getItem("userToken"),
      },
    });
    if (!res?.data) {
      return;
    }
    setCourseList(res?.data?.result?.data || []);
    console.log(res?.data?.result?.data, "askbajfadseqsw");
  };

  useEffect(() => {
    console.log(courseList, "asdfyuahjbas");
  }, [courseList]);

  const matchesForSlidermd = useMediaQuery((theme) =>
    theme.breakpoints.up("md")
  );
  const theme = useTheme();

  return (
    <>
      {courseList.length > 0 && (
        <LearningCard>
          <Container maxWidth="lg" /* className="mainLearnCard" */>
            <Box className="displaySpacebetween" mt={4} mb={3}>
              <Box>
                <ComponentHeading
                  fontWeight={600}
                  color={currentTheme === "DARK" ? "#fff" : "#000"}
                  sx={{
                    fontSize: { lg: "45px", xs: "25px" },
                    textAlign: { xs: "center", lg: "left" },
                    width: "100%",
                  }}
                >
                  Learning & Lives
                </ComponentHeading>
                <Typography
                  color={currentTheme === "DARK" ? "#a3a3a3" : "#000"}
                  sx={{
                    fontSize: "25px",
                    // paddingLeft: { lg: "0", xs: "20%" },
                    fontWeight: "500",
                  }}
                >
                  Join & Grow
                </Typography>
              </Box>
              <Box>
                {courseList?.length > 3 ? (
                  <Box>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#e26304",
                      }}
                      sx={{}}
                      onClick={() => navigate("/learning&lives")}
                    >
                      View All
                    </Button>
                  </Box>
                ) : (
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: "#e26304",
                    }}
                    sx={{}}
                    onClick={() =>
                      navigate("/learning&lives", {
                        state: { data: "myCourses" },
                      })
                    }
                  >
                    My Courses
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{ height: "100%" }}>
              <Swiper
                modules={[Navigation, Autoplay]}
                // slidesPerView={3}
                spaceBetween={20}
                slidesPerView={
                  matchesForSlider4xl
                    ? 6
                    : matchesForSlider3xl
                    ? 5
                    : matchesForSliderXxl
                    ? 3
                    : matchesForSliderXl
                    ? 3
                    : matchesForSliderlg
                    ? 3
                    : matchesForSlidermd
                    ? 2
                    : matchesForSlidersm
                    ? 2
                    : 1
                }
                navigation={false}
                pagination={true}
                // onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
                style={{
                  "--swiper-pagination-color": "#fff",
                  "--swiper-navigation-color": "#fff",
                  "--swiper-navigation-size": "25px",
                }}
                // spaceBetween={80}
                // autoplay={{
                //   delay: 1500,
                // }}
              >
                {courseList?.map((val, idx) => (
                  <SwiperSlide key={idx}>
                    <Box
                      sx={{
                        border: "1px #e8e8e8 solid",
                        borderRadius: "20px",
                        overflow: "hidden",
                      }}
                      p={2}
                      mb={4}
                    >
                      <Box className="displayCenter">
                        <Box
                          sx={{
                            width: "100%",
                            height: "250px",
                            position: "relative",
                            top: "0px",
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            objectFit: "fill",
                            borderRadius: "10px",
                          }}
                          component="img"
                          src={val?.course?.image_url}
                        />
                      </Box>
                      <Box
                        // m={2}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "24px",
                            height: "20px",
                            marginTop: "12px",
                          }}
                          component="img"
                          src={"/images/coursecategory.png"}
                        />
                        {/* <Typography className="learnHeading">
                 {val?.course?.courseName
                   ? val?.course?.courseName?.length > 20
                     ? val?.course?.courseName?.slice(0, 20) + "..."
                     : val?.course?.courseName
                   : "NA"}
               </Typography> */}
                        <Typography
                          className="learnHeading"
                          style={{
                            color: currentTheme == "DARK" ? "#fff" : "#202123",
                          }}
                        >
                          {val?.course?.courseName
                            ? matchesForSliderXl
                              ? val?.course?.courseName?.length > 15
                                ? val?.course?.courseName?.slice(0, 20) + "..."
                                : val?.course?.courseName
                              : val?.course?.courseName?.slice(0, 20) + "..."
                            : "NA"}
                        </Typography>
                      </Box>
                      <Typography
                        className="wordBreak"
                        style={{
                          color: currentTheme == "DARK" ? "#fff" : "#202123",
                          height: "50px",
                        }}

                        // variant="subtitle1"
                      >
                        {val?.course?.description
                          ? matchesForSliderXl
                            ? val?.course?.description?.length > 110
                              ? val?.course?.description?.slice(0, 110) + "..."
                              : val?.course?.description
                            : val?.course?.description?.slice(0, 110) + "..."
                          : "NA"}
                      </Typography>
                      <Box className="descripText">
                        <Typography
                          mt={4}
                          variant="subtitle1"
                          style={{
                            color: "#202123BF",
                            color: currentTheme == "DARK" ? "#fff" : "#a3a3a3",
                          }}
                        >
                          Available Videos: &nbsp;
                          <span
                            style={{
                              color:
                                currentTheme == "DARK" ? "#fff" : "#202123",
                              fontWeight: "500",
                            }}
                          >
                            {val?.total_videos}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        mt={2}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Button
                          sx={{
                            background:
                              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                            borderRadius: "6px",
                            width: "160px",
                            padding: "5px 100px",
                            fontSize: "18px",
                            textTransform: "capitalize",
                            boxShadow: "0px 4px 15px 0px #00000040",
                            // boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
                          }}
                          variant="contained"
                          disabled={val?.course?.isModuleAvailable == false}
                          onClick={() =>
                            navigate("/learning-detail", {
                              state: { data: val },
                            })
                          }
                        >
                          Join
                        </Button>
                      </Box>
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>

            <Box className="displayCenter">
              {courseList && courseList?.length === 0 && (
                <Typography
                  variant="subtitle1"
                  style={{ marginBottom: "50px" }}
                >
                  No available course data found!
                </Typography>
              )}
            </Box>
          </Container>
        </LearningCard>
      )}
    </>
  );
};

export default LearningLives;
