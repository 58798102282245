import axios from "axios";
import React from "react";
import { useState, useEffect } from "react";
import { ApiConfig } from "../../services/ApiConfig";
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "add-to-calendar-button";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import PasswordResetModal from "../../components/PasswordResetModal";
import "./liveAnnouncement.scss";

const LiveAnnouncement = ({ courseId }) => {
  console.log(courseId);
  const [list, setList] = useState();
  const [emptyList, setEmptyList] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [liveTitle, setLiveTitle] = useState("");
  const [liveDescription, setLiveDescription] = useState("");
  const [meetingDate, setMeetingDate] = useState("");
  const [endMeetingTime, setEndMeetingTime] = useState("");
  const [meetingTime, setMeetingTime] = useState("");

  const matchesForSliderXl = useMediaQuery((theme) =>
    theme.breakpoints.up("xl")
  );
  const matchesForSlider = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const matches = useMediaQuery("(min-width:600px)");
  const getLiveAnnouncement = async () => {
    const res = await axios({
      url: ApiConfig.getAllLiveAnnouncement,
      method: "GET",
      headers: {
        token: sessionStorage.getItem("userToken"),
      },
      params: {
        cohort_id: sessionStorage.getItem("cohortId"),
        course_id: courseId,
      },
    });
    if (!res?.data) {
      return;
    }
    // setList(res?.data?.result?.data || []);
    console.log(res, "askbajfadseqsw");
    setList(res?.data?.result?.result);
    setEmptyList(res?.data?.result);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    getLiveAnnouncement();
  }, []);

  return (
    <Box>
      {emptyList?.length <= 0 && (
        <Typography sx={{ textAlign: "center", fontWeight: 600, mt: 10 }}>
          No live sessions are currently scheduled for this course.
        </Typography>
      )}
      {list?.filter((res) => {
        const endDate = new Date(res?.end_date_time);
        const currentDate = new Date();
        return endDate > currentDate;
      })?.length <= 0 && (
        <Typography
          sx={{
            textAlign: "center",
            fontWeight: 600,
            fontSize: "20px",
            mt: 10,
          }}
        >
          No live sessions are currently scheduled for this course.
        </Typography>
      )}
      <Box
      // modules={[Navigation, Pagination, Autoplay]}
      // navigation={true}
      // autoplay={{
      //   delay: 2500,
      //   disableOnInteraction: false,
      // }}
      // pagination={{ clickable: true }}
      // slidesPerView={1}
      // onSlideChange={() => console.log("slide change")}
      // style={{
      //   "--swiper-pagination-color": "#000",
      //   "--swiper-navigation-color": "#fff",
      //   "--swiper-navigation-size": "25px",
      //   width: "70vw",
      // }}
      >
        {list
          ?.filter((res) => {
            const endDate = new Date(res?.end_date_time);
            const currentDate = new Date();
            return endDate > currentDate;
          })
          .map((res, idx) => {
            const startDate = new Date(res?.start_date_time);
            const endDate = new Date(res?.end_date_time);
            const currentDate = new Date();

            const dateTimeString = res?.start_date_time;
            const endDateTimeString = res?.end_date_time;
            const [datePart, timePart] = dateTimeString.split(" ");
            const [enddatePart, endtimePart] = endDateTimeString.split(" ");
            const date = datePart; // "2024-05-20"
            const enddate = enddatePart; // "2024-05-20"
            const time = timePart.substring(0, 5); // "20:05:00"
            const endtime = endtimePart.substring(0, 5); // "20:05:00"
            console.log("Date:", date);
            console.log("Time:", time);

            const isPastStartDate = currentDate >= startDate;

            return (
              <>
                {/* <SwiperSlide key={idx}> */}
                {/* <Box
                  sx={{
                    position: "absolute",
                    zIndex: 100,
                    color: "#fff",
                    borderRadius: "15px",
                    background: "linear-gradient(to right, black, transparent)",
                    padding: "5%",

                    height: "300px",
                    transition: "opacity 0.3s ease", // Add transition for smooth opacity change
                  }}
                >
                  <Typography
                    sx={{ width: "50vw", fontSize: "26px", fontWeight: 600 }}
                  >
                    {res?.title}
                  </Typography>
                  <Typography sx={{ width: "40vw", mt: 1 }}>
                    {res?.description}
                  </Typography>
                </Box> */}
                <Typography fontSize="22px" variant="h6" textAlign="center">
                  Live Session
                </Typography>

                <Box>
                  {" "}
                  <Button
                    disabled={currentDate <= startDate}
                    className={currentDate >= startDate ? "button" : "btn"}
                    sx={{
                      background:
                        "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                      marginX: "auto",
                      width: "200px",
                      fontSize: "18px",
                      marginTop: "10px",
                      width: { sm: "200px", xs: "200px" },
                      textTransform: "none",
                      borderRadius: "10px",
                      // position: "absolute",
                      zIndex: 100,
                      color: "#fff",
                      // marginLeft: "80%",
                      // marginTop: "5%",
                      transition: "opacity 0.3s ease", // Add transition for smooth opacity change
                    }}
                    onClick={
                      isPastStartDate
                        ? () => window.open(res?.meeting_url, "_blank")
                        : null
                    }
                  >
                    Join
                  </Button>
                </Box>

                <Button
                  sx={{
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    marginX: "auto",
                    width: "200px",
                    fontSize: "18px",
                    marginTop: "10px",
                    width: { sm: "200px", xs: "200px" },
                    textTransform: "none",
                    borderRadius: "10px",
                    // position: "absolute",
                    zIndex: 100,
                    color: "#fff",
                    // marginLeft: "80%",
                    // marginTop: "5%",
                    transition: "opacity 0.3s ease", // Add transition for smooth opacity change
                  }}
                  onClick={() => {
                    setIsModalOpen(true);
                    setLiveTitle(res?.title);
                    setLiveDescription(res?.description);
                    setMeetingTime(time);
                    setEndMeetingTime(endtime);
                    setMeetingDate(date);
                  }}
                >
                  View Details
                </Button>
                <Box
                  sx={{
                    // position: "absolute",
                    zIndex: 100,
                    color: "#fff",
                    marginTop: "10px",
                    // marginLeft: "80%",
                    // marginTop: "10%",
                    transition: "opacity 0.3s ease", // Add transition for smooth opacity change
                  }}
                >
                  <AddToCalendarButton
                    disabled={currentDate >= startDate}
                    name={res?.title}
                    options={["Apple", "Google", "Outlook.com", "Yahoo"]}
                    location={"Atlanta, GA, USA"}
                    startDate={`${date}`}
                    endDate={`${enddate}`}
                    startTime={`${time}`}
                    endTime={`${endtime}`}
                    icsFile={res?.meeting_url}
                    timeZone={"EST5EDT"}
                    buttonStyle="default"
                    size="7"
                    description={`[strong]Title[/strong][p]${res?.title}[/p][br][strong]Description[/strong][p]${res?.description}[/p][url]${res?.meeting_url}[/url]`}
                  ></AddToCalendarButton>
                </Box>
                {/* <img
                  style={{ width: "70vw", height: "400px", opacity: "0.8" }}
                  src={res?.thumbnail}
                  alt={`Live announcement ${idx + 1}`}
                /> */}

                {/* </SwiperSlide> */}
              </>
            );
          })}
      </Box>
      <PasswordResetModal
        type="liveAnnouncement"
        open={isModalOpen}
        onClose={handleCloseModal}
        liveTitle={liveTitle}
        liveDescription={liveDescription}
        meetingDate={meetingDate}
        meetingTime={meetingTime}
        endMeetingTime={endMeetingTime}
      />
    </Box>
  );
};

export default LiveAnnouncement;
