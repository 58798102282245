import React, { useEffect, useState } from "react";
import Assessments from "../../../components/assessments/Assessments";
import { useLocation } from "react-router-dom";
import { ApiConfig } from "../../../services/ApiConfig";
import FindFundersAssessment from "../../../components/assessments/FindFundersAssessments";
import customAxios from "../../../utils/customAxios";
import { CircularProgress, Grid, Typography } from "@mui/material";

import { useSelector } from "react-redux";
export default function FundersAssessment() {
  const [activeStep, setActiveStep] = useState(1);
  const [initialAnswers, setInitialAnswers] = useState([]);
  const location = useLocation();
  const path = location?.pathname?.split("/");
  const [assessmentData, setAssessmentData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const handleNextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const handlePreviousStep = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1);
    }
  };

  const getAssessmentData = async (path) => {
    setIsLoading(true);
    try {
      const res = await customAxios({
        method: "GET",
        url: ApiConfig.findAllFundingAssesmentLists,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
      });
      if (res?.data?.statusCode === 200) {
        setAssessmentData(
          res?.data?.result
          // ?.filter((e) => e?.is_active)
          // ?.filter((e) => {
          //   if (e?.fundingAssessmentQuestions?.length > 0) {
          //     return true;
          //   }
          //   return false;
          // }) || []
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInitialAnswers = async () => {
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.getFundingAnswerDetails,
        headers: {
          token: sessionStorage.getItem("userToken"),
        },
        data: {
          user_id: sessionStorage.getItem("userId"),
        },
      });
      if (res.data.statusCode === 200) {
        if (res.data?.result !== "Assessment not found.") {
          setInitialAnswers(res.data?.result);
          getAssessmentData(path);
        }
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAssessmentData();
    handleInitialAnswers(path);
  }, []);

  if (isLoading) {
    return (
      <Grid
        container
        item
        xs={12}
        justifyContent={"center"}
        alignItems={"center"}
        minHeight={"500px"}
        flexDirection={"column"}
      >
        <CircularProgress
          sx={{
            color: "#e16104",
          }}
          size={"80px"}
        />
        <Typography mt={6} variant="subtitle2">
          Getting Funding Assesment
        </Typography>
      </Grid>
    );
  }

  return (
    <>
    
        {assessmentData?.length > 0 && (
          <FindFundersAssessment
            pageTitle={"Funding Assessment"}
            renderData={assessmentData}
            activeStep={activeStep}
            handlePreviousStep={handlePreviousStep}
            handleNextStep={handleNextStep}
            initialAnswers={initialAnswers}
            handleInitialAnswers={handleInitialAnswers}
           
          />
        )}
    
    </>
  );
}
