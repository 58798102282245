import React from "react";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import "./myProfile.scss";
import Sidebar from "../../components/profileSidebar/Sidebar";
import { Drawer, IconButton, useMediaQuery, useTheme } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useSelector } from "react-redux";

const mainObj = [
  {
    url: "/images/profileicon.png",
    value: "Core Business Information",
    background:
      " var(--Linear, linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%))",
    link: "/core-business-information",
  },
  {
    url: "/images/lockicon.png",
    value: "Password & Security",
    background: "var(--Main-Green, #19C37D)",
    link: "/password-and-security",
  },
  {
    url: "/images/sessionlogo.png",
    value: "Session Management",
    background: "#A4ADFF",
    link: "/session-management",
  },
];

const MyProfile = () => {
  const navigate = useNavigate();
  // const matches = useMediaQuery("(min-width:900px)");
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div
      style={{
        backgroundColor: currentTheme == "DARK" ? "#151515" : "#F8F8F8",
      }}
    >
      <div
        style={{
          display: "flex",
          //   gap: "25px",
          alignItems: "center",
          paddingLeft: "70px",
          backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
          padding: "20px 0px",
        }}
      >
        <div
          style={{
            paddingLeft: "60px",
            fontSize: "30px",
            fontWeight: 600,
            color: currentTheme == "DARK" ? "#fff" : "#000",
          }}
        >
          My Profile{" "}
        </div>
      </div>
      <div className="profileWrapper">
        <Box
          sx={{
            display: { md: "block", xs: "none" },

            margin: 0,
            padding: 0,
            height: "90vh",
            // paddingBottom:"30px",
            width: "450px",

            overflow: "none",
            alignItems: "center",
            gap: "2rem",
            flexDirection: "column",
            "&::-webkit-scrollbar": {
              display: "none", // For Safari and Chrome
            },
            "@media(max-width:768px)": {
              ml: "0px",
            },
          }}
        >
          <Sidebar />
        </Box>

        <div
          style={{
            width: "100%",
            height: "90vh",
            overflow: "auto",
            backgroundColor: currentTheme == "DARK" ? "#000" : "#fff",
          }}
        >
          <div className="card-container">
            {mainObj.map((data, i) => (
              <div
                key={i}
                style={{ background: data.background }}
                className="cards"
                onClick={() => navigate(data.link)}
              >
                <img src={data.url} alt={`${i}`} />
                <div>{data.value}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
