import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  Stack,
  FormControl,
  FormControlLabel,
  Button,
  TextField,
  Checkbox,
  TextareaAutosize,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useMediaQuery } from "@mui/material";

import { useRef } from "react";
import { Formik, Form } from "formik";
import styled from "@emotion/styled";
import { ToastContainer, toast } from "react-toastify";
import { ApiConfig } from "../../services/ApiConfig";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./contactUs.scss";
import ReCAPTCHA from "react-google-recaptcha";

import * as Yup from "yup";
import customAxios from "../../utils/customAxios";
import { useSelector } from "react-redux";
import PasswordResetModal from "../../components/PasswordResetModal";

const contactobj = [
  {
    icon: "/images/emailicon.png",
    name: "Email",
    value: "contact@businessboostsociety.com",
  },
  {
    icon: "/images/phoneicon1.png",
    name: "Phone Number",
    value: "+254 797 771771",
  },
  {
    icon: "/images/locationicon.png",
    name: "Location",
    value:
      "BOOST STRATEGY GROUP LLC 3979 Peachtree Rd Ste 700 Atlanta GA 30326",
  },
];

const initialValues = {
  email: "",
  name: "",
  mobileNumber: "",
  message: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Please enter valid email address.")
    .required("Email address is required.")
    .min(8, "Email address must be at least 8 characters."),
  // .max(49, "Email address must not exceed 50 characters."),

  name: Yup.string()
    .required("Name is required.")
    .min(2, "Name must be at least 2 characters.")
    // .max(49, "Name must not exceed 50 characters.")
    .matches(
      /^[a-zA-Z0-9 ]+$/,
      "Name must consist of alphanumeric characters only."
    ),

  mobileNumber: Yup.string()
    .min(10, "Mobile number must be atleast 10 digits.")
    .required("Mobile number is required.")
    .matches(/^(?!0+$)[0-9]{10,}$/, "Invalid mobile number."),
  message: Yup.string()
    .required("Message is required.")
    .min(10, "Message must be at least 10 characters."),
  // .max(249, "Message must not exceed 250 characters."),
});

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false);
  const captchaRef = useRef(null);
  const [openQueryModal, setOpenQueryModal] = useState(false);

  const currentTheme = useSelector((state) => state.themeSwitcher.mode);

  const handleOpenModal = () => {
    setOpenQueryModal(true);
  };
  const handleCloseModal = () => {
    setOpenQueryModal(false);
  };

  // const handleRecaptchaChange = () => {
  //   setIsRecaptchaVerified(true);
  // };
  const isSubmitDisabled = (values) => {
    return (
      !values ||
      !values.name ||
      !values.email ||
      !values.mobileNumber ||
      !values.message
      // ||
      // !isRecaptchaVerified
    );
  };

  const contactusHandler = async (values) => {
    setLoading(true);

    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.createContactUs,
        data: {
          email: values.email,
          name: values.name,
          phone: values.mobileNumber,
          query: values.message,
        },
      });
      if (res.data?.statusCode == 200) {
        console.log(res);
        setLoading(false);
        setOpenQueryModal(true);

        // sessionStorage.setItem("userToken", res.data.result.token);
      } else {
        console.log(
          res.data?.responseMessage ||
          "Something went wrong. Please try again.",
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);

        return null;
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.responseMessage ||
        "Something went wrong. Please try again.",
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  const LoginWrapper = styled("div")({
    height: "100dvh",
    width: "45%",
    overflow: "hidden",
    marginTop: "150px",
    marginRight: "100px",
    "@media(max-width:1024px)": {
      width: "100%",
      display: "flex",
      justifyContent: "start",
      alignItems: "center",
      flexDirection: "column",
      gap: "10px",
      marginTop: "200px",
      marginRight: "0px",
    },
    "@media(max-width:640px)": {
      marginRight: "0px",
    },
    // backgroundColor: "yellow",
  });

  const FormContainer = styled(Form)({
    marginTop: "40px",
    display: "flex",
    flexDirection: "column",

    "@media(max-width:640px)": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  });

  const FormControlContainer = styled(FormControl)({
    marginTop: "10px",
  });

  const FormTextField = styled(TextField)({
    height: "60px!important",
    marginTop: "4px",
    borderRadius: "12px",

    "@media(max-width:1024px)": {
      width: "70vw !important",
      // margin: "10px 0px",
    },
  });

  return (
    <Box
      sx={{
        paddingX: { sm: "60px" /* xs: "40px" */ },
        backgroundColor: currentTheme === "DARK" ? "#151515" : "#fff",
      }}
      py={4}
    >
      <Grid container spacing={3}>
        <Grid item lg={6}>
          <Typography
            color={currentTheme == "DARK" ? "#fff" : "#000"}
            sx={{ fontSize: { md: "45px", xs: "30px" }, fontWeight: "600" }}
          >
            Get in touch with us
          </Typography>
          <Typography
            variant="body1"
            color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
            sx={{
              fontWeight: "400",
              marginTop: "8px  ",
              fontSize: { md: "18px", xs: "15px" },
              marginBottom: "40px  ",
              // color: "rgba(63, 60, 60, 0.87)",
              width: { xxl: "670px", md: "400px", sm: "350px", xs: "300px" },
            }}
          >
            Lorem ipsum dolor sit amet consectetur. Sit risus id placerat morbi.
            Velit pellentesque sed duis id aliquam quis leo congue.
          </Typography>
          {contactobj.map((val, idx) => (
            <Box my={3} display="flex" alignItems="center" gap="30px">
              <Box display="flex" flexDirection="column" justifyContent="start">
                <Box component="img" src={val.icon} />
              </Box>
              <Box pt={2}>
                <Typography
                  color={currentTheme == "DARK" ? "#fff" : "#000"}
                  fontSize="16px"
                >
                  {val.name}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "14px",

                    width: { md: "322px", xs: "252px" },
                  }}
                  mt={1}
                  color={currentTheme == "DARK" ? "#a3a3a3" : "#3F3C3CDE"}
                >
                  {val.value}
                </Typography>
              </Box>
            </Box>
          ))}
          <div mt={2}>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d349.3457872788494!2d36.7850055367961!3d-1.3013306845106964!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f109c4fe69693%3A0x413267c9854fdad4!2sJadala%20Place%2C%20Ngong%20Ln!5e0!3m2!1sen!2sin!4v1707384570830!5m2!1sen!2sin"
              width="100%"
              height="301"
              // style="border:0;"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { resetForm }) => {
              console.log(values);
              contactusHandler(values);
              resetForm();
            }}
          >
            {({
              errors,
              touched,
              values,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <FormContainer>
                <FormControlContainer>
                  <label
                    style={{
                      textAlign: "left",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    Name
                  </label>
                  <FormTextField
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    inputProps={{
                      maxLength: 50,
                      style: {
                        height: "20px",
                        backgroundColor: "#fff",
                      },
                    }}
                    variant="outlined"
                    name="name"
                    placeholder="Enter your name"
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControlContainer>
                <FormControlContainer>
                  <label
                    style={{
                      textAlign: "left",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    Email
                  </label>
                  <FormTextField
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: "0px",
                      },
                    }}
                    inputProps={{
                      maxLength: 60,
                      style: {
                        height: "20px",
                        backgroundColor: "#fff",
                        marginBottom: "0px",
                      },
                    }}
                    variant="outlined"
                    name="email"
                    placeholder="Enter your email address"
                  />
                </FormControlContainer>
                <FormControlContainer>
                  <label
                    style={{
                      textAlign: "left",
                      marginBottom: "3px",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    Mobile Number
                  </label>

                  <PhoneInput
                    country={"us"}
                    className="phoneInput"
                    value={values.mobileNumber}
                    onChange={(mobileNumber, cc) => {
                      setFieldValue("mobileNumber", mobileNumber);
                    }}
                    onBlur={handleBlur}
                    placeholder="Enter your mobile number"
                    inputClass="form-control"
                    inputProps={{
                      name: "mobileNumber",
                      required: true,
                    }}
                  />
                  {touched.mobileNumber && errors.mobileNumber && (
                    <FormHelperText
                      sx={{ color: "#d32f2f", marginLeft: "0px !important" }}
                    >
                      {errors.mobileNumber}
                    </FormHelperText>
                  )}
                </FormControlContainer>
                <FormControl>
                  <label
                    style={{
                      textAlign: "left",
                      marginTop: "12px",
                      color: currentTheme == "DARK" ? "#fff" : "#000",
                    }}
                  >
                    Message
                  </label>
                  <TextField
                    sx={{ backgroundColor: "#fff" }}
                    value={values.message}
                    multiline
                    rows={4}
                    inputProps={{
                      maxLength: 255,
                      style: { marginBottom: "0px" },
                    }}
                    variant="outlined"
                    name="message"
                    placeholder="Message type here...."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.message && Boolean(errors.message)}
                    helperText={touched.message && errors.message}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: "0px",
                      },
                    }}
                  />
                </FormControl>



                <Box
                  mt={12}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",

                    // width: "400px",
                    "@media(max-width:1024px)": {
                      width: "70vw !important",
                      // margin: "10px 0px",
                    },
                    "@media(max-width:355px)": {
                      flexDirection: "column",
                    },
                  }}
                >
                  {/* <FormControlLabel
                    control={
                      <Checkbox
                        style={{ color: "#C7340D" }}
                        checked={isChecked}
                      />
                    }
                    label="I’m not a Robot"
                  /> */}
                  <ReCAPTCHA
                    sitekey="6LcrfWspAAAAALk78ijIX3arXUSFkwS53-xkNTgc"
                    ref={captchaRef}
                  // value={isRecaptchaVerified}
                  // onChange={(values) => {
                  //   setIsRecaptchaVerified(true);
                  // }}
                  // onChange={handleRecaptchaChange}
                  />
                </Box>

                <Box
                  mt={10}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    sx={{
                      background:
                        "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                      paddingY: "10px",
                      borderRadius: "10px",
                      fontSize: "16px",
                      paddingX: "30px",
                      width: "220px",
                      textTransform: "none",
                    }}
                    type="submit"
                    variant="contained"
                    // disabled={loading}
                    onClick={handleSubmit}
                    disabled={isSubmitDisabled(values)}
                  >
                    Send Message
                    {loading && (
                      <CircularProgress
                        size={20} // Set the size of CircularProgress
                        sx={{
                          marginLeft: "10px", // Half of the size for horizontal centering
                          color: "white", // Set the color of CircularProgress
                        }}
                      />
                    )}
                  </Button>
                </Box>
              </FormContainer>
            )}
          </Formik>
        </Grid>
      </Grid>
      <PasswordResetModal
        type="contactus"
        open={openQueryModal}
        onClose={handleCloseModal}
      />
    </Box>
  );
};

export default ContactUs;
