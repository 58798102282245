import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BlockIcon from "@mui/icons-material/Block";
import { Avatar, Grid } from "@mui/material";
const ActionCallModalPopup = ({
  open,
  setOpen,
  funcToExecute,
  message,
  currentId,
  currentStatus,
  action,
  setShowUpdateButton,
  setAcceptLoadingStatus,
  setDeclineLoadingStatus,
  taskStatus,
}) => {
  const handleClickOpen = () => {
    setOpen(true);
    // setAccepted(true)
  };
  console.log(currentId, "hhhui");
  const handleYesClick = () => {
    funcToExecute(currentId, currentStatus, taskStatus);
    // setAccepted(true); // Update the accepted status in the parent component
    setOpen(false); // Close the modal
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} style={{}}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "400px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "10px",
            mb: "20px",
          }}
        >
          <Grid container item xs={5}>
            <Button
              onClick={handleYesClick}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "#fe352c" }}
            >
              Yes
            </Button>
          </Grid>
          <Grid container item xs={5}>
            <Button
              onClick={handleClose}
              variant="contained"
              fullWidth
              style={{ backgroundColor: "gray" }}
            >
              No
            </Button>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ActionCallModalPopup;
