import styled from "@emotion/styled";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
  Button,
  CircularProgress,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Formik, Form } from "formik";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PasswordResetModal from "../../components/PasswordResetModal";
import * as Yup from "yup";
import { ApiConfig } from "../../services/ApiConfig";
import { toast } from "react-toastify";
import { VisibilityOff, RemoveRedEye } from "@mui/icons-material";
import customAxios from "../../utils/customAxios";

const LoginWrapper = styled("div")({
  height: "100dvh",
  width: "45%",
  overflow: "hidden",
  marginTop: "150px",
  marginRight: "100px",
  "@media(max-width:1250px)": {
    width: "100%",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    flexDirection: "column",
    gap: "10px",
    marginTop: "200px",
    marginRight: "0px",
  },
  "@media(max-width:640px)": {
    marginRight: "0px",
  },
  // backgroundColor: "yellow",
});

const FormContainer = styled(Form)({
  marginTop: "40px",
  display: "flex",
  flexDirection: "column",

  "@media(max-width:640px)": {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
});
const FormControlContainer = styled(FormControl)({
  marginTop: "10px",
});

const FormTextField = styled(TextField)({
  height: "60px!important",
  marginTop: "4px",

  "@media(max-width:1250px)": {
    width: "50vw !important",
    // margin: "10px 0px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px",
  },
});

const initialValues = {
  password: "",
  confirmPassword: "",
};

const validationSchema = Yup.object({
  password: Yup.string()

    .required("Password is required.")
    .min(6, "Password must be at least 6 characters.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,

      "Password requires uppercase, lowercase, number, and special character."
    ),
  confirmPassword: Yup.string()
    .required("Confirmation of your password is required.")
    .oneOf([Yup.ref("password"), null], "Passwords must match."),
});

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState("");
  const [isHidden, setIsHidden] = useState(false);
  const [isConfirmHidden, setIsConfirmHidden] = useState(false);
  const navigator = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handlePasswordResetSuccess = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleFogetPassword = async (values) => {
    setLoading(true);
    try {
      const res = await customAxios({
        method: "POST",
        url: ApiConfig.resetPassword,
        data: { email: location.state.email, password: values.password },
      });

      if (res.data?.statusCode === 200) {
        console.log(res);
        handlePasswordResetSuccess();
        setLoading(false);
      } else {
        setErrorMessage(res.data?.responseMessage || "Something went wrong");
        setLoading(false);

        return null;
      }
    } catch (error) {
      console.log(error);
      setErrorMessage(
        error.response?.data?.responseMessage || "Something went wrong"
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!location.state?.email) {
      return navigator("/");
    }
  }, []);
  return (
    <LoginWrapper>
      <Typography style={{ textAlign: "left" }} variant="h4" fontWeight={"500"}>
        Reset Password
      </Typography>
      <Typography
        sx={{
          textAlign: "left",
          color: "#d32f2f",
          fontSize: "18px",
        }}
        fontWeight={"500"}
        mt={2}
      >
        {errorMessage}
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          console.log(values);
          handleFogetPassword(values);
        }}
      >
        {({ errors, touched, handleChange, handleBlur }) => (
          <FormContainer>
            <FormControlContainer>
              <label style={{ textAlign: "left" }}>New Password</label>
              <FormTextField
                inputProps={{
                  maxLength: 20,

                  style: {
                    height: "20px",
                    marginBottom: "0px",
                  },
                }}
                // value={values.password}
                variant="outlined"
                name="password"
                placeholder="Enter new password"
                onChange={handleChange}
                onBlur={handleBlur}
                type={isHidden ? "text" : "password"}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
                onInput={(e) => {
                  // Regular expression to allow everything except spaces
                  const regex = /^[^\s]*$/;

                  // Check if the input matches the regex
                  if (!regex.test(e.target.value)) {
                    // If not, set the value to the previous valid value
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    // If valid, update the previous valid value
                    e.target.dataset.previousValue = e.target.value;
                  }
                }}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsHidden((p) => !p)}>
                        {isHidden ? <RemoveRedEye /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlContainer>
            <FormControlContainer>
              <label style={{ textAlign: "left" }}>Confirm Password</label>
              <FormTextField
                inputProps={{
                  maxLength: 20,
                  onPaste: (e) => e.preventDefault(),

                  style: {
                    height: "20px",
                    marginBottom: "0px",
                  },
                }}
                variant="outlined"
                name="confirmPassword"
                placeholder="Enter Confirm password"
                onChange={handleChange}
                onBlur={handleBlur}
                type={isConfirmHidden ? "text" : "password"}
                onInput={(e) => {
                  const regex = /^[^\s]*$/;

                  if (!regex.test(e.target.value)) {
                    e.target.value = e.target.dataset.previousValue || "";
                  } else {
                    e.target.dataset.previousValue = e.target.value;
                  }
                }}
                error={
                  touched.confirmPassword && Boolean(errors.confirmPassword)
                }
                helperText={touched.confirmPassword && errors.confirmPassword}
                FormHelperTextProps={{
                  style: {
                    marginLeft: "0px",
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setIsConfirmHidden((p) => !p)}>
                        {isConfirmHidden ? <RemoveRedEye /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControlContainer>

            <Box
              mt={2}
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                sx={{
                  background:
                    "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                  borderRadius: "10px",
                  fontSize: "18px",
                  paddingX: "40px",
                  paddingY: "10px",
                  textTransform: "none",
                }}
                variant="contained"
                type="submit"
                disabled={loading}
                // onClick={handlePasswordResetSuccess}
              >
                Continue
                {loading && (
                  <CircularProgress
                    size={20} // Set the size of CircularProgress
                    sx={{
                      marginLeft: "10px", // Half of the size for horizontal centering
                      color: "white", // Set the color of CircularProgress
                    }}
                  />
                )}
              </Button>
            </Box>
          </FormContainer>
        )}
      </Formik>

      {/* Render the success modal */}
      <PasswordResetModal
        type="reset"
        open={isModalOpen}
        onClose={handleCloseModal}
      />
    </LoginWrapper>
  );
};

export default ResetPassword;
