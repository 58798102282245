import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Select } from "@mui/material";
import { MenuItem } from "@mui/material";
const UpdateStatusModal = ({
  open,
  setOpen,
  funcToExecute,
  message,
  currentId,
  currentStatus,
  taskStatus,
}) => {
  const handleClickOpen = () => {
    setOpen(true);
  };
  const [selectedStatus, setSelectedStatus] = React.useState("");
  const handleClose = () => {
    setOpen(false);
  };
  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleUpdateStatus = () => {
    if (selectedStatus === "IN-PROGRESS" || selectedStatus === "COMPLETED") {
      funcToExecute(currentId, taskStatus, selectedStatus);
      handleClose();
    }
  };
  return (
    <React.Fragment>
      <Dialog open={open} onClose={handleClose} style={{}}>
        <DialogTitle>Update Status Of Your Task</DialogTitle>
        <DialogContent
          sx={{
            // display: "flex",
            // justifyContent: "center",
            alignItems: "center",
            width: "400px",
          }}
        >
          <DialogContentText id="alert-dialog-description">
            Select status for the task:
          </DialogContentText>
          <Select
            value={selectedStatus}
            onChange={handleStatusChange}
            variant="outlined"
            fullWidth
            style={{ marginTop: "10px" }}
          >
            <MenuItem value="IN-PROGRESS">In Progress</MenuItem>
            <MenuItem value="COMPLETED">Completed</MenuItem>
          </Select>
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            gap: "10px",
            mb: "20px",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={handleUpdateStatus}
            variant="contained"
            style={{ backgroundColor: "#fe352c" }}
            disabled={!selectedStatus}
          >
            Update
          </Button>
          <Button
            onClick={handleClose}
            variant="contained"
            style={{ backgroundColor: "gray" }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default UpdateStatusModal;
