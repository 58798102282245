import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import "./layout.scss";
import LandingFooter from "../components/footer/LandingFooter";

export default function MainLayout({ children }) {
  const token = sessionStorage.getItem("userToken");

  return (
    <>
      <div className="headerwrapper">
        <Header />
      </div>
      <div className="childrenWrapper">{children}</div>
      {!token ? <LandingFooter /> : <Footer />}
    </>
  );
}
