import {
  Avatar,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  Button,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import PersonIcon from "@mui/icons-material/Person";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { apiWithToken } from "../../services/apiWithToken/apiWithToken";
import { ApiConfig } from "../../services/ApiConfig";
import { AccessTime } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { FaRegCalendarAlt } from "react-icons/fa";
import ActionCallModalPopup from "../ActionCallModalPopup";
import JoinModal from "./JoinModal";

const ScheduleCallTab = ({
  updateScheduleCallList,
  setUpdateScheduleCallList,
}) => {
  const matches = useMediaQuery("(min-width:800px)");
  const secondaryMatches = useMediaQuery("(min-width:982px)");
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [callList, setCallList] = useState([]);
  const [acceptLoadingStatus, setAcceptLoadingStatus] = useState(false);
  const [rejectLoadingStatus, setRejectLoadingStatus] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentActionId, setCurrentActionId] = useState("");
  const [message, setMessage] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [joinModalOpen, setJoinModalOpen] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const handleJoinClick = (meetingId) => {
    console.log(meetingId, "sdwsdsdds");
    console.log("sadfadfhasgd");
    setJoinModalOpen(true);
    setIframeSrc(`${meetingId}`);
  };

  const handleCloseModal = () => {
    setJoinModalOpen(false);
  };

  const actionScheduleRefresher = useSelector(
    (state) => state.actionScheduleRefresher
  );
  const listInnerRef = useRef();
  const [filter, setFilter] = useState({
    page: "",
    limit: "",
    status: "All",
  });

  useEffect(() => {
    getCallList();
  }, [updateScheduleCallList, actionScheduleRefresher?.schedule, filter]);

  const getCallList = async () => {
    setIsDataLoading(true);
    const options = {
      method: "POST",
      url: ApiConfig.getMeetingListing,
      data: Object.fromEntries(
        Object.entries(filter).filter(([key, value]) => {
          return value !== "" && !(key === "status" && value === "All");
        })
      ),
    };
    const res = await apiWithToken(options);
    if (res?.data?.statusCode === 200) {
      setCallList(res?.data?.result);
      setIsDataLoading(false);
    }
    setIsDataLoading(false);
  };
  const updateCall = async (action, status) => {
    // setCurrentId(action?.id);
    if (status == "ACCEPTED") {
      setAcceptLoadingStatus(true);
    } else {
      setRejectLoadingStatus(true);
    }
    let amIHost = action?.host_id === sessionStorage.getItem("userId");
    const payLoadToSend = {
      meeting_id: action?.id,
      status_host: amIHost ? status : action?.status_host,
      status_user: !amIHost ? status : action?.status_user,
      decline_reason: "string",
    };

    const options = {
      url: ApiConfig.updateMeetingDetails,
      method: "POST",
      data: payLoadToSend,
    };

    const res = await apiWithToken(options);
    if (status == "ACCEPTED") {
      setAcceptLoadingStatus(false);
    } else {
      setRejectLoadingStatus(false);
    }
    if (res) {
      getCallList();
      // toast.success(res?.result || "Meeting details updated successfully.");
      setIsDataLoading(false);
    }
  };
  const buttonToShow = (data) => {
    if (dayjs(data?.meeting_start_date_time)?.isSame(dayjs(), "day")) {
      if (
        dayjs(dayjs(), "minutes")?.isBefore(
          dayjs(data?.meeting_end_date_time),
          "minutes"
        )
      ) {
        return true;
      }
    }
    if (
      dayjs(dayjs(), "minute")?.isAfter(data?.meeting_end_date_time, "minute")
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Grid
      container
      item
      xs={12}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Grid
        container
        item
        xs={10}
        justifyContent={"flex-end"}
        alignItems={"flex-end"}
      >
        <Box
          sx={{
            width: "300px",
            display: "flex",
            // bgcolor: "orange",
            justifyContent: "flex-end",
            mr: 6,
            gap: 2,
          }}
        >
          <Select
            value={filter.status}
            name="status"
            onChange={(e) => {
              const newFilter = {
                ...filter,
                status: e?.target?.value,
              };
              setFilter(newFilter);
            }}
            fullWidth
            inputProps={{
              style: { height: "10px" },
            }}
            sx={{
              mt: 1,
              "& .MuiSelect-select": {
                padding: "10.5px 14px",
                border: "none",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
              },
              "& .MuiSelect-icon": {
                color: "#fff",
              },
              width: "50%",
              backgroundColor: "#c9380d",
              color: "white",
              borderRadius: "8px",
              border: "none",
            }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"ACCEPTED"}>ACCEPTED</MenuItem>
            <MenuItem value={"DECLINED"}>DECLINED</MenuItem>
            <MenuItem value={"PENDING"}>PENDING</MenuItem>
          </Select>
        </Box>
      </Grid>
      <Box
        component={"div"}
        style={{
          margin: "2rem 0",
          padding: "0 10%",
          width: "100%",
          height: "800px",
          overflowY: "scroll",
        }}
        sx={{
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        {isDataLoading ? (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </div>
        ) : callList.length > 0 ? (
          callList.map((e, i) => (
            <ListItem
              key={i}
              sx={{
                background: i % 2 == 0 ? "#F4DEFF63" : "#E3FFDE63",
                borderRadius: "10px",
                width: "100%",
                height: "auto",
                padding: "10px 0 0 50px",
                boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                mb: "10px",
                p: 1,
                mt: 2,
              }}
              alignItems="flex-start"
            >
              {matches && (
                <img
                  src="/images/listicon.png"
                  alt=""
                  style={{
                    width: "114px",
                    height: "114px",
                    margin: "auto 41px auto auto",
                  }}
                />
              )}
              <ListItemText
                primary={
                  <Grid
                    container
                    item
                    xs={12}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h6"
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {e?.topic
                        ? e?.topic?.length > 150
                          ? e?.topic?.slice(0, 150) + "..."
                          : e?.topic
                        : "NA"}
                      {/* {e?.topic ? e?.topic : "NA"} */}
                    </Typography>
                    <Typography
                      variant="body1"
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                      sx={{
                        px: { xs: 0, md: 2 },
                      }}
                      textAlign={"center"}
                    >
                      <FaRegCalendarAlt /> <span>Assigned Date:</span>{" "}
                      <span>
                        {e?.createdAt
                          ? dayjs(e?.createdAt)?.format("ll")
                          : "NA"}
                      </span>
                    </Typography>
                  </Grid>
                }
                primaryTypographyProps={{
                  sx: { color: "#202123", fontSize: "22px" },
                }}
                secondary={
                  <Box>
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#202123",
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      {e?.agenda}
                    </span>
                    <Box
                      display={"flex"}
                      gap={1.4}
                      alignItems="center"
                      mt={1.4}
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      <CalendarMonthIcon />
                      <Typography
                        display={"inline"}
                        color="#202123"
                        style={{
                          fontSize: "14px",
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        Meeting Date -{" "}
                        {e?.meeting_start_date_time
                          ? dayjs(e?.meeting_start_date_time).format("ll")
                          : "NA"}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      gap={1.4}
                      alignItems="center"
                      mt={1.4}
                      style={{
                        color: currentTheme == "DARK" ? "#fff" : "#000",
                      }}
                    >
                      <AccessTime />
                      <Typography
                        display={"inline"}
                        color="#202123"
                        style={{
                          fontSize: "14px",
                          color: currentTheme == "DARK" ? "#fff" : "#000",
                        }}
                      >
                        Meeting Timings -{" "}
                        {e?.meeting_start_date_time
                          ? dayjs(e?.meeting_start_date_time).format("h:mm A")
                          : "NA"}{" "}
                        -{" "}
                        {e?.meeting_end_date_time
                          ? dayjs(e?.meeting_end_date_time).format("h:mm A")
                          : "NA"}
                      </Typography>
                    </Box>
                    <Box
                      display={"flex"}
                      gap={1.4}
                      alignItems="center"
                      mt={1.4}
                    >
                      <PersonIcon />
                      <Typography
                        variant="body2"
                        display={"inline"}
                        color="#202123"
                      >
                        Scheduled by -{" "}
                        {e?.User?.full_name ? e?.User?.full_name : "NA"}
                      </Typography>
                    </Box>
                    <Grid
                      container
                      xs={12}
                      display={"flex"}
                      gap={1.4}
                      alignItems="center"
                      justifyContent={"space-between"}
                      mt={1.4}
                    >
                      <Grid container item xs={12} md={4}>
                        <Typography
                          display={"inline"}
                          color="#202123"
                          style={{
                            fontSize: "14px",
                            textTransform: "capitalize",
                            color: currentTheme == "DARK" ? "#fff" : "#000",
                          }}
                        >
                          Status:{" "}
                          {/* {e?.host_id != sessionStorage.getItem("userId") ? (
                            <span
                              style={{
                                fontWeight: "600",
                                textTransform: "capitalize",
                                color:
                                  (e?.status_host == "PENDING" && "#eec21a") ||
                                  (e?.status_host == "ACCEPTED" && "green") ||
                                  (e?.status_host == "DECLINE" && "red"),
                              }}
                            >
                              {e?.status_host}
                            </span>
                          ) : ( */}
                          <span
                            style={{
                              fontWeight: "600",
                              textTransform: "capitalize",

                              color:
                                (e?.status_user == "PENDING" && "#eec21a") ||
                                (e?.status_user == "ACCEPTED" &&
                                  e?.status_host === "ACCEPTED" &&
                                  "green") ||
                                (e?.status_user == "DECLINED" && "red") ||
                                (e.status_host === "DECLINED" &&
                                  e?.status_user === "ACCEPTED" &&
                                  "red"),
                            }}
                          >
                            {e.status_host === "DECLINED" &&
                            e?.status_user === "ACCEPTED"
                              ? e?.status_host
                              : e?.status_user}
                          </span>
                          {/* // )} */}
                        </Typography>
                      </Grid>
                      <Grid container item xs={12} md={4}>
                        {secondaryActionCall(
                          e,
                          updateCall,
                          buttonToShow,
                          acceptLoadingStatus,
                          rejectLoadingStatus,
                          currentId,
                          isModalOpen,
                          setIsModalOpen,
                          currentActionId,
                          setCurrentActionId,
                          currentStatus,
                          setCurrentStatus,
                          message,
                          setMessage,
                          joinModalOpen,
                          setJoinModalOpen,
                          iframeSrc,
                          setIframeSrc,
                          handleJoinClick,
                          handleCloseModal,
                          setCurrentId
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                }
              />
            </ListItem>
          ))
        ) : (
          <h3
            style={{
              textAlign: "center",
              color: currentTheme == "DARK" ? "#fff" : "#000",
            }}
          >
            No meeting available
          </h3>
        )}
      </Box>
    </Grid>
  );
};

export default ScheduleCallTab;

const secondaryActionCall = (
  action,
  updateCall,
  buttonToShow,
  acceptLoadingStatus,
  rejectLoadingStatus,
  currentId,
  isModalOpen,
  setIsModalOpen,
  currentActionId,
  setCurrentActionId,
  currentStatus,
  setCurrentStatus,
  message,
  setMessage,
  joinModalOpen,
  setJoinModalOpen,
  iframeSrc,
  setIframeSrc,
  handleJoinClick,
  handleCloseModal,
  setCurrentId
) => {
  const amIHost = action?.host_id === sessionStorage.getItem("userId");
  const currentAssigneeStatus = amIHost
    ? action?.status_host
    : action.status_user;

  return (
    <Grid
      container
      xs={12}
      sx={{
        mt: { xs: 2, md: 0 },
        gap: 1,
        marginRight: "10px",
        minWidth: { md: 350, xs: "auto" },
        justifyContent: "end",
      }}
    >
      {!amIHost && action?.status_user == "PENDING" && (
        <>
          <Grid item xs={12} sm={3.6}>
            <LoadingButton
              loading={action?.id == currentId && rejectLoadingStatus}
              disabled={rejectLoadingStatus}
              fullWidth
              edge="end"
              style={{
                background: "#E40303",
                // borderRadius: "10px",
                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
              }}
              sx={{ color: "#fff", py: 1, px: 4 }}
              onClick={() => {
                // updateCall(action, "DECLINE");
                setCurrentStatus("DECLINED");
                setMessage("Would you like to reject this call?");
                setIsModalOpen(true);
                setCurrentId(action);
              }}
            >
              Decline
            </LoadingButton>
          </Grid>
          <Grid item xs={12} sm={3.6}>
            <LoadingButton
              loading={action?.id == currentId && acceptLoadingStatus}
              disabled={acceptLoadingStatus}
              fullWidth
              edge="end"
              style={{
                background: "#19C37D",
                // borderRadius: "10px",
                boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
              }}
              sx={{
                color: "#fff",
                py: 1,
                px: 4,
                mr: 1.4,
                "(min-width:800px)": {
                  mr: 0,
                },
              }}
              onClick={() => {
                // updateCall(action, "ACCEPTED");
                setCurrentStatus("ACCEPTED");
                setMessage("Would you like to accept this call?");
                setIsModalOpen(true);
                setCurrentId(action);
              }}
            >
              Accept
            </LoadingButton>
          </Grid>
          <ActionCallModalPopup
            open={isModalOpen}
            setOpen={setIsModalOpen}
            funcToExecute={updateCall}
            message={message}
            currentId={currentId}
            currentStatus={currentStatus}
          />
        </>
      )}

      <>
        {action?.status_user == "DECLINED" ||
        action?.status_host == "DECLINED" ? (
          <></>
        ) : (
          buttonToShow(action) &&
          currentAssigneeStatus == "ACCEPTED" && (
            <>
              <Grid item xs={12} sm={3.6}>
                {!amIHost && (
                  <LoadingButton
                    loading={action?.id == currentId && rejectLoadingStatus}
                    disabled={rejectLoadingStatus}
                    fullWidth
                    edge="end"
                    style={{
                      background: "#E40303",
                      // borderRadius: "10px",
                      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                    }}
                    sx={{ color: "#fff", py: 1, px: 4 }}
                    onClick={() => {
                      // updateCall(action, "DECLINE");
                      setCurrentStatus("DECLINED");
                      setMessage("Would you like to reject this call?");
                      setIsModalOpen(true);
                      setCurrentId(action);
                    }}
                  >
                    Decline
                  </LoadingButton>
                )}
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={3.6}
                sx={
                  {
                    // ml: { xs: 0, sm: 6 },
                  }
                }
              >
                <LoadingButton
                  fullWidth
                  edge="end"
                  style={{
                    background:
                      "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
                    // borderRadius: "10px",
                    boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.25)",
                  }}
                  sx={{ color: "#fff", py: 1, px: 4 }}
                  onClick={() => handleJoinClick(action?.join_url)}
                  // onClick={() => {
                  //   window.open(action?.join_url, "_blank");
                  // }}
                >
                  Join
                </LoadingButton>
              </Grid>
              <ActionCallModalPopup
                open={isModalOpen}
                setOpen={setIsModalOpen}
                funcToExecute={updateCall}
                message={message}
                currentId={currentId}
                currentStatus={currentStatus}
              />
            </>
          )
        )}
        {joinModalOpen === true && (
          <JoinModal
            open={joinModalOpen}
            onClose={handleCloseModal}
            iframeSrc={iframeSrc}
          />
        )}
      </>
    </Grid>
  );
};
