import React, { useEffect } from "react";
import { Typography, Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";

const learnObj = [
  {
    heroImg: "/images/hacker.png",
    heading: " Course Category 1",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
  {
    heroImg: "/images/ai.png",

    heading: " Course Category 1",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
  {
    heroImg: "/images/locking.png",

    heading: " Course Category 1",
    courseIcon: "/images/coursecategory.png",
    courseContent:
      "In publishing and graphic design, Lorem ipsum is a placeholder text commonly used",
    availableVideos: "10",
    coursePrice: "$500",
  },
];

const Accelarator = () => {
  const currentTheme = useSelector((state) => state.themeSwitcher.mode);
  useEffect(() => {
    // Scroll to the top of the page when the component is loaded
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Box
      style={{ backgroundColor: currentTheme == "DARK" ? "#151515" : "#fff" }}
    >
      {/* <Box sx={{ position: "relative", width: "100dvw" }}>
        <Typography
          sx={{
            position: "absolute",
            left: { lg: "100px", md: "70px", xs: "50px" },
            top: { lg: "280px", md: "200px", xs: "50px" },
            fontSize: { md: "60px", sm: "30px", xs: "20px" },
            zIndex: "1",
            width: "35vw",
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent", // Hide the actual text
          }}
        >
          IS THIS JUST ANOTHER BUSINESS ACCELERATOR?
        </Typography>
     
        <Box
          sx={{ width: "100vw", position: "absolute", zIndex: "1" }}
          component="img"
          src="/images/meetvector.png"
        />
        <Box
          sx={{ width: "100vw", position: "absolute" }}
          component="img"
          src="/images/purpleblur.png"
        />
        <Box sx={{ width: "90vw" }} component="img" src="/images/meet.png" />
      </Box> */}
      <div
        className="heroBannerContainer"
        style={{
          backgroundImage: 'url("./images/accelarator.png")',
        }}
      >
        <Box
          sx={{
            height: "550px",
            position: "absolute",
            left: "900px",
            top: "100px",
            zIndex: "1",
            borderRadius: "50%",
            display: { xl: "block", xs: "none" },
          }}
          component="img"
          src="/images/specsman.png"
        />
        <Typography
          mx={20}
          my={20}
          sx={{
            // position: "absolute",
            // left: { lg: "100px", md: "70px", xs: "50px" },
            // top: { lg: "200px", md: "200px", xs: "50px" },
            fontSize: { lg: "60px", md: "45px", sm: "30px", xs: "20px" },
            zIndex: "1",
            width: { lg: "38vw", xs: "67vw" },
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent", // Hide the actual text
            marginX: { lg: "60px" /* sm: "100px", xs: "75px" */ },
            marginY: { lg: "60px" /* sm: "120px", xs: "90px" */ },
          }}
        >
          IS THIS JUST ANOTHER BUSINESS ACCELERATOR?
        </Typography>
      </div>
      <Box sx={{ paddingX: { md: "60px" } }} py={6}>
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "300",
            color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
          }}
        >
          The first answer to that question is even if we were, there’s nothing
          wrong with that! There is such a need to provide support to
          historically under resource, businesses, and all emerging businesses
          that are philosophy is the more the merrier! Our programs are not in
          competition, but are actually collaborative partners that are part of
          a larger story, but more about that later!
        </Typography>
        <Typography
          mt={4}
          mb={2}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          Question: How is Business Boost Society different from other
          accelerator programs?
          <br />
          <br />
          Business Boost Society stands out from other accelerator programs in
          several key ways:
        </Typography>
        {Array(6)
          .fill("")
          .map((val, idx) => (
            <Typography
              my={2}
              sx={{
                fontSize: "16px",
                fontWeight: "300",
                color: currentTheme == "DARK" ? "#a3a3a3" : "#000",
                textAlign: "justify",
              }}
            >
              {idx + 1}. The first answer to that question is even if we were,
              there’s nothing wrong with that! There is such a need to provide
              support to historically under resource, businesses, and all
              emerging businesses that are philosophy is the more the merrier!
              Our programs are not in competition, but are actually
              collaborative partners that are part of a larger story, but more
              about that later!
            </Typography>
          ))}
        <Typography
          mt={4}
          mb={2}
          sx={{
            fontSize: "20px",
            fontWeight: "600",
            background:
              "linear-gradient(98deg, #E46703 -1.68%, #C7340D 103.45%)",
            WebkitBackgroundClip: "text",
            backgroundClip: "text",
            color: "transparent",
          }}
        >
          At Business Boost Society, we are dedicated to providing a unique and
          impactful accelerator program that focuses on your specific needs,
          fosters a thriving community, and empowers you to achieve lasting
          success in your business endeavors.
        </Typography>
      </Box>
    </Box>
  );
};

export default Accelarator;
